import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { useAppSelector } from '../state/hooks'
import useSelectChain from './useSelectChain'

export default function useCheckTargetChain() {
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)

  const { chainId } = useWeb3React()

  const selectChain = useSelectChain()
  return useCallback(
    async (targetChain: ChainId) => {
      if (switchingChain) return
      if (targetChain !== chainId) {
        return await selectChain(targetChain)
      }
      return true
    },
    [chainId, selectChain, switchingChain]
  )
}
