import { useRequest } from 'ahooks'
import { useState } from 'react'

export default function useTableRequest(api: any, defaultTableRes: any, pageSize = 20) {
  const [sortConfig, setSortConfig] = useState({
    sortBy: '',
    sortDirection: '',
  })

  const [tablePaginationConfig, setTablePaginationConfig] = useState({
    value: 1,
    total: 0,
  })

  const {
    data: tableRes = defaultTableRes,
    run,
    loading: tableResLoading,
  } = useRequest(
    async (page = 1, sortConfig = {}) => {
      const res = await api(
        {},
        {
          page,
          pageSize,
        },
        sortConfig
      )
      setTablePaginationConfig({
        value: res?.page,
        total: Math.ceil((res?.total || 0) / pageSize),
      })
      return res
    },
    {
      manual: true,
      refreshDeps: [],
    }
  )

  async function init() {
    const sortParams = {
      sortBy: '',
      sortDirection: '',
    }
    setSortConfig(sortParams)
    run(1, sortParams)
  }

  function onPageChange(page: any) {
    run(page, sortConfig)
  }

  function onSortChange(key: string) {
    setSortConfig((prevState) => {
      let direction = ''
      if (prevState.sortBy !== key) {
        direction = 'desc'
      } else {
        switch (prevState.sortDirection) {
          case 'desc':
            direction = 'asc'
            break
          case 'asc':
            direction = ''
            break
          default:
            direction = 'desc'
        }
      }
      run(1, {
        sortBy: key,
        sortDirection: direction,
      })
      return {
        sortBy: key,
        sortDirection: direction,
      }
    })
  }

  return {
    init,
    tableRes,
    tableResLoading,
    tablePaginationConfig,
    onPageChange,
    sortConfig,
    onSortChange,
  }
}
