import { Box, Button, createStyles, Divider, Group, Stack, Text, Tooltip } from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { HiMiniExclamationCircle } from 'react-icons/hi2'
import { PrivateSaleModel, ProjectModel } from 'service/launch.api.d'
import { formatUnits } from 'viem'
import ButtonCheckChain from '../../components/detrade/ButtonCheckChain'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { getProjectPrivateSaleInfo } from '../../service/launch.api'
import { mantissaNumber } from '../../utils/detrade/number'
import LaunchClaimToken from './LaunchClaimToken'
import { LaunchChainId } from './launchConst'
import LaunchDeposit from './LaunchDeposit'
import LaunchRefund from './LaunchRefund'
import LaunchRules from './LaunchRules'
import { useGetLaunchDepositAmount, useLaunchTotalDeposit } from './useLaunchContract'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    label: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
      textAlign: 'center',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#0B132A',
    },
  }
})

function capitalizeFirstLetter(string: string) {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export default function LaunchPrivateSale({ item, loading = true }: { item: ProjectModel; loading: boolean }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const { classes, cx } = useStyles()

  const { data: privateSaleInfo = {} as PrivateSaleModel, refresh: refreshPrivateSaleInfo } = useRequest(
    async () => {
      if (!account) return {} as PrivateSaleModel
      const res = await getProjectPrivateSaleInfo({
        address: account,
        project_id: item.project_id,
      })
      return res
    },
    {
      ready: !!item.is_private_sale && !!item.project_id,
      refreshDeps: [account],
      pollingInterval: 10000,
    }
  )

  const list = useMemo(() => {
    const eligibilityClassify = item.eligibility_classify
    return [
      {
        title: 'Qualifying for the Private Sale',
        desc: (
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            <Text fw={600} fz={14} lh="20px" mt={10}>
              {`Obtain Private sale ${eligibilityClassify}:`}
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                {`To qualify for the private sale, you must acquire Private sale ${eligibilityClassify}. The more Private sale
                {eligibilityClassify} you have, the larger the amount you can deposit in the private sale.`}
              </li>
            </ul>
            <Text fw={600} fz={14} lh="20px" mt={10}>
              {`How to Get Private sale ${eligibilityClassify}:`}
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                {`You can earn Private sale ${eligibilityClassify} through trading activities or by providing liquidity in popular token pools such as ETH/USDT, ETH/USDC, and USDT/USDC.
`}
              </li>
            </ul>
            <Text fw={600} fz={14} lh="20px" mt={10}>
              {` Maximum Deposit:`}
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                Your deposit cannot exceed the maximum deposit limit. The more Private sale {eligibilityClassify} you
                have, the higher your deposit qualification.
              </li>
              <li>
                {`Maximum deposit = (Your Private sale ${eligibilityClassify} / Total Private sale ${eligibilityClassify}) * Private Sale Allocation * Private sale ${item.overfunded_limit}.
`}
              </li>
            </ul>
          </Text>
        ),
        media: '',
      },
      {
        title: 'Allocation',
        desc: (
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            Deposit-Based Allocation: Your token allocation is based on the amount you deposit. A higher deposit results
            in a larger allocation.
            <Text fw={600} fz={14} lh="20px" mt={10}>
              Scenario 1: Private Sale Total Deposits ≤ Private Sale Allocation:
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                If the private sale total deposits from all users do not exceed the public sale allocation, your entire
                deposit will be used to purchase tokens.
              </li>
              <li> Your public sale token allocation = your deposit.</li>
            </ul>
            <Text fw={600} fz={14} lh="20px" mt={10}>
              {'Scenario 2: Private Sale Total Deposits > Private Sale Allocation:'}
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                {`If the private sale total deposits from all users exceed the private sale allocation, each user's
                deposit will be partially used, and the remainder will be refunded.`}
              </li>
              <li> Your private sale token allocation = (your deposit / total deposits) * private sale allocation.</li>
              <li> Refund = your deposit - (your private sale token allocation * private sale price).</li>
            </ul>
          </Text>
        ),
        media: '',
      },
      {
        title: 'Price',
        desc: 'Your token purchase = Your private sale token allocation / private sale price.',
        media: '',
      },
      {
        title: 'Distribution',
        desc: 'You will receive your purchased tokens and any applicable refund once the purchase event concludes.',
        media: '',
      },
    ]
  }, [item.eligibility_classify, item.overfunded_limit])

  const startTime = item.project_start_time
  const endTime = item.project_end_time
  const isActive = useMemo(() => {
    return dayjs().isBefore(endTime) && dayjs().isAfter(startTime)
  }, [endTime, startTime])

  const isActiveEnd = useMemo(() => {
    return dayjs().isAfter(endTime)
  }, [endTime])
  const canDeposit = useMemo(() => {
    return Object.keys(privateSaleInfo).length > 0 && isActive
  }, [isActive, privateSaleInfo])

  const contractAddress = item.lpd_contract_address
  const paymentTokenContractAddress = item.payment_token_address
  // const paymentTokenContractAddress = '0x52D512B297F3CF6158B22ec0AD4ca6a47eE67bEd'
  // const paymentTokenDecimal = 6
  const paymentTokenDecimal = item.payment_token_decimals
  const {
    data,
    loading: depositAmountLoading,
    refresh: refreshLaunchDepositAmount,
  } = useGetLaunchDepositAmount(contractAddress, 0)
  const depositAmount = useMemo(() => {
    return formatUnits(data || 0, paymentTokenDecimal)
  }, [data, paymentTokenDecimal])

  const { data: dataTotalDeposit, loading: loadingTotalDeposit } = useLaunchTotalDeposit(contractAddress, 0)
  const totalDepositAmount = useMemo(() => {
    return formatUnits(dataTotalDeposit || 0, paymentTokenDecimal)
  }, [dataTotalDeposit, paymentTokenDecimal])

  const subscribed = useMemo(() => {
    if (!totalDepositAmount || !item.private_sale_allocation) return 0
    if (item.public_sale_allocation >= totalDepositAmount) return 0
    const res = Number(totalDepositAmount) / Number(item.private_sale_allocation)
    return res
  }, [item.private_sale_allocation, item.public_sale_allocation, totalDepositAmount])

  return (
    <>
      <Box
        w="100%"
        p={20}
        sx={({ colors }) => ({
          border: '1px solid #BABDC466',
          borderRadius: 16,
        })}
      >
        <Group
          spacing={6}
          p={12}
          sx={({ colors }) => ({
            borderRadius: 6,
            background: '#F7F7F7',
          })}
        >
          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <div className={classes.value}>
              {mantissaNumber(item.private_sale_allocation || 0, 8)} {item.currency}
            </div>
            <div className={classes.label}>Private Sale Allocation</div>
          </Stack>
          <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <Group spacing={6} position="center">
              <div className={classes.value}>
                {mantissaNumber(totalDepositAmount || 0, 8)} {item.currency}
              </div>
              {subscribed > 0 && (
                <Box
                  sx={({ colors }) => ({
                    padding: '2px 6px',
                    background: '#FFF0E1',
                    borderRadius: 16,
                  })}
                >
                  <Text fw={500} fz={12} lh="14px" color="#FE9839">
                    {mantissaNumber(subscribed, 2)}x subscribed
                  </Text>
                </Box>
              )}
            </Group>
            <div className={classes.label}>Private Sale Total Deposit</div>
          </Stack>
          <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <Text className={classes.value} sx={{ color: '#45AF5C' }}>
              {mantissaNumber(item.private_sale_price || 0, 8)} {item.currency}
            </Text>
            <div className={classes.label}>Private Sale Price</div>
          </Stack>
        </Group>
        <Stack spacing={32} mt={20}>
          <Text fw={600} fz={18} lh="24px" color="#0B132A">
            My Private Sale Info
          </Text>

          <Stack spacing={20} w="100%">
            <Stack align="center" spacing={7}>
              <Text fw={400} fz={14} lh="16px" color="#4F5665">
                My Deposit
              </Text>
              <Text fw={700} fz={28} lh="32px" color="#45AF5C">
                {mantissaNumber(depositAmount || 0, 8)} {item.currency}
              </Text>
            </Stack>

            <Group spacing={10}>
              <Stack align="center" spacing={4} sx={{ flex: '1 0' }}>
                <div className={classes.label}>{capitalizeFirstLetter(item.eligibility_classify || '--')}</div>
                <div>
                  {mantissaNumber(privateSaleInfo.detrade_share || 0, 2)} (
                  {mantissaNumber(Number(privateSaleInfo.detrade_share_ratio || 0) * 100, 2)}%)
                </div>
              </Stack>

              <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />
              <Stack align="center" spacing={4} sx={{ flex: '1 0' }}>
                <Group spacing={2}>
                  <div className={classes.label}>Max Deposit</div>
                  <Tooltip
                    width={300}
                    withArrow
                    label={
                      <Stack spacing={8}>
                        <Text fw={400} fz={12} lh="16px">
                          <Text ta="center">Max Deposit = {item.eligibility_classify || '--'} / Total </Text>
                          <Text ta="center">
                            {item.eligibility_classify || '--'} * Private Sale Allocation * {item.overfunded_limit}
                          </Text>
                        </Text>
                      </Stack>
                    }
                    styles={({ colors }) => ({
                      tooltip: {
                        padding: 10,
                        background: '#3C38F5',
                        borderRadius: 8,
                      },
                    })}
                  >
                    <Group
                      sx={{
                        transform: 'rotate(180deg)',
                      }}
                    >
                      <HiMiniExclamationCircle size={14} color="#BABDC4" />
                    </Group>
                  </Tooltip>
                </Group>
                <div>
                  {mantissaNumber(privateSaleInfo.max_deposit || 0, 8)} {item.currency}
                </div>
              </Stack>

              <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

              <Stack align="center" spacing={4} sx={{ flex: '1 0' }}>
                <div className={classes.label}>My Estimated {item.project_slug} Allocation</div>

                <Text ta="center">
                  {mantissaNumber(privateSaleInfo.estimated_allocation || 0, 8)} {item.project_slug} (
                  {mantissaNumber(Number(privateSaleInfo.estimated_allocation_ratio || 0) * 100, 2)}%)
                </Text>
              </Stack>
            </Group>

            <LaunchRefund
              item={item}
              saleType={0}
              contractAddress={contractAddress}
              paymentTokenDecimal={paymentTokenDecimal}
            />
          </Stack>

          {canDeposit ? (
            <LaunchDeposit
              item={item}
              saleType={0}
              contractAddress={contractAddress}
              paymentTokenContractAddress={paymentTokenContractAddress}
              paymentTokenDecimal={paymentTokenDecimal}
              max={Number(privateSaleInfo.max_deposit || 0) - Number(depositAmount || 0)}
              onRefresh={() => {
                refreshPrivateSaleInfo()
                refreshLaunchDepositAmount()
              }}
            />
          ) : (
            <>
              {isActiveEnd ? (
                <></>
              ) : (
                <>
                  {isActive ? (
                    <ButtonCheckChain size="xl" fullWidth chainId={LaunchChainId} disabled={!canDeposit}>
                      Not eligible
                    </ButtonCheckChain>
                  ) : (
                    <Button size="xl" fullWidth disabled={!canDeposit}>
                      Coming soon
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          <LaunchClaimToken
            item={item}
            saleType={0}
            contractAddress={contractAddress}
            paymentTokenDecimal={paymentTokenDecimal}
          />
        </Stack>
      </Box>

      <Box mt={40}>
        <Text fw={600} fz={24} lh="32px" color="#0B132A">
          📚 Private sale rule
        </Text>
      </Box>

      <Stack mt={28} spacing={16}>
        <LaunchRules list={list} />
      </Stack>
    </>
  )
}
