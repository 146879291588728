import { Button, Group, Image, Text } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { ProjectModel } from 'service/launch.api.d'
import { formatUnits } from 'viem'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { mantissaNumber } from '../../utils/detrade/number'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { useGetLaunchRefundData, useLaunchClaimRefund } from './useLaunchContract'

export default function LaunchRefund({
  item,
  saleType,
  contractAddress,
  paymentTokenDecimal,
}: {
  item: ProjectModel
  saleType: 0 | 1
  contractAddress: string
  paymentTokenDecimal: number
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const {
    data: launchRefundAmountData = {},
    loading: launchRefundAmountLoading,
    refresh,
  } = useGetLaunchRefundData(contractAddress, saleType)
  const { expectedRefund = 0, claimedRefund = 0 } = launchRefundAmountData
  const launchRefundAmount = useMemo(() => {
    return formatUnits(expectedRefund || 0, paymentTokenDecimal)
  }, [expectedRefund, paymentTokenDecimal])

  const addTransaction = useTransactionAdder()
  const claimRefund = useLaunchClaimRefund(contractAddress, saleType)
  const { run, loading } = useRequest(
    async () => {
      try {
        const response = await claimRefund()
        if (!response) return
        const transactionInfo: TransactionInfo = {
          type: TransactionType.LAUNCH_CLAIM_REFUND,
          data: {
            detail: item,
            amount: launchRefundAmount,
          },
        }
        addTransaction(response, transactionInfo)
        await sleep(1000)
        await refresh()
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )

  const distributionTime = item.project_distribution_time
  const isShowRefundAndClaim = useMemo(() => {
    return dayjs().isAfter(distributionTime)
  }, [distributionTime])
  if (expectedRefund <= 0 || !isShowRefundAndClaim) return null
  return (
    <>
      <Group
        py={10}
        px={16}
        position="apart"
        sx={({ colors }) => ({
          borderRadius: 6,
          background: '#F7F7F7',
        })}
      >
        <Group spacing={6} fw={500} fz={16} lh="20px" color="#0B132A">
          <Image width={24} height={24} radius={23} src={item.project_logo} />

          <Text>Refund：</Text>
          <Text>
            {mantissaNumber(launchRefundAmount || 0, 8)} {item.currency}
          </Text>
        </Group>
        <Button
          sx={({ colors }) => ({
            height: 36,
            minWidth: 141,
            background: '#E1FFE4',
            borderRadius: 60,
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '20px',
            color: '#45AF5C',
            cursor: 'pointer',
            padding: 0,
            ':hover': {
              background: '#E1FFE4',
            },
          })}
          onClick={run}
          loading={loading}
          disabled={claimedRefund >= expectedRefund}
        >
          Claim {item.currency}
        </Button>
      </Group>
    </>
  )
}
