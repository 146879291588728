import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Trans } from 'i18n'
import { Box } from 'nft/components/Box'
import { ReactNode, useCallback, useMemo } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'

import { createStyles, Divider, Flex, Group, Header, Image, Text } from '@asuikit/core'
import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import Web3Status from 'components/Web3Status'
import { useTranslation } from 'react-i18next'
import { STATIC_BASEURL } from '../../constants/detrade'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
    menu: {
      color: '#4F5665',
      '&:hover, &.active': {
        color: '#0B132A',
        fontWeight: 500,
      },
      '&.disabled': {
        color: colors.text[4],
        cursor: 'not-allowed',
      },
    },
  }
})
const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  const { classes, cx } = useStyles()

  return (
    <NavLink
      to={href}
      className={cx(classes.menu, {
        active: isActive,
      })}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()

  const isPoolActive = useIsPoolsPage()

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
        <Trans>Swap</Trans>
      </MenuItem>
      {/*<MenuItem*/}
      {/*  href={'/explore' + (chainName !== Chain.Ethereum ? `/${chainName.toLowerCase()}` : '')}*/}
      {/*  isActive={pathname.startsWith('/explore')}*/}
      {/*>*/}
      {/*  <Trans>Explore</Trans>*/}
      {/*</MenuItem>*/}
      {/*{!shouldDisableNFTRoutes && (*/}
      {/*  <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>*/}
      {/*    <Trans>NFTs</Trans>*/}
      {/*  </MenuItem>*/}
      {/*)}*/}
      <Box>
        <MenuItem href="/add/ETH" isActive={isPoolActive}>
          <Trans>Liquidity</Trans>
        </MenuItem>
      </Box>
      <Box>
        <MenuItem href="/pool-list" isActive={pathname.startsWith('/pool-list')}>
          <Trans>Pools</Trans>
        </MenuItem>
      </Box>
      <Box>
        <MenuItem href="/create-token" isActive={pathname.startsWith('/create-token')}>
          <Trans>Create token</Trans>
        </MenuItem>
      </Box>
      {/*<Box>*/}
      {/*  <MenuItem href="/launch" isActive={pathname.startsWith('/launch')}>*/}
      {/*    <Trans>Launch</Trans>*/}
      {/*  </MenuItem>*/}
      {/*</Box>*/}
      {/*<More />*/}
    </>
  )
}
const Navbar = ({ blur }: { blur?: boolean }) => {
  const navigate = useNavigate()

  // const { account } = useWeb3React()
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleIconClick = useCallback(() => {
    // if (account) {
    //   return
    // }
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])
  const { t } = useTranslation()

  const rightMenuList = useMemo(() => {
    return [
      // {
      //   icon: <Image width={20} height={20} src={`${STATIC_BASEURL}/images/detrade/Navbar/water-flash-fill.svg`} />,
      //   text: <>Testnet</>,
      //   href: 'https://hal-faucet.alienxchain.io/',
      // },
    ]
  }, [])
  return (
    <>
      {blur && <Blur />}
      <Header
        height={72}
        p="0"
        styles={() => ({
          root: {
            background: 'transparent',
            borderBottom: 0,
            flex: 1,
          },
        })}
      >
        <Flex px={60} h="100%" align="center" justify="space-between" gap={40}>
          <a onClick={handleIconClick}>
            <Image src={`${STATIC_BASEURL}/images/detrade/logo-with-text.svg`} height={32} width="auto" />
          </a>
          <Divider orientation="vertical" color="line.2" h={20} sx={{ alignSelf: 'center' }} />

          <Group spacing={48} noWrap position="left" sx={{ flex: 1, overflowX: 'auto' }}>
            <PageTabs />
          </Group>
          <div className="right">
            <Group spacing={8} noWrap>
              {rightMenuList.map((item: any, index) => {
                const content = (
                  <Group spacing={4} px={16} py={0} noWrap>
                    {item.icon}
                    <Text fw={500} fz={16} lh="20px" color="text.1">
                      {item.text}
                    </Text>
                  </Group>
                )
                if (!item.href) return <>{content}</>
                return (
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    style={{ textDecoration: 'none' }}
                  >
                    {content}
                  </a>
                )
              })}
              <ChainSelector />
              <Web3Status />
            </Group>
          </div>
        </Flex>
      </Header>
    </>
  )
}

export default Navbar
