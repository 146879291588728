import {
  ActionIcon,
  Box,
  Button,
  Center,
  Collapse,
  createStyles,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  Tooltip,
} from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import { CurrencyAmount, Fraction, Token, V3_CORE_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { BigNumber } from 'ethers/lib/ethers'
import { useCallback, useContext, useMemo, useState } from 'react'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LPStakeRewardModel } from 'service/pools.api.d'
import RemoveLiquidity from '../../components/detrade/AddLiquidity/RemoveLiquidity'
import {
  getPriceOrderingFromPositionForUI,
  PositionListItemProps,
  usePositionListItem,
} from '../../components/PositionListItem'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import { getApiChainParam } from '../../constants/chains'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useToken } from '../../hooks/Tokens'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { PoolCache, usePool } from '../../hooks/usePools'
import useStablecoinPrice from '../../hooks/useStablecoinPrice'
import { useV3PositionFees } from '../../hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions'
import { getMyPoolsLpReward } from '../../service/pools.api'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import { mantissaNumber } from '../../utils/detrade/number'
import { NumberType, useFormatter } from '../../utils/formatNumbers'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { useInverter } from '../Pool/PositionPage'
import { MyLPContext } from './index'
import { useClaimReward, useUnStakePool } from './stakeContract.api'
import { StakeLiquidityInfo, StakeLiquidityModalContext, StakeLiquidityModalProvider } from './StakeLiquidityModal'
import { stakePoolChainId } from './stakePoolConst'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },
    label: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#4F5665',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#3C38F5',
    },
  }
})

function StakeMyLPInfo({ item }: { item: PositionListItemProps }) {
  const { tokenId, priceLower, priceUpper, currencyBase, currencyQuote, feeAmount, removed, outOfRange, tickAtLimit } =
    usePositionListItem(item)
  const poolAddress = useMemo(() => {
    if (currencyBase && currencyQuote && feeAmount) {
      const tokenA = currencyBase.wrapped
      const tokenB = currencyQuote.wrapped
      if (tokenA.equals(tokenB)) return undefined
      const params: [Token, Token, number] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB, feeAmount]
        : [tokenB, tokenA, feeAmount]
      const address = PoolCache.getPoolAddress(V3_CORE_FACTORY_ADDRESSES[stakePoolChainId], ...params)
      // console.log(11111, tokenId.toString(), tokenA, tokenB, feeAmount, address)

      return address
    }
    return undefined
  }, [currencyBase, currencyQuote, feeAmount])

  return (
    <>
      <StakeLiquidityInfo
        currencyBase={currencyBase}
        currencyQuote={currencyQuote}
        feeAmount={feeAmount}
        tokenId={tokenId}
        poolAddress={poolAddress}
        removed={removed}
        outOfRange={outOfRange}
      />
    </>
  )
}

function StakeMyPoolItem({ item, data }: { item: PositionListItemProps; data: any }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const myLPContext = useContext(MyLPContext)
  const [opened, { toggle }] = useDisclosure(false)

  const { classes, cx } = useStyles()
  const [openedRemoveLiquidity, { open: openRemoveLiquidity, close: closeRemoveLiquidity }] = useDisclosure(false)

  const { loading, position: positionDetails } = useV3PositionFromTokenId(BigNumber.from(data?.token_id))

  const [showClaim, setShowClaim] = useState(false)
  const [showClaimData, setShowClaimData] = useState<LPStakeRewardModel | null>(null)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  const token0 = useToken(data.token0)
  const token1 = useToken(data.token1)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, data.fee)

  const positionItem = usePositionListItem(item)
  const { position, tokenId, currencyBase, currencyQuote, removed } = positionItem
  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, BigNumber.from(data?.token_id), false)

  const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(false)

  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  })
  const inverted = token1 ? base?.equals(token1) : undefined

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  // txn values
  const [txHash, setTxHash] = useState<string>('')

  const handleDismiss = useCallback(() => {
    setShowClaim(false)
    // if there was a tx hash, we want to clear the input
    setAttemptingTxn(false)
    setTxHash('')
  }, [])

  const { account, chainId, provider } = useWeb3React()

  const stakeLiquidityModalContext = useContext(StakeLiquidityModalContext)

  const {
    data: dataLpReward,
    loading: dataLpRewardLoading,
    refresh: dataLpRewardRefresh,
  } = useRequest(
    async () => {
      if (!opened) return undefined
      try {
        const res = await getMyPoolsLpReward({
          chain: getApiChainParam(stakePoolChainId),
          token_address: data?.token_address,
          token_id: data?.token_id,
        })

        return res || []
      } catch (e: any) {
        console.log(e)
        return undefined
      }
    },
    {
      refreshDeps: [opened],
    }
  )

  const claimReward = useClaimReward()
  const addTransaction = useTransactionAdder()
  const claim = useCallback(
    async (itemLpReward: LPStakeRewardModel) => {
      if (!provider || !itemLpReward?.token_address) {
        return
      }
      try {
        setAttemptingTxn(true)
        const response = await claimReward(data?.token_id, itemLpReward?.token_address)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.CLAIM_REWARD,
          data: itemLpReward,
        }
        addTransaction(response, transactionInfo)
      } catch (e: any) {
        handleDismiss()
        handleErrorMessage(e)
      }
    },
    [addTransaction, claimReward, data, handleDismiss, provider]
  )

  const { run: claimAll, loading: claimAllLoading } = useRequest(
    async (skipRefresh = false) => {
      if (!dataLpReward || !dataLpReward.length) return

      for (let i = 0; i < dataLpReward.length; i++) {
        const item = dataLpReward[i]
        try {
          setShowClaim(true)
          setShowClaimData(item)
          await claim(item)
        } catch (e: any) {
          console.log(e)
        } finally {
          setShowClaim(false)
          setShowClaimData(null)
        }
      }
      if (!skipRefresh) {
        await sleep(10000)
        myLPContext.refresh()
      }
      dataLpRewardRefresh()
    },
    {
      manual: true,
    }
  )
  const canStake = useMemo(() => {
    return !!data?.rewards && data.rewards.length > 0
  }, [data.rewards])
  const unStake = useUnStakePool()

  const { run: unStakeApi, loading: unStakeApiLoading } = useRequest(
    async (tokenId) => {
      try {
        if (canClaim) {
          await claimAll(true)
        }
        const response = await unStake(tokenId)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.UNSTAKE_LP,
          data: item,
        }
        addTransaction(response, transactionInfo)
        await sleep(10000)
        await myLPContext.refresh()
      } catch (e: any) {
        if (!didUserReject(e)) {
          handleErrorMessage(e)
          console.log(e)
        }
      }
    },
    {
      manual: true,
    }
  )
  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()

  const dataLpRewardDesc = useMemo(() => {
    return (dataLpReward || [])
      .filter(Boolean)
      .map((item: any) => {
        return `${mantissaNumber(item?.amount || 0)} ${item?.token_symbol}`
      })
      .join(' | ')
  }, [dataLpReward])
  const canClaim = useMemo(() => {
    const num = Object.values(dataLpReward || []).reduce((pre, cur) => {
      return pre + Number(cur.amount || 0)
    }, 0)
    return num > 0
  }, [dataLpReward])

  const navigate = useNavigate()
  const checkTargetChain = useCheckTargetChain()

  const price0 = useStablecoinPrice(token0 ?? undefined)
  const price1 = useStablecoinPrice(token1 ?? undefined)
  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  return (
    <Box
      p={20}
      sx={({ colors }) => ({
        borderRadius: 20,
        background: '#FFFFFF',
        boxShadow: ' 0px 20px 40px -10px #0000000D',
      })}
    >
      <Stack spacing={24}>
        <StakeMyLPInfo
          item={{
            ...item,
          }}
        />
        <Stack spacing={12}>
          <Center>
            <Text fw={400} fz={14} lh="16px" color="#4F5665">
              My position
            </Text>
          </Center>
          <Stack spacing={8}>
            <Text fw={700} fz={28} lh="32px" color="#3C38F5" ta="center">
              {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
                <Text>
                  ~
                  {formatCurrencyAmount({
                    amount: fiatValueOfLiquidity,
                    type: NumberType.FiatTokenPrice,
                  })}
                </Text>
              ) : (
                <Text>-</Text>
              )}
            </Text>
            <Text fw={500} fz={16} lh="20px" color="#4F5665" ta="center">
              {formatCurrencyAmount({ amount: inverted ? position?.amount0 : position?.amount1 })}
              {currencyQuote?.symbol} |{' '}
              {formatCurrencyAmount({ amount: inverted ? position?.amount1 : position?.amount0 })}{' '}
              {currencyBase?.symbol}
            </Text>
            {/*<Text fw={500} fz={16} lh="20px" color="#4F5665" ta="center">*/}
            {/*  {data?.token0_amount || 0} {data?.token0_symbol} | {data?.token1_amount || 0} {data?.token1_symbol}*/}
            {/*</Text>*/}
          </Stack>
        </Stack>

        <Group spacing={12}>
          <Tooltip
            width={300}
            withArrow
            label={
              <Stack spacing={8}>
                <Text
                  fw={400}
                  fz={12}
                  lh="16px"
                  color="#FFFFFF"
                  ta="center"
                  sx={({ colors }) => ({
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                  })}
                >
                  APR refers to the annualized income from trading fees relative to total value locked (TVL) within a
                  pool and LP rewards.
                </Text>
              </Stack>
            }
            styles={({ colors }) => ({
              tooltip: {
                padding: 10,
                background: '#3C38F5',
                borderRadius: 8,
              },
            })}
          >
            <Stack spacing={5} align="center" sx={{ flex: 1 }}>
              <Text fw={500} fz={18} lh="20px" color="#45AF5C">
                {mantissaNumber((data?.apr || 0) * 100, 2)}%
              </Text>
              <Text fw={400} fz={14} lh="16px" color="#4F5665">
                APR
              </Text>
            </Stack>
          </Tooltip>
          <Stack spacing={5} align="center" sx={{ flex: 1 }}>
            <Text fw={500} fz={18} lh="20px" color="#0B132A">
              ~${mantissaNumber(data?.fees_earned || 0, 2)}
            </Text>
            <Text fw={400} fz={14} lh="16px" color="#4F5665">
              Fees earned
            </Text>
          </Stack>
        </Group>

        <Grid gutter={8} justify="center">
          {!removed && (
            <Grid.Col span={6}>
              {data?.stake_state === 0 ? (
                <Button
                  variant="outline"
                  size="lg"
                  fullWidth
                  loading={unStakeApiLoading}
                  onClick={(e) => {
                    e.stopPropagation()
                    unStakeApi(data.token_id)
                  }}
                >
                  Unstake
                </Button>
              ) : (
                <Button
                  variant="outline"
                  size="lg"
                  fullWidth
                  disabled={!canStake}
                  onClick={(e) => {
                    e.stopPropagation()
                    stakeLiquidityModalContext.setPool(item)
                    stakeLiquidityModalContext.open()
                  }}
                >
                  Stake
                </Button>
              )}
            </Grid.Col>
          )}

          {!!currency0 && !!currency1 && (
            <Grid.Col span={data?.liquidity ? 6 : 12}>
              <Link
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}/${data?.fee}/${data?.token_id}`}
                target="_blank"
              >
                <Button
                  size="lg"
                  fullWidth
                  onClick={async (e) => {
                    const checked = await checkTargetChain(stakePoolChainId)
                    if (!checked) {
                      e.stopPropagation()
                    }
                  }}
                >
                  Add
                </Button>
              </Link>
            </Grid.Col>
          )}

          {!removed && (
            <Grid.Col span={12}>
              <Button
                variant="default"
                size="lg"
                fullWidth
                bg="#F1F1FE"
                loading={loading}
                disabled={data?.stake_state === 0}
                onClick={openRemoveLiquidity}
                sx={{ color: '#3C38F5', border: 0 }}
              >
                Withdraw
              </Button>
            </Grid.Col>
          )}
        </Grid>

        <Box p={10} sx={{ background: '#BABDC41A', borderRadius: 6 }}>
          <Group position="apart">
            <Text fw={500} fz={16} lh="20px" color="#4F5665">
              Unclaimed Rewards
            </Text>
            <ActionIcon size={16} onClick={toggle}>
              {!opened ? <GoTriangleDown size={16} color="#4F5665" /> : <GoTriangleUp size={16} color="#4F5665" />}
            </ActionIcon>
          </Group>

          <Collapse in={opened} transitionTimingFunction="linear">
            <Stack spacing={12} mt={12}>
              <Group position="apart">
                <div className={classes.label}>Trading fee：</div>
                <div className={classes.value}>
                  {feeValueLower ? formatCurrencyAmount({ amount: feeValueLower }) : '-'}{' '}
                  {feeValueLower?.currency?.symbol} |{' '}
                  {feeValueUpper ? formatCurrencyAmount({ amount: feeValueUpper }) : '-'}
                  {feeValueUpper?.currency?.symbol}
                </div>
              </Group>
              <Group position="apart">
                <div className={classes.label}>Reward：</div>
                {dataLpRewardLoading ? (
                  <Loader variant="dots" />
                ) : (
                  <div className={classes.value}>{dataLpRewardDesc || '-'}</div>
                )}
              </Group>
              <Button
                size="xs"
                fullWidth
                loading={claimAllLoading}
                disabled={!canClaim}
                onClick={() => {
                  claimAll()
                }}
              >
                Claim
              </Button>
            </Stack>
          </Collapse>
        </Box>
      </Stack>

      <RemoveLiquidity
        opened={openedRemoveLiquidity}
        onClose={closeRemoveLiquidity}
        tokenId={BigNumber.from(data?.token_id)}
        position={positionDetails}
        needWaitConfirm={true}
      />

      <TransactionConfirmationModal
        isOpen={showClaim}
        onDismiss={handleDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash ? txHash : ''}
        reviewContent={() => <></>}
        title="Claim rewards"
        pendingText="Collect rewards"
      />
    </Box>
  )
}

export default function StakeMyPoolList({ list = [] }: { list: any[] }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  return (
    <>
      <StakeLiquidityModalProvider>
        <Grid gutter={24}>
          {list
            .filter((item) => !!item.token1_symbol)
            .map((item, index) => (
              <Grid.Col span={4} key={index}>
                <StakeMyPoolItem
                  item={{
                    token0: item.token0,
                    token1: item.token1,
                    tokenId: BigNumber.from(item.token_id),
                    fee: item.fee,
                    liquidity: BigNumber.from(item.liquidity || 0),
                    tickLower: item.tick_lower,
                    tickUpper: item.tick_upper,
                  }}
                  data={item}
                />
              </Grid.Col>
            ))}
        </Grid>
      </StakeLiquidityModalProvider>
    </>
  )
}
