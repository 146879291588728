import { BigNumber } from '@ethersproject/bignumber'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '@uniswap/sdk-core'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { Erc721 } from 'uniswap/src/abis/types'
import { getContract } from 'utilities/src/contracts/getContract'
import { getApiChainParam } from '../../constants/chains'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { useContract } from '../../hooks/useContract'
import { getPoolConfig, postMyPoolsClaimSign } from '../../service/pools.api'
import stakePoolAbi from './stakePoolAbi.json'
import { stakePoolChainId } from './stakePoolConst'

export function useStakePool() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()

      const contractAddress = config.stake_contract?.[stakePoolChainId]

      if (!positionManagerContract || !account || !provider) return
      const res = await positionManagerContract.isApprovedForAll(account, contractAddress)

      if (!res) {
        const res = await positionManagerContract.setApprovalForAll(contractAddress, true)
      }
      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      // const estimatedGas = await stakeContract.estimateGas.deposit(positionManager, tokenId)
      const txRes = await stakeContract.deposit(positionManager, tokenId, {
        // gasLimit: calculateGasMargin(estimatedGas),
      })
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}
export function useUnStakePool() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()

      const contractAddress = config.stake_contract?.[stakePoolChainId]

      if (!positionManagerContract || !account || !provider) return
      const res = await positionManagerContract.isApprovedForAll(account, contractAddress)

      if (!res) {
        const res = await positionManagerContract.setApprovalForAll(contractAddress, true)
      }
      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      const txRes = await stakeContract.withdraw(positionManager, tokenId)
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}
export function useClaimReward() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber, reward_token_address: string) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()
      const contractAddress = config.stake_contract?.[stakePoolChainId]
      //
      if (!positionManagerContract || !account || !provider) return
      const signer = provider.getSigner(account)
      const params = {
        chain: getApiChainParam(stakePoolChainId),
        reward_address: account,
        timestamp: Date.now(),
        token_address: positionManager,
        reward_token_address,
        token_id: Number(tokenId.toString()),
      }
      const msg = Object.keys(params)
        .map((key, index) => {
          // @ts-ignore
          return `${key}: ${params?.[key]}`
        })
        .join('\n')
      const signature = await signer.signMessage(msg)
      const res = await postMyPoolsClaimSign({
        ...params,
        signature,
      })

      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      const txRes = await stakeContract.claimReward(
        res.tokenAddress,
        res.tokenId,
        res.rewardTokenAddress,
        res.amount,
        res.totalAmount,
        res.to,
        res.expireTimestamp,
        res.signature
      )
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}
