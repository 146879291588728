import { Box, Divider, Group, Image, Loader, Stack, Switch, Text, UnstyledButton, createStyles } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RiShutDownLine } from 'react-icons/ri'
import { shortenAddress } from 'utilities/src/addresses'
import { STATIC_BASEURL } from '../../constants/detrade'
import useENSName from '../../hooks/useENSName'
import { EmptyWalletModule } from '../../nft/components/profile/view/EmptyWalletContent'
import { getDetradeShare } from '../../service/pools.api'
import { useAppDispatch } from '../../state/hooks'
import { setRecentConnectionDisconnected } from '../../state/user/reducer'
import { mantissaNumber } from '../../utils/detrade/number'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { miniPortfolioMenuStateAtom } from '../AccountDrawer/DefaultMenu'
import { StatusIndicator } from '../AccountDrawer/MiniPortfolio/Activity/ActivityRow'
import { useOpenOffchainActivityModal } from '../AccountDrawer/MiniPortfolio/Activity/OffchainActivityModal'
import { useAllActivities } from '../AccountDrawer/MiniPortfolio/Activity/hooks'
import { Activity } from '../AccountDrawer/MiniPortfolio/Activity/types'
import { createGroups } from '../AccountDrawer/MiniPortfolio/Activity/utils'
import { PortfolioLogo } from '../AccountDrawer/MiniPortfolio/PortfolioLogo'
import { PortfolioSkeleton } from '../AccountDrawer/MiniPortfolio/PortfolioRow'
import { useToggleAccountDrawer } from '../AccountDrawer/MiniPortfolio/hooks'
import { hideSpamAtom } from '../AccountDrawer/SpamToggle'
import { showTestnetsAtom } from '../AccountDrawer/TestnetsToggle'
import { LoadingBubble } from '../Tokens/loading'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
  }
})

function ActivityItem({ activity }: { activity: Activity }) {
  const { chainId, title, descriptor, logos, otherAccount, currencies, hash, prefixIconSrc, offchainOrderDetails } =
    activity

  const openOffchainActivityModal = useOpenOffchainActivityModal()

  const { ENSName } = useENSName(otherAccount)
  const explorerUrl = getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)

  const onClick = useCallback(() => {
    if (offchainOrderDetails) {
      openOffchainActivityModal(offchainOrderDetails, {
        inputLogo: activity?.logos?.[0],
        outputLogo: activity?.logos?.[1],
      })
      return
    }

    window.open(getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION), '_blank')
  }, [activity?.logos, chainId, hash, offchainOrderDetails, openOffchainActivityModal])

  return (
    <UnstyledButton onClick={onClick}>
      <Group spacing={12} noWrap>
        <PortfolioLogo chainId={chainId} currencies={currencies} images={logos} accountAddress={otherAccount} />
        <Stack sx={{ flexGrow: 1, overflow: 'hidden' }} spacing={0}>
          <Group spacing={4}>
            {prefixIconSrc && <img height="14px" width="14px" src={prefixIconSrc} alt="" />}
            <Text fw={500} fz={14} lh="20px" color="#0B132A">
              {title}
            </Text>
          </Group>

          <Text fw={400} fz={12} lh="16px" color="#959AA4">
            <Group spacing={4} className="ellipsis">
              {descriptor}
              {ENSName ?? shortenAddress(otherAccount)}
            </Group>
          </Text>
        </Stack>
        <StatusIndicator activity={activity} />
      </Group>
    </UnstyledButton>
  )
}

function AccountActivity({ account }: any) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()

  const toggleAccountDrawer = useToggleAccountDrawer()
  const setMenu = useUpdateAtom(miniPortfolioMenuStateAtom)

  const { activities, loading } = useAllActivities(account)

  const hideSpam = useAtomValue(hideSpamAtom)
  const activityGroups = useMemo(() => createGroups(activities, hideSpam), [activities, hideSpam])
  const { connector } = useWeb3React()
  const dispatch = useAppDispatch()
  const disconnect = useCallback(() => {
    connector.deactivate?.()
    connector.resetState()
    dispatch(setRecentConnectionDisconnected())
  }, [connector, dispatch])

  const [showTestnets, updateShowTestnets] = useAtom(showTestnetsAtom)

  const {
    data = {
      detrade_share: 0,
    },
    loading: detradeShareLoading,
  } = useRequest(
    async () => {
      const res = await getDetradeShare({
        address: account,
      })
      return res
    },
    {
      refreshDeps: [account],
      ready: !!account,
    }
  )
  return (
    <div className={classes.root}>
      <Stack spacing={20} w="100%">
        <Stack spacing={12}>
          <Group position="apart">
            <Text fw={500} fz={16} lh="20px" color="#0B132A">
              Activity
            </Text>

            <Group>
              <Switch
                size="xs"
                label={t`Show testnets`}
                checked={showTestnets}
                onChange={(event) => updateShowTestnets(event.currentTarget.checked)}
              />
              <UnstyledButton onClick={disconnect}>
                <Group
                  spacing={4}
                  sx={({ colors }) => ({
                    background: colors.bg[2],
                    borderRadius: 4,
                    minWidth: 96,
                    height: 24,
                    color: '#4F5665',
                    padding: '4px 8px',
                    '&:hover': {
                      background: colors.red[0],
                      color: colors.red[9],
                    },
                  })}
                >
                  <RiShutDownLine size={12} />
                  <Text fw={400} fz={12} lh="16px">
                    Disconnect
                  </Text>
                </Group>
              </UnstyledButton>
            </Group>
          </Group>

          <Group
            position="apart"
            py={8}
            px={10}
            sx={({ colors }) => ({
              background: '#F1F1FE',
              borderRadius: 4,
            })}
          >
            <Text fw={400} fz={14} lh="16px" color="#3C38F5">
              Detrade Share：
            </Text>
            {loading ? (
              <Loader variant="dots" />
            ) : (
              <Group spacing={4}>
                <Image src={`${STATIC_BASEURL}/images/detrade/icon-detrade-share.svg`} height={14} width="auto" />

                <Text fw={500} fz={14} lh="16px" color="#3C38F5">
                  {mantissaNumber(data.detrade_share)}
                </Text>
              </Group>
            )}
          </Group>
        </Stack>

        <Divider color="#F5F5F5" />

        {activityGroups.length === 0 && (
          <Box px={16} py={activityGroups.length ? 40 : 10}>
            {detradeShareLoading ? (
              <>
                <LoadingBubble height="16px" width="80px" margin="16px 16px 8px" />
                <PortfolioSkeleton shrinkRight />
              </>
            ) : (
              <>
                <EmptyWalletModule type="activity" onNavigateClick={toggleAccountDrawer} />
              </>
            )}
          </Box>
        )}

        {activityGroups.map((activityGroup) => (
          <Stack spacing={8} key={activityGroup.title}>
            <Text fw={400} fz={16} lh="20px" color="#959AA4">
              {activityGroup.title}
            </Text>

            <Stack spacing={16}>
              {activityGroup.transactions.map(
                (activity) => !(hideSpam && activity.isSpam) && <ActivityItem key={activity.hash} activity={activity} />
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  )
}

export default AccountActivity
