import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  CopyButton,
  Divider,
  Grid,
  Group,
  Image,
  Stack,
  Text,
  Tooltip,
  createStyles,
} from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { useMemo } from 'react'
import { PiCopyBold } from 'react-icons/pi'
import { RiArrowDownSFill, RiArrowUpSFill, RiShareBoxLine } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateTokenItem } from 'service/createToken.api.d'
import { shortenAddress } from 'utilities/src/addresses'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { STATIC_BASEURL } from '../../constants/detrade'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { formatShowNumber } from '../../utils/detrade/number'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import ModalManage from './ModalManage'
import { createTokenChainId } from './createTokenConst'
import { useDisplayERC20Token } from './useCreateTokenContract'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    value: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '36px',
      color: '#3C38F5',

      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    label: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22px',
      color: '#959AA4',
    },
  }
})
function toScientificNotation(numStr: string | number) {
  // 将字符串转换为数字
  const num = Number(numStr)

  // 检查转换后的值是否为有效数字
  if (isNaN(num)) {
    throw new Error('Invalid number string')
  }

  // 将数字转换为科学记数法表示法
  return num.toExponential().replace('+', '^')
}
function TokenItem({ item, onRefresh }: { item: CreateTokenItem; onRefresh: () => void }) {
  const [opened, { toggle }] = useDisclosure(false)

  const { classes } = useStyles()
  const address = item.token_address
  const explorerUrl = getExplorerLink(createTokenChainId, item.token_address, ExplorerDataType.TOKEN)
  const list = useMemo(() => {
    return [
      {
        label: 'Token Symbol',
        value: <>{item.symbol}</>,
      },
      // {
      //   label: 'Created by',
      //   value: (
      //     <Group spacing={8}>
      //       <Text>{shortenAddress(address)}</Text>
      //       <Group spacing={4}>
      //         <ActionIcon size={20}>
      //           <RiShareBoxLine size={16} color="#808080" />
      //         </ActionIcon>
      //         <CopyButton value={address || ''} timeout={1000}>
      //           {({ copy, copied }) => (
      //             <Tooltip label="Copied" withinPortal opened={copied}>
      //               <ActionIcon
      //                 size={20}
      //                 onClick={() => {
      //                   copy()
      //                 }}
      //               >
      //                 <PiCopyBold size={16} color="#808080" />
      //               </ActionIcon>
      //             </Tooltip>
      //           )}
      //         </CopyButton>
      //       </Group>
      //     </Group>
      //   ),
      // },
      {
        label: 'Holders',
        value: (
          <Group spacing={8}>
            <Text>Token Holders Chart</Text>
            <Group spacing={4}>
              <a href={explorerUrl + '?tab=holders'} target="_blank" rel="noopener noreferrer">
                <ActionIcon size={20}>
                  <RiShareBoxLine size={16} color="#808080" />
                </ActionIcon>
              </a>
            </Group>
          </Group>
        ),
      },
      {
        label: 'Balance',
        value: <>{formatShowNumber(item.balance || '')}</>,
      },
      // {
      //   label: 'Special Features',
      //   value: (
      //     <>
      //       Burn {Number(item?.burn || 0) * 100}% / Trading Fees {Number(item.trading_fee || 0) * 100}%
      //     </>
      //   ),
      // },
    ]
  }, [explorerUrl, item.balance, item?.burn, item.symbol, item.trading_fee])
  const [openedModal, { open: openModal, close: closeModal }] = useDisclosure(false)
  const displayERC20Token = useDisplayERC20Token(createTokenChainId)
  const { loading: displayTokenLoading, run: displayToken } = useRequest(
    async () => {
      await displayERC20Token({
        address,
        symbol: item.symbol || undefined,
        decimals: item.decimals || undefined,
      })
    },
    {
      manual: true,
    }
  )
  const tokenSupply = useMemo(() => {
    const num = String(item.total_supply || 0)
    return formatShowNumber(num)
  }, [item.total_supply])

  return (
    <Box
      sx={{
        border: '1px solid #BABDC466',
        padding: 20,
        borderRadius: 16,
      }}
    >
      <Stack spacing={20}>
        <Group spacing={12}>
          <Text fw={600} fz={18} lh="26px" color="#0B132A">
            {shortenAddress(address)}
          </Text>
          <Group spacing={4}>
            <a href={explorerUrl} target="_blank" rel="noopener noreferrer">
              <ActionIcon size={20}>
                <RiShareBoxLine size={16} color="#808080" />
              </ActionIcon>
            </a>
            <CopyButton value={address || ''} timeout={1000}>
              {({ copy, copied }) => (
                <Tooltip label="Copied" withinPortal opened={copied}>
                  <ActionIcon
                    size={20}
                    onClick={() => {
                      copy()
                    }}
                  >
                    <PiCopyBold size={16} color="#808080" />
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>

            <ActionIcon size={20} onClick={displayToken} loading={displayTokenLoading}>
              <Image width={13} height={12} src={`${STATIC_BASEURL}/images/detrade/create-token/metamask.svg`} />
            </ActionIcon>
          </Group>
        </Group>

        <Stack spacing={12} align="center">
          <Group spacing={20} position="center" w="100%" noWrap>
            <Stack spacing={0} sx={{ flex: '1', overflow: 'hidden' }}>
              <Text className={classes.value}>{item.symbol}</Text>
              <Text className={classes.label}>Token Symbol</Text>
            </Stack>
            <Stack spacing={0} sx={{ flex: '1', overflow: 'hidden' }}>
              <Text className={classes.value}>{tokenSupply}</Text>
              <Text className={classes.label}>Token Supply</Text>
            </Stack>
          </Group>
          <ActionIcon size={20} radius={20} variant="filled" onClick={toggle} sx={{ background: '#959AA4' }}>
            {opened ? <RiArrowUpSFill /> : <RiArrowDownSFill />}
          </ActionIcon>
        </Stack>
        <Collapse in={opened}>
          <Stack spacing={20}>
            <Divider color="#BABDC466" />
            <Stack spacing={16}>
              {list.map((item, index) => (
                <Stack spacing={0} key={index}>
                  <Text fw={400} fz={14} lh="22px" color="#959AA4">
                    {item.label}
                  </Text>
                  <Text fw={500} fz={20} lh="28px" color="#0B132A" lineClamp={1}>
                    {item.value}
                  </Text>
                </Stack>
              ))}
            </Stack>
            <Button size="lg" fullWidth onClick={openModal}>
              Manage
            </Button>
          </Stack>
        </Collapse>
      </Stack>
      <ModalManage opened={openedModal} onClose={closeModal} item={item} onRefresh={onRefresh} />
    </Box>
  )
}

export default function CreateTokenList({ list, onRefresh }: { list: any[]; onRefresh: () => void }) {
  const { classes } = useStyles()
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const navigate = useNavigate()

  const [opened, { toggle }] = useDisclosure(false)

  return (
    <Box>
      <Grid gutter={24}>
        {list.map((item, index) => (
          <Grid.Col sm={6} key={index} md={4}>
            <TokenItem item={item} onRefresh={onRefresh} />
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  )
}
