import { ActionIcon, Collapse, Divider, Group, Image, Stack, Text } from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import { useMemo } from 'react'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

function LaunchRuleItem({ item, index }: { item: any; index: number }) {
  const [opened, { toggle }] = useDisclosure(index === 0)

  return (
    <Stack spacing={12} key={index}>
      <Group position="apart" align="top" noWrap onClick={toggle} sx={{ cursor: 'pointer' }}>
        <Text fw={600} fz={16} lh="24px" color="#0B132A">
          {index + 1}. {item.title}
        </Text>
        <ActionIcon
          size={24}
          radius={4}
          variant="filled"
          sx={{
            background: '#00000014!important',
          }}
        >
          {opened ? <RiArrowUpSFill size={20} color="#0B132A" /> : <RiArrowDownSFill size={20} color="#0B132A" />}
        </ActionIcon>
      </Group>
      <Collapse in={opened}>
        <Stack spacing={12}>
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            {item.desc}
          </Text>
          {item.media && <Image width={550} height="auto" src={item.media} />}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default function LaunchRules({ list }: { list: any[] }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const content = useMemo(() => {
    const ary = list.map((item, index) => {
      return <LaunchRuleItem item={item} index={index} key={index} />
    })
    const res = ary.reduce((acc: any, item, index) => {
      acc.push(item)
      if (index !== ary.length - 1) {
        acc.push(<Divider color="#BABDC480" key={`Divider${index}`} />)
      }
      return acc
    }, [])
    return res
  }, [list])

  return <>{content}</>
}
