import { STATIC_BASEURL } from '../../constants/detrade'
import {
  USDC_ALIENX_HAL,
  USDC_ALIENXCHAIN,
  USDT_ALIENX_HAL,
  USDT_ALIENXCHAIN,
  WETH_ALIENX_HAL,
  WETH_ALIENXCHAIN,
} from '../../constants/tokens'

const list = [
  WETH_ALIENX_HAL.address,
  USDT_ALIENX_HAL.address,
  USDC_ALIENX_HAL.address,
  WETH_ALIENXCHAIN.address,
  USDT_ALIENXCHAIN.address,
  USDC_ALIENXCHAIN.address,
]

export const alienxTokenLogoMap = list.reduce<{ [_: string]: string }>((pre, address) => {
  pre[address] = `${STATIC_BASEURL}/images/detrade/token-logo/${address}.png`
  return pre
}, {})
