// import NotificationTransactionView from '@/components/NotificationTransactionView';
import { Text } from '@asuikit/core'
import type { NotificationProps } from '@asuikit/notifications'
import type { TransactionResponse } from '@ethersproject/providers'
import i18next from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { RiCheckboxCircleFill, RiCloseCircleFill, RiErrorWarningFill } from 'react-icons/ri'

const getMantineNotifications = () => {
  return import(/* webpackChunkName: "sync-common" */ '@asuikit/notifications')
}

const isBrowser = true

const notification = {
  toast: async (message: string) => {
    const { showNotification } = await getMantineNotifications()
    showNotification({
      message,
      color: 'black',
      autoClose: false,
      // styles(theme, params, context) {
      //   return {
      //     root: {
      //       position: 'fixed',
      //       top: '50%',
      //       left: '50%',
      //       transform: 'translate3d(-50%,-50%,0)',
      //       '&:before': {
      //         display: 'none',
      //       },
      //     },
      //   };
      // },
    })
  },
  warn: async (
    message:
      | string
      | number
      | boolean
      | React.ReactFragment
      | React.ReactPortal
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | null
      | undefined,
    props?: Partial<NotificationProps>
  ) => {
    if (!isBrowser) return

    const { showNotification } = await getMantineNotifications()

    showNotification({
      message: props?.title
        ? message
        : React.createElement(
            'div',
            {
              className: 'mt-[2px]',
            },
            message
          ),
      title: props?.title,
      icon: React.createElement(RiErrorWarningFill),
      color: 'orange',
      autoClose: false,
      ...props,
    })
  },
  error: async (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    props?: Partial<NotificationProps>
  ) => {
    if (!isBrowser) return

    const { showNotification } = await getMantineNotifications()
    // const { AlertCircle } = await import('tabler-icons-react');

    showNotification({
      message: props?.title
        ? message
        : React.createElement(
            'div',
            {
              className: 'mt-[2px]',
            },
            message
          ),
      color: 'red',
      title: props?.title /* ?? `${i18next.t('error')}!` */,
      icon: React.createElement(RiCloseCircleFill),
      ...props,
    })
  },
  networkError: async (code = '000000') => {
    if (!isBrowser) return
    console.error('unknown error occur')
    // const { AlertCircle } = await import('tabler-icons-react');
    const { showNotification } = await getMantineNotifications()
    showNotification({
      message: React.createElement(Trans, {
        t: i18next.t,
        i18nKey: 'network_error',
        values: {
          code,
        },
        components: {
          a: React.createElement(Text as any, {
            variant: 'link',
            component: 'a',
            style: { color: '#1971c2' },
            href: 'https://discord.com/invite/6SzkW9hNRa',
          }),
        },
      }),
      color: 'red',
      title: `${i18next.t('error')}!`,
      icon: React.createElement(RiCloseCircleFill),
    })
  },
  success: async (
    message:
      | string
      | number
      | boolean
      | React.ReactFragment
      | React.ReactPortal
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | null
      | undefined,
    props?: NotificationProps
  ) => {
    if (!isBrowser) return
    // const { CircleCheck } = await import('tabler-icons-react');
    const { showNotification } = await getMantineNotifications()
    showNotification({
      message: props?.title
        ? message
        : React.createElement(
            'div',
            {
              className: 'mt-[2px]',
            },
            message
          ),
      color: 'green',
      // title: `${i18next.t('error')}!`,
      icon: React.createElement(RiCheckboxCircleFill),
      ...props,
    })
  },
  _loadingTransactionSet: new Set<TransactionResponse & { text?: string }>(),
  _loadingTransactionShow: false,
  loadingTransactionId: 'notification-loading-transaction',
}

const getMantineModal = () => {
  return import(/* webpackChunkName: "sync-common" */ '@asuikit/modals')
}

export default notification

if (isBrowser) {
  ;(window as any).notification = notification
}
