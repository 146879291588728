import { Text } from '@asuikit/core'
import { useCountDown } from 'ahooks'
import { FormattedRes } from 'ahooks/lib/useCountDown'
import dayjs from 'dayjs'
import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { LaunchDetailContext } from './detail'

export default function LaunchTimeCountdown({ startTime, endTime }: { startTime: number; endTime: number }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()
  const launchDetailContext = useContext(LaunchDetailContext)

  const isEnd = useMemo(() => {
    return dayjs().isAfter(endTime)
  }, [endTime])
  const isActive = useMemo(() => {
    return dayjs().isBefore(endTime) && dayjs().isAfter(startTime)
  }, [endTime, startTime])

  const targetDate = useMemo(() => {
    if (isActive) return endTime
    if (isEnd) return undefined
    return startTime
  }, [endTime, isActive, isEnd, startTime])
  const [countdown, formattedRes] = useCountDown({
    targetDate,
    onEnd() {
      launchDetailContext.refresh()
    },
  })
  const { days, hours, minutes, seconds, milliseconds } = Object.keys(formattedRes).reduce((acc, key) => {
    acc[key] = String(formattedRes?.[key as keyof FormattedRes] || 0).padStart(2, '0')
    return acc
  }, {} as any)
  return (
    <>
      {isEnd ? (
        <Text>Distributing</Text>
      ) : (
        <Text>
          {isActive ? 'Ends' : 'Begin'} in <span hidden={days === '00'}>{days}:</span>
          {hours}:{minutes}:{seconds}
        </Text>
      )}
    </>
  )
}
