import { Box, createStyles, Group, Stack, Text } from '@asuikit/core'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},

    box: {
      width: '100%',
      padding: 16,
      borderRadius: 12,
      background: colors.bg[2],
    },
  }
})

interface PriceRangeDataProps {
  num: any
  quoteCurrency: any
  text?: any
}

function PriceRangeView({
  priceSize = 16,
  minData,
  maxData,
}: {
  priceSize?: number
  minData: PriceRangeDataProps
  maxData: PriceRangeDataProps
}) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()

  return (
    <div className={classes.root}>
      <Stack spacing={6} className={classes.box}>
        <Group spacing={6} fw={400} fz={12} lh="16px" position="apart" sx={{ color: '#959AA4' }}>
          <Box>Min price</Box>
          <Box>Max price</Box>
        </Group>

        <Group spacing={6} fw={500} fz={priceSize} lh="20px" position="apart" sx={{ color: '#0B132A' }}>
          <Box sx={{ flex: '1!important' }}>{minData.num}</Box>
          <Text>{maxData.num}</Text>
        </Group>
        <Group spacing={6} fw={400} fz={12} lh="16px" position="apart" sx={{ color: '#959AA4' }}>
          <Box>{minData.quoteCurrency}</Box>
          <Box>{maxData.quoteCurrency}</Box>
        </Group>
      </Stack>
    </div>
  )
}

export default PriceRangeView
