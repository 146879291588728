import { Button, createStyles, Modal, Stack, Text } from '@asuikit/core'
import { Currency, Percent } from '@uniswap/sdk-core'
import { useRequest } from 'ahooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TransactionStatus } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { useConfirmModalState } from '../../../../../hooks/useConfirmModalState'
import { Allowance, AllowanceState } from '../../../../../hooks/usePermit2Allowance'
import { SwapResult } from '../../../../../hooks/useSwapCallback'
import { Trans } from '../../../../../i18n'
import { useSuppressPopups } from '../../../../../state/application/hooks'
import { PopupType } from '../../../../../state/application/reducer'
import { InterfaceTrade, TradeFillType } from '../../../../../state/routing/types'
import { isLimitTrade, isPreviewTrade } from '../../../../../state/routing/utils'
import { useOrder } from '../../../../../state/signatures/hooks'
import { useSwapTransactionStatus } from '../../../../../state/transactions/hooks'
import { FadePresence } from '../../../../../theme/components/FadePresence'
import { UniswapXOrderStatus } from '../../../../../types/uniswapx'
import { SignatureExpiredError } from '../../../../../utils/errors'
import { didUserReject } from '../../../../../utils/swapErrorToUserReadableMessage'
import { ConfirmModalState } from '../../../../ConfirmSwapModal'
import SwapError, { PendingModalError } from '../../../../ConfirmSwapModal/Error'
import { Pending } from '../../../../ConfirmSwapModal/Pending'
import { MODAL_TRANSITION_DURATION } from '../../../../Modal'
import { Field } from '../../../../swap/constants'
import SwapDetailsDropdown from '../../../../swap/SwapDetailsDropdown'
import ReviewPrice from './components/ReviewPrice'
import WalletPending from './components/WalletPending'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
  }
})
interface CallToAction {
  buttonText: string
  helpLink?: any
}

const Container = styled.div<{ $height?: string; $padding?: string }>`
  height: ${({ $height }) => $height ?? ''};
  padding: ${({ $padding }) => $padding ?? ''};
`

function ReviewSwap({
  opened,
  onClose,
  trade,
  originalTrade,
  inputCurrency,
  allowance,
  allowedSlippage,
  fiatValueInput,
  fiatValueOutput,
  swapResult,
  swapError,
  priceImpact,
  clearSwapState,
  onAcceptChanges,
  onConfirm,
  onCurrencySelection,
}: {
  opened: boolean
  onClose: () => void
  trade: InterfaceTrade
  originalTrade?: InterfaceTrade
  inputCurrency?: Currency
  allowance: Allowance
  allowedSlippage: Percent
  fiatValueInput: { data?: number; isLoading: boolean }
  fiatValueOutput: { data?: number; isLoading: boolean }
  swapResult?: SwapResult
  swapError?: Error
  priceImpact?: Percent
  clearSwapState: () => void
  onAcceptChanges?: () => void
  onConfirm: () => void
  onCurrencySelection: (field: Field, currency: Currency) => void
}) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()

  const [priceIsChange, setPriceIsChange] = useState(true)

  const { run: confirm, loading } = useRequest(
    async () => {
      if (priceIsChange) {
        onClose()
      }
    },
    {
      manual: true,
    }
  )
  const {
    confirmModalState,
    pendingModalSteps,
    priceUpdate,
    doesTradeDiffer,
    approvalError,
    wrapTxHash,
    startSwapFlow,
    onCancel,
  } = useConfirmModalState({
    trade,
    originalTrade,
    allowance,
    allowedSlippage,
    onCurrencySelection,
    onSwap: () => {
      clearSwapState()
      onConfirm()
    },
  })

  // Get status depending on swap type
  const swapStatus = useSwapTransactionStatus(swapResult)
  const uniswapXOrder = useOrder(swapResult?.type === TradeFillType.UniswapX ? swapResult.response.orderHash : '')

  // Has the transaction been confirmed onchain?
  const swapConfirmed =
    swapStatus === TransactionStatus.Confirmed || uniswapXOrder?.status === UniswapXOrderStatus.FILLED

  // Has a limit order been submitted?
  const limitPlaced = isLimitTrade(trade) && uniswapXOrder?.status === UniswapXOrderStatus.OPEN

  // Has the transaction failed locally (i.e. before network or submission), or has it been reverted onchain?
  const localSwapFailure = Boolean(swapError) && !didUserReject(swapError)
  const swapReverted = swapStatus === TransactionStatus.Failed
  const swapFailed = localSwapFailure || swapReverted
  const errorType = useMemo(() => {
    if (approvalError) return approvalError
    if (swapError instanceof SignatureExpiredError) return
    if (swapError && !didUserReject(swapError)) return PendingModalError.CONFIRMATION_ERROR
    return
  }, [approvalError, swapError])

  // Determine which view to show based on confirm modal state and other conditions
  const { showPreview, showDetails, showProgressIndicator, showAcceptChanges, showConfirming, showSuccess, showError } =
    useMemo(() => {
      const showAcceptChanges = confirmModalState !== ConfirmModalState.PENDING_CONFIRMATION && doesTradeDiffer
      let showPreview, showDetails, showProgressIndicator, showConfirming, showSuccess, showError
      if (errorType) {
        // When any type of error is encountered (except for SignatureExpiredError, which has special retry logic)
        showError = true
      } else if (swapConfirmed || limitPlaced) {
        showSuccess = true
      } else if (confirmModalState === ConfirmModalState.REVIEWING || showAcceptChanges) {
        // When swap is in review, either initially or to accept changes, show the swap details
        showPreview = true
        showDetails = true
      } else if (pendingModalSteps.length > 1) {
        // When a multi-step swap is in progress (i.e. not in review and not yet confirmed), show the progress indicator
        showPreview = true
        showProgressIndicator = true
      } else {
        // When a single-step swap requires confirmation, show a loading spinner (possibly followed by a submission icon)
        showConfirming = true
      }
      return {
        showPreview,
        showDetails,
        showProgressIndicator,
        showAcceptChanges,
        showConfirming,
        showSuccess,
        showError,
      }
    }, [confirmModalState, doesTradeDiffer, errorType, limitPlaced, pendingModalSteps.length, swapConfirmed])

  const disabledConfirm = useMemo(() => {
    return showAcceptChanges || isPreviewTrade(trade) || allowance.state === AllowanceState.LOADING
  }, [allowance.state, showAcceptChanges, trade])

  const swapErrorMessage = useMemo(() => {
    return swapFailed ? swapError?.message : undefined
  }, [swapError?.message, swapFailed])

  const callToAction: CallToAction = useMemo(() => {
    if (allowance && allowance.state === AllowanceState.REQUIRED && allowance.needsSetupApproval) {
      return {
        buttonText: isLimitTrade(trade) ? t`Approve and submit` : t`Approve and swap`,
      }
    } else if (allowance && allowance.state === AllowanceState.REQUIRED && allowance.needsPermitSignature) {
      return {
        buttonText: t`Sign and swap`,
      }
    } else {
      return {
        buttonText: isLimitTrade(trade) ? t`Place order` : t`Confirm swap`,
      }
    }
  }, [allowance, t, trade])

  // Reset modal state if user rejects the swap
  useEffect(() => {
    if (swapError && !swapFailed) {
      onCancel()
    }
  }, [onCancel, swapError, swapFailed])

  const { suppressPopups, unsuppressPopups } = useSuppressPopups([PopupType.Transaction, PopupType.Order])

  const onModalDismiss = useCallback(() => {
    onClose()
    setTimeout(() => {
      // Reset local state after the modal dismiss animation finishes, to avoid UI flicker as it dismisses
      onCancel()
    }, MODAL_TRANSITION_DURATION)
    // Popups are suppressed when modal is open; re-enable them on dismissal
    unsuppressPopups()
  }, [onCancel, onClose, unsuppressPopups])

  return (
    <div className={classes.root}>
      <Modal
        size={480}
        title="Review swap"
        opened={opened}
        onClose={onModalDismiss}
        closeOnClickOutside={!showConfirming}
        centered
        styles={({ colors, fn }) => ({
          content: {
            background: colors.bg[1],
            // height: 536,
          },
          header: {
            background: colors.bg[1],
            height: 64,
            padding: '20px 24px',
            // borderBottom: '1px solid' + colors.line[2],
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0B132A',
          },
          body: {
            padding: '16px 24px',
          },
        })}
      >
        <Stack spacing={16}>
          {showPreview && <ReviewPrice inputCurrency={inputCurrency} trade={trade} allowedSlippage={allowedSlippage} />}
          {showDetails && (
            <>
              <SwapDetailsDropdown
                hiddenMoreBtn={true}
                priceImpact={priceImpact}
                trade={trade}
                allowedSlippage={allowedSlippage}
              />

              {showAcceptChanges ? (
                <Button fullWidth size="xl" loading={loading} onClick={onAcceptChanges}>
                  Accept price update
                </Button>
              ) : (
                <>
                  <Button
                    fullWidth
                    size="xl"
                    onClick={() => {
                      suppressPopups()
                      startSwapFlow()
                    }}
                    disabled={disabledConfirm}
                    loading={isPreviewTrade(trade)}
                  >
                    {isPreviewTrade(trade) ? (
                      <Trans>Finalizing quote...</Trans>
                    ) : (
                      <Text>{callToAction.buttonText}</Text>
                    )}
                  </Button>

                  {swapErrorMessage ? <Text color="red">{swapErrorMessage}</Text> : null}
                </>
              )}
            </>
          )}
        </Stack>

        {/* Progress indicator displays all the steps of the swap flow and their current status  */}
        {confirmModalState !== ConfirmModalState.REVIEWING && showProgressIndicator && (
          <>
            <WalletPending
              steps={pendingModalSteps}
              currentStep={confirmModalState}
              trade={trade}
              swapResult={swapResult}
              wrapTxHash={wrapTxHash}
              tokenApprovalPending={allowance.state === AllowanceState.REQUIRED && allowance.isApprovalPending}
              revocationPending={allowance.state === AllowanceState.REQUIRED && allowance.isRevocationPending}
              swapError={swapError}
              onRetryUniswapXSignature={onConfirm}
            />
          </>
        )}

        {/* Pending screen displays spinner for single-step confirmations, as well as success screen for all flows */}
        {(showConfirming || showSuccess) && (
          <Container>
            <FadePresence>
              <Pending
                trade={trade}
                swapResult={swapResult}
                wrapTxHash={wrapTxHash}
                tokenApprovalPending={allowance.state === AllowanceState.REQUIRED && allowance.isApprovalPending}
                revocationPending={allowance.state === AllowanceState.REQUIRED && allowance.isRevocationPending}
              />
            </FadePresence>
          </Container>
        )}
        {/* Error screen handles all error types with custom messaging and retry logic */}
        {errorType && showError && (
          <SwapError trade={trade} swapResult={swapResult} errorType={errorType} onRetry={startSwapFlow} />
        )}
      </Modal>
    </div>
  )
}

export default ReviewSwap
