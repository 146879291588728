import { AppShell, Box, Center, Container, Grid, Group, Loader, Pagination, Skeleton, Stack, Text } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { useLocation, useNavigate } from 'react-router-dom'
import NoData from '../../components/detrade/NoData'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { getCreatorTokenList, getTotalNumber } from '../../service/createToken.api'
import { mantissaNumber } from '../../utils/detrade/number'
import CreateTokenForm from './CreateTokenForm'
import CreateTokenList from './CreateTokenList'

export default function CreateTokenPage({ className }: { className?: string }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const navigate = useNavigate()
  const { data: totalNumber = 0, loading: totalNumberLoading } = useRequest(
    async () => {
      const res = await getTotalNumber()
      return res?.total
    },
    {
      pollingInterval: 10000,
    }
  )
  const pageSize = 6
  const {
    data = {
      list: [],
      total: 0,
      page: 1,
    },
    mutate,
    run,
    loading,
    refresh,
  } = useRequest(
    async (page = 1) => {
      if (!account)
        return {
          list: [],
          total: 0,
          page: 1,
        }
      const res = await getCreatorTokenList({
        pageSize,
        page,
        address: account,
      })
      return {
        list: res.results || [],
        total: res.total,
        page,
      }
    },
    {
      refreshDeps: [account],
      pollingInterval: 10000,
    }
  )
  return (
    <AppShell
      padding={0}
      // footer={<Footer fixed={false} />}
      styles={(theme) => ({
        main: {
          background: '#FFFFFF',
        },
      })}
    >
      <Container mt={24} pb={40} px={smScreen ? 60 : 16} size={1440}>
        <Text fw={700} fz={48} lh="56px" color="#0B132A" ta="center">
          Create your own tokens with a single click!
          <Text>
            <Text span color="#3C38F5">
              {!totalNumber && totalNumberLoading ? <Loader variant="dots" /> : <> {mantissaNumber(totalNumber, 0)}</>}
            </Text>{' '}
            Tokens Created
          </Text>
        </Text>
        <Box mt={13}>
          <CreateTokenForm onSuccess={refresh} />
        </Box>
        <Box mt={60}>
          <Text fw={700} fz={24} lh="32px" color="#0B132A" pb={24}>
            My Token List
          </Text>
          {data?.list?.length <= 0 && loading ? (
            <>
              <Grid gutter={24}>
                {new Array(9).fill(null).map((_, i) => (
                  <Grid.Col sm={6} key={i} md={4}>
                    <Box
                      sx={{
                        border: '1px solid #BABDC466',
                        padding: 20,
                        borderRadius: 16,
                      }}
                    >
                      <Stack spacing={20}>
                        <Group spacing={12}>
                          <Skeleton height={26} />
                        </Group>

                        <Stack spacing={12} align="center">
                          <Group spacing={20} position="center" w="100%">
                            <Stack spacing={0} sx={{ flex: '1' }}>
                              <Skeleton height={30} mb={6} />
                              <Skeleton height={22} />
                            </Stack>
                            <Stack spacing={0} sx={{ flex: '1' }}>
                              <Skeleton height={30} mb={6} />
                              <Skeleton height={22} />
                            </Stack>
                          </Group>

                          <Skeleton height={20} circle />
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid.Col>
                ))}
              </Grid>
            </>
          ) : (
            <>
              {data?.list?.length > 0 ? (
                <>
                  <CreateTokenList list={data?.list || []} onRefresh={run} />

                  {Math.ceil(data.total / pageSize) > 1 && (
                    <Center py={32}>
                      <Pagination
                        radius="lg"
                        boundaries={2}
                        siblings={2}
                        value={data.page}
                        onChange={(page) => {
                          mutate({
                            ...data,
                            list: [],
                          })
                          run(page)
                        }}
                        total={Math.ceil(data.total / pageSize)}
                      />
                    </Center>
                  )}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Box>
      </Container>
    </AppShell>
  )
}
