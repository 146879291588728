import {
  ActionIcon,
  AppShell,
  Box,
  Container,
  CopyButton,
  Group,
  Image,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useDisclosure } from '@asuikit/hooks'
import { useRequest } from 'ahooks'
import { PiCopyLight } from 'react-icons/pi'
import { shortenAddress } from 'utilities/src/addresses'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import ASDataTable from '../../components/detrade/ASDataTable'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { getLaunchpadBannerList, getLaunchpadFinishedList } from '../../service/launch.api'
import { mantissaNumber } from '../../utils/detrade/number'
import LaunchBanner from './LaunchBanner'

export default function LaunchPage({ className }: { className?: string }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)
  const columns = useMemo<any[]>(
    () => [
      {
        title: <>Name</>,
        align: 'left',
        render(text: any, item: any, index: any) {
          const address = item.project_contract_address
          return (
            <Group spacing={7}>
              <Image width={40} height={40} radius={40} src={item.project_logo} />
              <Stack spacing={2}>
                <Text fw={500} fz={16} lh="20px" color="#0B132A">
                  {item.project_name}
                </Text>
                <Group spacing={4}>
                  <Text fw={400} fz={12} lh="16px" color="#959AA4">
                    {shortenAddress(address)}
                  </Text>

                  <CopyButton value={address || ''} timeout={1000}>
                    {({ copy, copied }) => (
                      <Tooltip label="Copied" withinPortal opened={copied}>
                        <ActionIcon
                          size={24}
                          onClick={(e) => {
                            e.stopPropagation()
                            copy()
                          }}
                        >
                          <PiCopyLight size={12} color="#141414" />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
              </Stack>
            </Group>
          )
          // return null
        },
      },
      {
        title: <>Total Allocation</>,
        align: 'center',
        render(text: any, item: any, rowIndex: any): any {
          return (
            <Text fw={500} fz={16} lh="20px" color="#45AF5C">
              {mantissaNumber(item.total_allocation, 8)} {item.currency}
            </Text>
          )
        },
      },
      {
        title: <>Total Deposit</>,
        align: 'center',
        render(text: any, item: any, rowIndex: any): any {
          let subscribed = 0
          if (item.total_deposit > item.total_allocation) {
            subscribed = item.total_deposit / item.total_allocation
          }
          return (
            <Group spacing={8}>
              <Text fw={500} fz={16} lh="20px" color="#0B132A">
                {mantissaNumber(item.total_deposit, 8)} {item.currency}
              </Text>
              {subscribed > 1 && (
                <Box
                  sx={({ colors }) => ({
                    background: '#FE9839',
                    padding: '2px 6px',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '18px',
                    borderRadius: 16,
                    color: '#FFFFFF',
                  })}
                >
                  {mantissaNumber(subscribed, 2)}x
                </Box>
              )}
            </Group>
          )
        },
      },

      {
        title: <>ROI (ATH)</>,
        align: 'center',
        render(text: any, item: any, rowIndex: any): any {
          if (!item.roi) return '--'
          return (
            <Text fw={500} fz={16} lh="20px" color="#FE9839">
              {item.roi}x
            </Text>
          )
        },
      },
    ],
    []
  )

  const { data: bannerList = [], loading: bannerListLoading } = useRequest(async () => {
    const res = await getLaunchpadBannerList()
    return res
  })

  const { data: launchpadFinishedList = [], loading } = useRequest(async () => {
    const res = await getLaunchpadFinishedList()
    return res
  })

  const navigate = useNavigate()
  return (
    <AppShell
      padding={0}
      // footer={<Footer fixed={false} />}
      styles={(theme) => ({
        main: {
          background: '#FFFFFF',
        },
      })}
    >
      <Container mt={24} px={smScreen ? 172 : 16} size={1440}>
        {bannerListLoading ? (
          <>
            <Skeleton h={320} w="100%" radius={16}></Skeleton>
          </>
        ) : (
          <Stack w="100%" spacing={20}>
            {bannerList.map((item, index) => {
              return <LaunchBanner item={item} key={index} />
            })}
          </Stack>
        )}

        <Text fw={700} fz={24} lh="32px" color="#0B132A" pt={40} pb={16}>
          Finished
        </Text>
        <ASDataTable
          hasXSpace
          columns={columns}
          loading={loading}
          data={launchpadFinishedList}
          verticalSpacing={0}
          pagination={false}
          headerSticky={true}
          onRowClick={(row) => {
            navigate(`/launch/${row.project_id}`)
          }}
          styles={({ colors }: any) => {
            return {
              header: {
                // borderRadius: 8,
                // background: colors.bg[2],
                borderBottom: '1px solid #BABDC433',
              },
              tr: {
                borderBottom: '1px solid #BABDC433',
                '&:nth-child(even)': {
                  background: '#F7F7F7',
                  // borderRadius: 8,
                },
              },
              th: {
                height: 40,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '20px',
                // padding: 6,
                // padding: '10px 12px 10px',
                padding: '10px 8px',
                color: '#959AA4',
                borderBottom: 0,
              },
              td: {
                height: 80,
                wordBreak: 'break-word',
                padding: '21px 8px',
                color: '#0B132A',
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '20px',
              },
            }
          }}
        />
      </Container>
    </AppShell>
  )
}
