import { Group, Text, createStyles } from '@asuikit/core'
import { MouseoverTooltip, TooltipSize } from 'components/Tooltip'
import useHoverProps from 'hooks/useHoverProps'
import { useIsMobile } from 'nft/hooks'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { LoadingRow } from '../Loader/styled'

export type LineItemData = {
  Label: React.FC
  Value: React.FC
  TooltipBody?: React.FC
  tooltipSize?: TooltipSize
  loaderWidth?: number
}

const LabelText = styled(ThemedText.BodySmall)<{ hasTooltip?: boolean }>`
  cursor: ${({ hasTooltip }) => (hasTooltip ? 'help' : 'auto')};
  color: ${({ theme }) => theme.neutral2};
`

const DetailRowValue = styled(ThemedText.BodySmall)`
  text-align: right;
  overflow-wrap: break-word;
`

type ValueWrapperProps = PropsWithChildren<{
  lineItem: LineItemData
  labelHovered: boolean
  syncing: boolean
}>

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    label: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#4F5665',
    },
    value: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#4F5665',
    },
  }
})
function ValueWrapper({ children, lineItem, labelHovered, syncing }: ValueWrapperProps) {
  const { TooltipBody, tooltipSize, loaderWidth } = lineItem
  const isMobile = useIsMobile()

  const { classes, cx } = useStyles()
  if (syncing) return <LoadingRow data-testid="loading-row" height={15} width={loaderWidth ?? 50} />

  if (!TooltipBody) return <DetailRowValue>{children}</DetailRowValue>

  return (
    <MouseoverTooltip
      placement={isMobile ? 'auto' : 'right'}
      forceShow={labelHovered} // displays tooltip when hovering either both label or value
      size={tooltipSize}
      text={
        <ThemedText.Caption color="neutral2">
          <TooltipBody />
        </ThemedText.Caption>
      }
    >
      <Text className={classes.value}>{children}</Text>
    </MouseoverTooltip>
  )
}

export function DetailLineItem({ LineItem, syncing }: { LineItem: LineItemData; syncing?: boolean }) {
  const { classes, cx } = useStyles()
  const [labelHovered, hoverProps] = useHoverProps()
  return (
    <Group position="apart">
      <Text className={classes.label} sx={{ cursor: LineItem.TooltipBody ? 'pointer' : 'inherit' }}>
        <LineItem.Label />
      </Text>
      <ValueWrapper lineItem={LineItem} labelHovered={labelHovered} syncing={syncing ?? false}>
        <LineItem.Value />
      </ValueWrapper>
    </Group>
  )
}
