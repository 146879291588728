import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import ERC20ABI from 'uniswap/src/abis/erc20.json'
import { getContract } from 'utilities/src/contracts/getContract'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import AlienXLaunchpadAbi from './AlienXLaunchpadAbi.json'

import { useRequest } from 'ahooks'
import { providers as ethersProviders } from 'ethers/lib/ethers'
import { formatUnits } from 'viem'
import { CHAIN_INFO } from '../../constants/chains'
import { postPrivateSaleSign } from '../../service/launch.api'
import isZero from '../../utils/isZero'
import { LaunchChainId } from './launchConst'

const functionNameMap = {
  0: 'privateSale',
  1: 'publicSale',
}
const paramsKeyMap = {
  0: ['amount', 'totalAmount', 'to', 'expireTimestamp', 'signature'],
  1: ['amount'],
}

const altPublicRPCUrl = CHAIN_INFO[LaunchChainId].rpcUrls?.['safe'][0]
const providerLaunch = new ethersProviders.JsonRpcProvider(altPublicRPCUrl)
function getFunctionParams(fields: any[], obj: any) {
  return fields.map((field) => obj[field])
}

type SaleType = 0 | 1
// 0 - 代表私募 1 - 代表公募
export function useLaunchDeposit(
  contractAddress: string = '',
  saleType: SaleType,
  paymentTokenContractAddress: string = '',
  paymentTokenDecimal: number,
  project_id: string = ''
) {
  const { account, provider } = useWeb3React()
  const functionName = functionNameMap[saleType]

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (amount: number | bigint) => {
      if (!(await checkTargetChain(LaunchChainId))) return

      if (!contractAddress || !paymentTokenContractAddress || !account || !provider) return
      if (!isZero(paymentTokenContractAddress)) {
        const paymentTokenContract = getContract(paymentTokenContractAddress, ERC20ABI, provider, account)

        const res = await paymentTokenContract.allowance(account, contractAddress)

        if (res < amount) {
          await paymentTokenContract.approve(contractAddress, amount)
        }
      }
      let callParams: any = {}
      if (saleType === 1) {
        callParams.amount = amount
      } else if (saleType === 0) {
        const params = {
          address: account,
          timestamp: Date.now(),
          project_id,
        }
        const signer = provider.getSigner(account)
        const msg = Object.keys(params)
          .map((key, index) => {
            // @ts-ignore
            return `${key}: ${params?.[key]}`
          })
          .join('\n')
        const signature = await signer.signMessage(msg)

        const res = await postPrivateSaleSign({
          ...params,
          buy_amount: formatUnits(BigInt(amount || 0), paymentTokenDecimal),
          signature,
        })
        callParams = res
      }
      const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, provider, account)
      // const estimatedGas = await stakeContract.estimateGas.deposit(positionManager, tokenId)
      const paramsKey = paramsKeyMap[saleType]
      const ary = getFunctionParams(paramsKey, callParams)

      if (isZero(paymentTokenContractAddress)) {
        ary.push({
          value: amount,
        })
      }
      const txRes = await launchContract?.[functionName].apply(null, ary)
      return txRes
    },
    [
      account,
      checkTargetChain,
      contractAddress,
      functionName,
      paymentTokenContractAddress,
      paymentTokenDecimal,
      project_id,
      provider,
      saleType,
    ]
  )
}

export function useGetLaunchDepositAmount(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'getDepositedBalance'

  const res = useRequest(
    async () => {
      if (!contractAddress || !account) return 0
      const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, providerLaunch, account)
      const res = await launchContract?.[functionName].apply(null, [account, saleType])
      return res
    },
    {
      pollingInterval: 10000,
      ready: !!contractAddress,
      refreshDeps: [account],
    }
  )
  return res
}
export function useGetLaunchRefundData(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'getExpectedRefund'
  const functionName2 = 'getClaimedRefund'

  const res = useRequest(
    async () => {
      if (!contractAddress || !account) return {}
      const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, providerLaunch, account)
      const params = [account, saleType]
      const [expectedRefund, claimedRefund] = await Promise.all([
        launchContract?.[functionName].apply(null, params),
        launchContract?.[functionName2].apply(null, params),
      ])
      return {
        expectedRefund,
        claimedRefund,
      }
    },
    {
      pollingInterval: 10000,
      ready: !!contractAddress,
      refreshDeps: [account],
    }
  )
  return res
}

export function useGetLaunchClaimTokenData(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'getExpectedToken'
  const functionName2 = 'getClaimedToken'

  const res = useRequest(
    async () => {
      if (!contractAddress || !account) return {}
      const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, providerLaunch, account)
      const params = [account, saleType]
      const [expectedToken, claimedToken] = await Promise.all([
        launchContract?.[functionName].apply(null, params),
        launchContract?.[functionName2].apply(null, params),
      ])
      return {
        expectedToken,
        claimedToken,
      }
    },
    {
      pollingInterval: 10000,
      ready: !!contractAddress,
      refreshDeps: [account],
    }
  )
  return res
}

// 0 - 代表私募 1 - 代表公募
export function useLaunchClaimRefund(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'claimRefund'

  const checkTargetChain = useCheckTargetChain()
  return useCallback(async () => {
    if (!(await checkTargetChain(LaunchChainId))) return

    if (!contractAddress || !account || !provider) return

    const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, provider, account)

    const txRes = await launchContract?.[functionName].apply(null, [saleType])
    return txRes
  }, [account, checkTargetChain, contractAddress, provider, saleType])
}

// 0 - 代表私募 1 - 代表公募
export function useLaunchClaimToken(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'claimToken'

  const checkTargetChain = useCheckTargetChain()
  return useCallback(async () => {
    if (!(await checkTargetChain(LaunchChainId))) return

    if (!contractAddress || !account || !provider) return

    const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, provider, account)

    const txRes = await launchContract?.[functionName].apply(null, [saleType])
    return txRes
  }, [account, checkTargetChain, contractAddress, provider, saleType])
}

export function useLaunchTotalDeposit(contractAddress: string = '', saleType: SaleType) {
  const { account, provider } = useWeb3React()
  const functionName = 'getTotalDeposit'

  const res = useRequest(
    async () => {
      if (!contractAddress) return 0
      const launchContract = getContract(contractAddress, AlienXLaunchpadAbi, providerLaunch, account)
      const res = await launchContract?.[functionName].apply(null, [saleType])
      return res
    },
    {
      pollingInterval: 10000,
      ready: !!contractAddress,
    }
  )
  return res
}

export function useLaunchTokenDecimals(tokenAddress: string = '') {
  const { account, provider } = useWeb3React()
  const functionName = 'decimals'

  const res = useRequest(
    async () => {
      if (!tokenAddress) return 0
      const launchContract = getContract(tokenAddress, ERC20ABI, providerLaunch, account)
      const res = await launchContract?.[functionName].apply(null, [])
      return res
    },
    {
      pollingInterval: 10000,
      ready: !!tokenAddress,
    }
  )
  return res
}
