import { Box, createStyles, Group, Stack } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiEdit2Fill, RiTokenSwapFill } from 'react-icons/ri'
import { useTheme } from 'styled-components'
import { TransactionStatus } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { SupportArticleURL } from '../../../../../../constants/supportArticles'
import { useBlockConfirmationTime } from '../../../../../../hooks/useBlockConfirmationTime'
import { useColor } from '../../../../../../hooks/useColor'
import { SwapResult } from '../../../../../../hooks/useSwapCallback'
import useNativeCurrency from '../../../../../../lib/hooks/useNativeCurrency'
import { InterfaceTrade, OffchainOrderType, TradeFillType } from '../../../../../../state/routing/types'
import { isLimitTrade, isUniswapXTrade } from '../../../../../../state/routing/utils'
import { useOrder } from '../../../../../../state/signatures/hooks'
import { useIsTransactionConfirmed, useSwapTransactionStatus } from '../../../../../../state/transactions/hooks'
import { colors } from '../../../../../../theme/colors'
import { UniswapXOrderStatus } from '../../../../../../types/uniswapx'
import { SignatureExpiredError } from '../../../../../../utils/errors'
import { ConfirmModalState } from '../../../../../ConfirmSwapModal'
import { StepDetails, StepStatus } from '../../../../../ConfirmSwapModal/Step'
import CurrencyLogo from '../../../../../Logo/CurrencyLogo'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
    stepContainer: {
      position: 'relative',
      marginTop: 24,
    },
    step: {
      minHeight: 69,
      '.line': {
        flex: 1,
        width: 4,
        borderRadius: 4,
        background: '#BABDC4',
      },
      '&:last-child': {
        '.line': {
          display: 'none',
        },
      },
      '.icon': {
        width: 44,
        height: 44,
        borderRadius: '100%',
        border: '1px solid #BABDC4',
      },
      '.img': {
        filter: 'grayscale(100%)',
      },
      '.icon-bg': {
        borderRadius: '100%',
        width: 32,
        height: 32,
        background: '#BABDC4',
      },
      '.text': {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '20px',
        color: '#959AA4',
        paddingTop: 12,
      },

      '.warn-text': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
        color: '#FE9839',
      },
      '.text-btn': {
        cursor: 'pointer',
        color: '#3C38F5',
      },

      '&.selected': {
        '.text': {
          color: '#0B132A',
        },

        '.img': {
          filter: 'grayscale(0%)',
        },
        '.icon-bg': {
          background: '#3C38F5',
        },
      },
    },
  }
})

type ProgressIndicatorStep = Extract<
  ConfirmModalState,
  | ConfirmModalState.APPROVING_TOKEN
  | ConfirmModalState.PERMITTING
  | ConfirmModalState.PENDING_CONFIRMATION
  | ConfirmModalState.WRAPPING
  | ConfirmModalState.RESETTING_TOKEN_ALLOWANCE
>

function WalletPending({
  steps,
  currentStep,
  trade,
  swapResult,
  wrapTxHash,
  tokenApprovalPending = false,
  revocationPending = false,
  swapError,
  onRetryUniswapXSignature,
}: {
  steps: ProgressIndicatorStep[]
  currentStep: ProgressIndicatorStep
  trade?: InterfaceTrade
  swapResult?: SwapResult
  wrapTxHash?: string
  tokenApprovalPending?: boolean
  revocationPending?: boolean
  swapError?: Error | string
  onRetryUniswapXSignature?: () => void
}) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const { chainId } = useWeb3React()
  const nativeCurrency = useNativeCurrency(chainId)
  const inputTokenColor = useColor(trade?.inputAmount.currency.wrapped)
  const theme = useTheme()

  // Dynamic estimation of transaction wait time based on confirmation of previous block
  const { blockConfirmationTime } = useBlockConfirmationTime()
  const [estimatedTransactionTime, setEstimatedTransactionTime] = useState<number>()
  useEffect(() => {
    // Value continuously updates as new blocks get confirmed
    // Only set step timers once to prevent resetting
    if (blockConfirmationTime && !estimatedTransactionTime) {
      // Add buffer to account for variable confirmation
      setEstimatedTransactionTime(Math.ceil(blockConfirmationTime * 1.2))
    }
  }, [blockConfirmationTime, estimatedTransactionTime])

  const swapStatus = useSwapTransactionStatus(swapResult)
  const uniswapXOrder = useOrder(swapResult?.type === TradeFillType.UniswapX ? swapResult.response.orderHash : '')

  const swapConfirmed =
    swapStatus === TransactionStatus.Confirmed || uniswapXOrder?.status === UniswapXOrderStatus.FILLED
  const wrapConfirmed = useIsTransactionConfirmed(wrapTxHash)

  const swapPending = swapResult !== undefined && !swapConfirmed
  const wrapPending = wrapTxHash != undefined && !wrapConfirmed
  const transactionPending = revocationPending || tokenApprovalPending || wrapPending || swapPending

  // Retry logic for UniswapX orders when a signature expires
  const [signatureExpiredErrorId, setSignatureExpiredErrorId] = useState('')
  useEffect(() => {
    if (swapError instanceof SignatureExpiredError && swapError.id !== signatureExpiredErrorId) {
      setSignatureExpiredErrorId(swapError.id)
      onRetryUniswapXSignature?.()
    }
  }, [onRetryUniswapXSignature, signatureExpiredErrorId, swapError])

  function getStatus(targetStep: ProgressIndicatorStep) {
    const currentIndex = steps.indexOf(currentStep)
    const targetIndex = steps.indexOf(targetStep)
    if (currentIndex < targetIndex) {
      return StepStatus.PREVIEW
    } else if (currentIndex === targetIndex) {
      return transactionPending ? StepStatus.IN_PROGRESS : StepStatus.ACTIVE
    } else {
      return StepStatus.COMPLETE
    }
  }

  const stepDetailsMap: Record<ProgressIndicatorStep, StepDetails> = useMemo(
    () => ({
      [ConfirmModalState.WRAPPING]: {
        icon: <CurrencyLogo size={32} currency={trade?.inputAmount.currency} />,
        rippleColor: inputTokenColor,
        previewTitle: t(`Wrap {{symbol}}`, { symbol: nativeCurrency.symbol }),
        actionRequiredTitle: t(`Wrap  {{symbol}} in wallet`, { symbol: nativeCurrency.symbol }),
        inProgressTitle: t(`Wrapping  {{symbol}}...`, { symbol: nativeCurrency.symbol }),
        learnMoreLinkText: t(`Why do I have to wrap my {{symbol}}?`, { symbol: nativeCurrency.symbol }),
        learnMoreLinkHref: SupportArticleURL.WETH_EXPLAINER,
      },
      [ConfirmModalState.RESETTING_TOKEN_ALLOWANCE]: {
        icon: <CurrencyLogo size={32} currency={trade?.inputAmount.currency} />,
        rippleColor: inputTokenColor,
        previewTitle: t(`Reset {{symbol}} limit`, { symbol: trade?.inputAmount.currency.symbol }),
        actionRequiredTitle: t(`Reset {{symbol}} limit in wallet`, { symbol: trade?.inputAmount.currency.symbol }),
        inProgressTitle: t(`Resetting {{symbol}} limit...`, { symbol: trade?.inputAmount.currency.symbol }),
      },
      [ConfirmModalState.APPROVING_TOKEN]: {
        icon: <CurrencyLogo size={32} currency={trade?.inputAmount.currency} />,
        rippleColor: inputTokenColor,
        previewTitle: t(`Approve {{symbol}} spending`, { symbol: trade?.inputAmount.currency.symbol }),
        actionRequiredTitle: t`Approve in wallet`,
        inProgressTitle: t`Approval pending...`,
        learnMoreLinkText: t`Why do I have to approve a token?`,
        learnMoreLinkHref: SupportArticleURL.APPROVALS_EXPLAINER,
      },
      [ConfirmModalState.PERMITTING]: {
        icon: (
          <Group className="icon-bg" position="center">
            <RiEdit2Fill size={20} color="#FFFFFF" />
          </Group>
        ),
        rippleColor: theme.accent1,
        previewTitle: t`Sign message`,
        actionRequiredTitle: t`Sign message in wallet`,
        learnMoreLinkText: t`Why are signatures required?`,
        learnMoreLinkHref: SupportArticleURL.APPROVALS_EXPLAINER,
      },
      [ConfirmModalState.PENDING_CONFIRMATION]: {
        icon: (
          <Group className="icon-bg" position="center">
            <RiTokenSwapFill size={20} color="#FFFFFF" />
          </Group>
        ),
        rippleColor: colors.blue400,
        previewTitle: isLimitTrade(trade) ? t`Confirm` : t`Confirm swap`,
        actionRequiredTitle: isLimitTrade(trade) ? t`Confirm in wallet` : t`Confirm swap in wallet`,
        inProgressTitle: isLimitTrade(trade) ? t`Pending...` : t`Swap pending...`,
        ...(isUniswapXTrade(trade) && trade.offchainOrderType === OffchainOrderType.DUTCH_AUCTION
          ? {
              timeToStart: trade.asDutchOrderTrade().order.info.deadline - Math.floor(Date.now() / 1000),
              delayedStartTitle: t`Confirmation timed out. Please retry.`,
            }
          : {}),
        learnMoreLinkText: isLimitTrade(trade) ? t`Learn more about limits` : t`Learn more about swaps`,
        learnMoreLinkHref: isLimitTrade(trade)
          ? SupportArticleURL.LEARN_ABOUT_LIMITS
          : SupportArticleURL.HOW_TO_SWAP_TOKENS,
      },
    }),
    [trade, inputTokenColor, t, nativeCurrency.symbol, theme.accent1]
  )

  if (steps.length === 0) {
    return null
  }
  return (
    <div className={classes.root}>
      <Stack spacing={8} className={classes.stepContainer}>
        {steps.map((step, i) => {
          const stepStatus = getStatus(step)
          const stepDetails = stepDetailsMap[step]
          let title: any = null
          switch (stepStatus) {
            case StepStatus.PREVIEW:
              title = stepDetails.previewTitle
              break
            case StepStatus.ACTIVE:
              title = stepDetails.actionRequiredTitle
              break
            case StepStatus.IN_PROGRESS:
              title = stepDetails.inProgressTitle
              break
            case StepStatus.COMPLETE:
              title = stepDetails.previewTitle
              break
            default:
              return null
          }
          return (
            <Group
              key={i}
              className={cx(classes.step, {
                selected: stepStatus !== StepStatus.PREVIEW,
              })}
              spacing={12}
              align="top"
            >
              <Stack spacing={8} align="center">
                <Group className="icon" position="center">
                  {stepDetails.icon}
                </Group>
                <Box className="line" />
              </Stack>
              <Box className={cx('text')}>
                {title}
                {/*<Group className="warn-text" spacing={12}>*/}
                {/*  <Text>Wallet rejects request</Text>*/}
                {/*  <Text className="text-btn">Retry</Text>*/}
                {/*</Group>*/}
              </Box>
            </Group>
          )
        })}
      </Stack>
    </div>
  )
}

export default WalletPending
