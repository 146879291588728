import { Percent } from '@uniswap/sdk-core'
import { useTrace } from 'analytics'
import AnimatedDropdown from 'components/AnimatedDropdown'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import { Trans } from 'i18n'
import { useState } from 'react'
import { ChevronDown } from 'react-feather'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { useFormatter } from 'utils/formatNumbers'

import { ActionIcon, createStyles, Group, Stack, Text } from '@asuikit/core'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'
import SwapLineItem, { SwapLineItemType } from './SwapLineItem'
import TradePrice from './TradePrice'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      padding: 16,
      background: '#F7F7F7',
      borderRadius: 12,
    },
    label: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#4F5665',
    },
    value: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#4F5665',
    },
  }
})
const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const SwapDetailsWrapper = styled(Column)`
  padding-top: ${({ theme }) => theme.grids.md};
`

const Wrapper = styled(Column)`
  border-radius: 16px;
  padding: 12px 16px;
`

interface SwapDetailsProps {
  trade?: InterfaceTrade
  syncing?: boolean
  loading?: boolean
  hiddenMoreBtn?: boolean
  priceIsChange?: boolean
  allowedSlippage: Percent
  priceImpact?: Percent
}

export default function SwapDetailsDropdown(props: SwapDetailsProps) {
  const { trade, syncing, loading, allowedSlippage, hiddenMoreBtn = false, priceIsChange } = props
  const theme = useTheme()
  const [showDetails, setShowDetails] = useState(hiddenMoreBtn)
  const trace = useTrace()

  const { classes, cx } = useStyles()
  return (
    <Stack spacing={0} className={classes.root}>
      <Group position="apart">
        <Text className={classes.label}>Exchange rate</Text>
        <Text className={classes.value}>
          <Group spacing={12}>
            {trade ? (
              <TradePrice price={trade.executionPrice} priceIsChange={priceIsChange} />
            ) : loading || syncing ? (
              <ThemedText.DeprecatedMain fontSize={14}>
                <Trans>Fetching best price...</Trans>
              </ThemedText.DeprecatedMain>
            ) : null}

            {!hiddenMoreBtn && (
              <ActionIcon size={16} onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? <RiArrowUpSFill size={16} /> : <RiArrowDownSFill size={16} />}
              </ActionIcon>
            )}
          </Group>
        </Text>
      </Group>
      <AdvancedSwapDetails {...props} open={showDetails} />
    </Stack>
  )
}

function AdvancedSwapDetails(props: SwapDetailsProps & { open: boolean }) {
  const { open, trade, allowedSlippage, syncing = false, priceImpact } = props
  const format = useFormatter()

  if (!trade) return null

  const lineItemProps = { trade, allowedSlippage, format, syncing, priceImpact }

  return (
    <AnimatedDropdown open={open}>
      <Stack spacing={12} pt={12}>
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.PRICE_IMPACT} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.MAX_SLIPPAGE} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.OUTPUT_TOKEN_FEE_ON_TRANSFER} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.SWAP_FEE} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.NETWORK_COST} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.ROUTING_INFO} />
      </Stack>
    </AnimatedDropdown>
  )
}
