import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useIsSupportedChainId } from 'constants/chains'
import { Trans } from 'i18n'
import ms from 'ms'
import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { flexColumnNoWrap } from 'theme/styles'
import { NumberType, useFormatter } from 'utils/formatNumbers'

import { Box, createStyles, Divider, Flex, Group, Stack, Text, TextInput, Tooltip } from '@asuikit/core'
import { DoubleCurrencyLogo } from 'components/DoubleLogo'
import { CurrencySearchFilters } from 'components/SearchModal/CurrencySearch'
import { PrefetchBalancesWrapper } from 'graphql/data/apollo/TokenBalancesProvider'
import { RiArrowDownSFill } from 'react-icons/ri'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ButtonGray } from '../Button'
import SelectToken from '../detrade/TokenSelector/SelectToken'
import { FiatValue } from './FiatValue'
import { formatCurrencySymbol } from './utils'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      width: '100%',
      borderRadius: 12,
      background: colors.bg[2],
      padding: '20px 24px 23px',
    },
  }
})

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

interface CurrencySelectProps {
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
  animateShake?: boolean
}

export const CurrencySelect = styled(ButtonGray)<CurrencySelectProps>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.surface1 : theme.accent1)};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  color: ${({ selected, theme }) => (selected ? theme.neutral1 : theme.neutralContrast)};
  cursor: pointer;
  height: 36px;
  border-radius: 18px;
  outline: none;
  user-select: none;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.surface3 : theme.accent1)};
  font-size: 24px;
  font-weight: 485;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: ${({ selected }) => (selected ? '4px 8px 4px 4px' : '6px 6px 6px 8px')};
  gap: 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  box-shadow: ${({ theme }) => theme.deprecated_shallowShadow};

  &:hover,
  &:active {
    background-color: ${({ theme, selected }) => (selected ? theme.surface2 : theme.accent1)};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
      animation-timing-function: ease-in-out;
    }
    20% {
      transform: translateX(10px);
      animation-timing-function: ease-in-out;
    }
    40% {
      transform: translateX(-10px);
      animation-timing-function: ease-in-out;
    }
    60% {
      transform: translateX(10px);
      animation-timing-function: ease-in-out;
    }
    80% {
      transform: translateX(-10px);
      animation-timing-function: ease-in-out;
    }
    100% {
      transform: translateX(0);
      animation-timing-function: ease-in-out;
    }
  }
  animation: ${({ animateShake }) => (animateShake ? 'horizontal-shaking 300ms' : 'none')};
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  id: string
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  disabled?: boolean
  currencySearchFilters?: CurrencySearchFilters
  numericalInputSettings?: {
    disabled?: boolean
    onDisabledClick?: () => void
    disabledTooltipBody?: ReactNode
  }
}

const SwapCurrencyInputPanel = forwardRef<HTMLInputElement, SwapCurrencyInputPanelProps>(
  (
    {
      value,
      onUserInput,
      onMax,
      showMaxButton,
      onCurrencySelect,
      currency,
      otherCurrency,
      id,
      renderBalance,
      fiatValue,
      priceImpact,
      hideBalance = false,
      pair = null, // used for double token logo
      hideInput = false,
      locked = false,
      loading = false,
      disabled = false,
      currencySearchFilters,
      numericalInputSettings,
      label,
      ...rest
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { account, chainId } = useWeb3React()
    const chainAllowed = useIsSupportedChainId(chainId)
    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
    const theme = useTheme()
    const { formatCurrencyAmount } = useFormatter()

    const handleDismissSearch = useCallback(() => {
      setModalOpen(false)
    }, [setModalOpen])

    const [tooltipVisible, setTooltipVisible] = useState(false)
    const handleDisabledNumericalInputClick = useCallback(() => {
      if (numericalInputSettings?.disabled && !tooltipVisible) {
        setTooltipVisible(true)
        setTimeout(() => setTooltipVisible(false), ms('4s')) // reset shake animation state after 4s
        numericalInputSettings.onDisabledClick?.()
      }
    }, [tooltipVisible, numericalInputSettings])

    // reset tooltip state when currency changes
    useEffect(() => setTooltipVisible(false), [currency])

    const { classes, cx } = useStyles()
    return (
      <>
        <div className={classes.root}>
          {locked && (
            <Text mb={20}>
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </Text>
          )}
          <Text fw={400} fz={16} lh="20px" color="#959AA4">
            {label}
          </Text>
          <Flex gap={20} mt={16}>
            <Box
              sx={({ colors }) => ({
                flex: 1,
              })}
            >
              <Stack spacing={6}>
                {!hideInput && (
                  <TextInput
                    value={value}
                    onChange={(e) => {
                      const decimalPlaces = currency?.decimals || 2
                      const newValue = e.target.value
                      // 构建正则表达式来匹配允许的格式: 只能输入数字和小数点
                      const regex = new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`)
                      // 检查输入值是否符合正则表达式，且仅包含数字和小数点
                      if (regex.test(newValue) || newValue === '') {
                        onUserInput(newValue)
                      }
                    }}
                    disabled={!chainAllowed || disabled || numericalInputSettings?.disabled}
                    placeholder="0"
                    styles={({ colors }) => ({
                      wrapper: {
                        height: 52,
                      },
                      input: {
                        fontSize: 48,
                        fontWeight: 600,
                        padding: 0,
                        border: 0,
                        height: 52,
                        background: 'none',
                        '&::placeholder': { colors: '#BABDC4' },
                        '&[data-disabled], &:disabled': { backgroundColor: 'transparent' },
                      },
                    })}
                  />
                )}

                <Text fw={400} fz={16} lh="20px" color="#959AA4">
                  {Boolean(!hideInput && !hideBalance) && (
                    <>
                      <LoadingOpacityContainer $loading={loading}>
                        {fiatValue && (
                          <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} testId={`fiat-value-${id}`} />
                        )}
                      </LoadingOpacityContainer>
                    </>
                  )}
                </Text>
              </Stack>
            </Box>

            <Box>
              <Stack spacing={8}>
                <PrefetchBalancesWrapper>
                  <Tooltip
                    opened={tooltipVisible && !modalOpen}
                    position="bottom"
                    label={numericalInputSettings?.disabledTooltipBody}
                  >
                    <Group
                      onClick={() => {
                        if (onCurrencySelect) {
                          setModalOpen(true)
                        }
                      }}
                      position="apart"
                      sx={({ colors }) => ({
                        padding: '8px 10px',
                        borderRadius: 8,
                        background: '#ffff',
                        cursor: 'pointer',
                        minWidth: 174,
                      })}
                    >
                      <Text fw={500} fz={20} lh="20px" color="#0B132A">
                        <Group spacing={6}>
                          {pair ? (
                            <span style={{ marginRight: '0.5rem' }}>
                              <DoubleCurrencyLogo currencies={[pair.token0, pair.token1]} size={24} />
                            </span>
                          ) : currency ? (
                            <CurrencyLogo style={{ marginRight: '2px' }} currency={currency} size={24} />
                          ) : null}
                          {pair ? (
                            <Text>
                              {pair?.token0.symbol}:{pair?.token1.symbol}
                            </Text>
                          ) : (
                            <Text>{currency ? formatCurrencySymbol(currency) : <Trans>Select token</Trans>}</Text>
                          )}
                        </Group>
                      </Text>
                      <RiArrowDownSFill size={14} color="#4F5665" />
                    </Group>
                  </Tooltip>
                </PrefetchBalancesWrapper>

                <Group spacing={10}>
                  {account ? (
                    <Box>
                      {!hideBalance && currency && selectedCurrencyBalance ? (
                        renderBalance ? (
                          renderBalance(selectedCurrencyBalance)
                        ) : (
                          <Text fw={400} fz={14} lh="20px" color="#959AA4">
                            <Group spacing={6}>
                              Balance:
                              <Text color="#4F5665" span>
                                {formatCurrencyAmount({
                                  amount: selectedCurrencyBalance,
                                  type: NumberType.TokenNonTx,
                                })}
                              </Text>
                            </Group>
                          </Text>
                        )
                      ) : null}
                    </Box>
                  ) : (
                    <span />
                  )}
                  {showMaxButton && selectedCurrencyBalance ? (
                    <>
                      <Divider orientation="vertical" h={10} sx={{ alignSelf: 'center' }} />
                      <Text fw={400} fz={14} lh="20px" color="#3C38F5" onClick={onMax} sx={{ cursor: 'pointer' }}>
                        MAX
                      </Text>
                    </>
                  ) : null}
                </Group>
              </Stack>
            </Box>
          </Flex>
        </div>

        {onCurrencySelect && (
          <SelectToken
            opened={modalOpen}
            onClose={handleDismissSearch}
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={currency}
            otherSelectedCurrency={otherCurrency}
            currencySearchFilters={currencySearchFilters}
          />
        )}
      </>
    )
  }
)
SwapCurrencyInputPanel.displayName = 'SwapCurrencyInputPanel'

export default SwapCurrencyInputPanel
