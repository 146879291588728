import { Group, Stack, Text, Tooltip } from '@asuikit/core'
import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { TextProps } from 'rebass'
import { useUSDPrice } from '../../../../../../hooks/useUSDPrice'
import { Trans } from '../../../../../../i18n'
import { InterfaceTrade } from '../../../../../../state/routing/types'
import { isPreviewTrade } from '../../../../../../state/routing/utils'
import { useFormatter } from '../../../../../../utils/formatNumbers'
import CurrencyLogo from '../../../../../Logo/CurrencyLogo'
import { Field } from '../../../../../swap/constants'

interface AmountProps {
  isLoading: boolean
  field: Field
  tooltipText?: ReactNode
  label: ReactNode
  amount: CurrencyAmount<Currency>
  usdAmount?: number
  headerTextProps?: TextProps
  // The currency used here can be different than the currency denoted in the `amount` prop
  // For UniswapX ETH input trades, the trade object will have WETH as the amount.currency, but
  // the user's real input currency is ETH, so show ETH instead
  currency: Currency
}
function SwapAmount({
  tooltipText,
  label,
  amount,
  usdAmount,
  field,
  currency,
  isLoading,
  headerTextProps,
}: AmountProps) {
  const { formatNumber, formatReviewSwapCurrencyAmount } = useFormatter()

  return (
    <>
      <Stack spacing={4}>
        {label && (
          <Tooltip label={tooltipText}>
            <Text fw={400} fz={14} lh="20px" color="#959AA4">
              {label}
            </Text>
          </Tooltip>
        )}

        <Group spacing={12} noWrap>
          <CurrencyLogo currency={currency} size={32} />
          <Text fw={500} fz={32} lh="36px" color="#0B132A">
            {formatReviewSwapCurrencyAmount(amount)} {currency?.symbol}
            {/*({' '}*/}
            {/*{formatNumber({*/}
            {/*  input: usdAmount,*/}
            {/*  type: NumberType.FiatTokenQuantity,*/}
            {/*})}*/}
            {/*)*/}
          </Text>
        </Group>
      </Stack>
    </>
  )
}

function ReviewPrice({
  trade,
  inputCurrency,
  allowedSlippage,
}: {
  trade: InterfaceTrade
  inputCurrency?: Currency
  allowedSlippage: Percent
}) {
  const { t, i18n } = useTranslation()

  const fiatValueInput = useUSDPrice(trade.inputAmount)
  const fiatValueOutput = useUSDPrice(trade.outputAmount)

  return (
    <Stack
      spacing={20}
      bg="bg.2"
      px={24}
      py={16}
      sx={({ colors }) => ({
        borderRadius: 12,
      })}
    >
      <SwapAmount
        field={Field.INPUT}
        label={<Trans>You pay</Trans>}
        amount={trade.inputAmount}
        currency={inputCurrency ?? trade.inputAmount.currency}
        usdAmount={fiatValueInput.data}
        isLoading={isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_OUTPUT}
      />
      <SwapAmount
        field={Field.OUTPUT}
        label={<Trans>You receive</Trans>}
        amount={trade.outputAmount}
        currency={trade.outputAmount.currency}
        usdAmount={fiatValueOutput.data}
        isLoading={isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_INPUT}
        tooltipText={
          trade.tradeType === TradeType.EXACT_INPUT ? (
            <Text>
              <Trans>
                Output is estimated. You will receive at least{' '}
                {{
                  amount: (
                    <b>
                      {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
                    </b>
                  ),
                }}{' '}
                or the transaction will revert.
              </Trans>
            </Text>
          ) : (
            <Text>
              <Trans>
                Input is estimated. You will sell at most{' '}
                {{
                  amount: (
                    <b>
                      {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
                    </b>
                  ),
                }}{' '}
                or the transaction will revert.
              </Trans>
            </Text>
          )
        }
      />
    </Stack>
  )
}

export default ReviewPrice
