import {
  Center,
  createStyles,
  Divider,
  Group,
  Image,
  Input,
  Modal,
  Skeleton,
  Stack,
  Text,
  UnstyledButton,
} from '@asuikit/core'
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy'
import { ChainId, Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useDebounce, useRequest } from 'ahooks'
import { STATIC_BASEURL } from 'constants/detrade'
import { uniqueId } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiSearchLine } from 'react-icons/ri'
import { CurrencyInfo } from '../../../../../../packages/uniswap/src/features/dataApi/types'
import { getApiChainParam, isSupportedChainId } from '../../../constants/chains'
import { buildCurrencyInfoWithLogo, COMMON_BASES } from '../../../constants/routing'
import { isAlienXNetwork } from '../../../constants/tokens'
import useSelectChain from '../../../hooks/useSelectChain'
import { useTokenBalances } from '../../../hooks/useTokenBalances'
import tryParseCurrencyAmount from '../../../lib/utils/tryParseCurrencyAmount'
import { getPopularTokens, getTokenSearch } from '../../../service/pools.api'
import { useUserAddedTokens } from '../../../state/user/userAddedTokens'
import { NumberType, useFormatter } from '../../../utils/formatNumbers'
import Loader from '../../Icons/LoadingSpinner'
import CurrencyLogo from '../../Logo/CurrencyLogo'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
  }
})

function SelectToken({
  opened,
  onClose,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCurrencyAmount = true,
  currencySearchFilters,
}: any) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()

  const { chainId } = useWeb3React()
  const bases = useMemo(() => {
    return chainId !== undefined
      ? isSupportedChainId(chainId)
        ? COMMON_BASES[chainId] ?? []
        : COMMON_BASES[ChainId.ALIENXCHAIN]
      : []
  }, [chainId])

  const selectChain = useSelectChain()
  const handleCurrencySelect = useCallback(
    async (currency: Currency, hasWarning?: boolean) => {
      if (currency.chainId !== chainId) {
        const result = await selectChain(currency.chainId)
        if (!result) {
          // failed to switch chains, don't select the currency
          return
        }
      }
      onCurrencySelect(currency, hasWarning)
      if (!hasWarning) onClose()
    },
    [chainId, onCurrencySelect, onClose, selectChain]
  )
  const { account } = useWeb3React()
  const userAddedTokens = useUserAddedTokens()
  const { balanceMap } = useTokenBalances()
  const { formatNumberOrString } = useFormatter()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const debouncedSearchQuery = useDebounce(searchQuery, { wait: 500 })
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setSearchQuery(input)
  }, [])

  const { data: alienXPopTokens = [], loading: alienXPopTokensLoading } = useRequest(
    async () => {
      if (!isAlienXNetwork(chainId)) return []
      const res = await getPopularTokens({
        chain: getApiChainParam(chainId),
      })
      const list: CurrencyInfo[] = res.map((item) => {
        return buildCurrencyInfoWithLogo(
          new Token(chainId, item.token_address, item.token_decimals, item.token_symbol, item.token_name),
          item.token_icon
        )
      })
      return list
    },
    {
      refreshDeps: [chainId],
    }
  )

  const defaultList = useMemo(() => {
    let list = bases
    console.log(alienXPopTokens)
    if (alienXPopTokens.length) {
      list = uniqueBy([...bases, ...alienXPopTokens], function (obj) {
        // 如果 currency.isNative 是 true，则返回一个唯一的标识
        if (obj.currency.isNative) {
          return uniqueId('native_')
        }
        return obj.currency.address
      })
    }
    return list
  }, [alienXPopTokens, bases])

  const { data: searchResult = [], loading: searchResultLoading } = useRequest(
    async () => {
      if (!debouncedSearchQuery || !chainId) return []
      const res = await getTokenSearch({
        chain: getApiChainParam(chainId),
        q: debouncedSearchQuery,
      })
      const list: CurrencyInfo[] = res.map((item) => {
        return buildCurrencyInfoWithLogo(
          new Token(chainId, item.token_address, item.token_decimals, item.token_symbol, item.token_name),
          item.token_icon
        )
      })
      return list
    },
    {
      refreshDeps: [debouncedSearchQuery, chainId],
    }
  )
  const list = useMemo(() => {
    if (debouncedSearchQuery) {
      return searchResult
    }
    return defaultList
  }, [debouncedSearchQuery, defaultList, searchResult])
  return (
    <div className={classes.root}>
      <Modal
        size={400}
        title="Select a token"
        opened={opened}
        onClose={onClose}
        centered
        styles={({ colors, fn }) => ({
          content: {
            background: colors.bg[1],
            height: 536,
          },
          header: {
            background: colors.bg[1],
            height: 64,
            padding: '20px 24px',
            // borderBottom: '1px solid' + colors.line[2],
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0B132A',
          },
          body: {
            padding: '16px 24px',
          },
        })}
      >
        <Stack spacing={12}>
          <Input
            icon={<RiSearchLine size={14} color="#959AA4" />}
            placeholder="Search"
            value={searchQuery}
            onInput={handleInput}
            sx={({ colors }) => ({
              input: {
                height: 40,
                borderRadius: 6,
                backgroundColor: colors.bg[3],
                border: 0,
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '20px',
              },
            })}
          />

          <Divider color="line.1" />

          <Stack spacing={20}>
            {userAddedTokens.length > 0 && (
              <Stack spacing={8}>
                <Text fw={400} fz={14} lh="20px" color="#4F5665">
                  Your tokens
                </Text>
                {userAddedTokens.map((currency, i) => {
                  const isSelected = Boolean(currency && selectedCurrency && selectedCurrency.equals(currency))

                  const balance =
                    tryParseCurrencyAmount(
                      String(balanceMap[currency.isNative ? 'ETH' : currency.address?.toLowerCase()]?.balance ?? 0),
                      currency
                    ) ?? CurrencyAmount.fromRawAmount(currency, 0)
                  return (
                    <UnstyledButton
                      key={i}
                      onClick={() => !isSelected && handleCurrencySelect(currency)}
                      disabled={isSelected}
                    >
                      <Group spacing={12} noWrap key={i}>
                        <Image width={32} height={32} radius={32} src={`${STATIC_BASEURL}`} />
                        <Stack spacing={0} sx={{ flexGrow: 1 }}>
                          <Text fw={500} fz={14} lh="20px" color="#0B132A">
                            {currency.name}
                          </Text>
                          <Text fw={400} fz={12} lh="16px" color="#959AA4">
                            {currency.symbol}
                          </Text>
                        </Stack>

                        <Stack spacing={0} sx={{ flexGrow: 1 }} align="flex-end">
                          {account ? (
                            balance ? (
                              <Text fw={500} fz={14} lh="20px" color="#0B132A">
                                {formatNumberOrString({
                                  input: balance.toExact(),
                                  type: NumberType.TokenNonTx,
                                })}
                              </Text>
                            ) : (
                              <Loader />
                            )
                          ) : null}
                          {/*<Text fw={400} fz={12} lh="16px" color="#959AA4">*/}
                          {/*  $50.01*/}
                          {/*</Text>*/}
                        </Stack>
                      </Group>
                    </UnstyledButton>
                  )
                })}
              </Stack>
            )}

            <Stack spacing={8}>
              <Text fw={400} fz={14} lh="20px" color="#4F5665">
                {searchQuery ? 'Search results' : 'Popular tokens'}
              </Text>
              {searchResultLoading || alienXPopTokensLoading ? (
                <>
                  {new Array(5).fill('').map((item, index) => (
                    <Group spacing={12} noWrap key={index}>
                      <Skeleton height={32} circle />
                      <Stack spacing={4} sx={{ flexGrow: 1 }}>
                        <Skeleton height={16} width="100%" radius="xl" />
                        <Skeleton height={16} width="40%" radius="xl" />
                      </Stack>
                    </Group>
                  ))}
                </>
              ) : (
                <>
                  {list.map((currencyInfo: CurrencyInfo, i) => {
                    const currency = currencyInfo.currency
                    const isSelected = selectedCurrency?.equals(currency)

                    return (
                      <UnstyledButton
                        key={i}
                        onClick={() => !isSelected && handleCurrencySelect(currency)}
                        disabled={isSelected}
                      >
                        <Group spacing={12} noWrap>
                          <CurrencyLogo currency={currency} size={32} />

                          <Stack spacing={0} sx={{ flexGrow: 1 }}>
                            <Text fw={500} fz={14} lh="20px" color="#0B132A">
                              {currency.name}
                            </Text>
                            <Text fw={400} fz={12} lh="16px" color="#959AA4">
                              {currency.symbol}
                            </Text>
                          </Stack>
                        </Group>
                      </UnstyledButton>
                    )
                  })}
                  {list.length === 0 && (
                    <Center mt={46}>
                      <Text fw={400} fz={14} lh="20px" color="#4F5665">
                        No More
                      </Text>
                    </Center>
                  )}
                </>
              )}
            </Stack>

            {/*<Stack spacing={8}>*/}
            {/*  <Text fw={400} fz={14} lh="20px" color="#4F5665">*/}
            {/*    Search results*/}
            {/*  </Text>*/}
            {/*  {new Array(3).fill(0).map((_, i) => (*/}
            {/*    <Group spacing={12} noWrap key={i}>*/}
            {/*      <Image width={32} height={32} radius={32} src={`${STATIC_BASEURL}`} />*/}
            {/*      <Stack spacing={0} sx={{ flexGrow: 1 }}>*/}
            {/*        <Text fw={500} fz={14} lh="20px" color="#0B132A">*/}
            {/*          Ethereum*/}
            {/*        </Text>*/}
            {/*        <Text fw={400} fz={12} lh="16px" color="#959AA4">*/}
            {/*          <Group spacing={8}>*/}
            {/*            <Text>ETH</Text>*/}
            {/*            <Text>0x123456...123456</Text>*/}
            {/*          </Group>*/}
            {/*        </Text>*/}
            {/*      </Stack>*/}
            {/*    </Group>*/}
            {/*  ))}*/}
            {/*  <Center mt={46}>*/}
            {/*    <Text fw={400} fz={14} lh="20px" color="#4F5665">*/}
            {/*      No More*/}
            {/*    </Text>*/}
            {/*  </Center>*/}
            {/*</Stack>*/}
          </Stack>
        </Stack>
      </Modal>
    </div>
  )
}

export default SelectToken
