import { Group, Text } from '@asuikit/core'
import { Currency, Price } from '@uniswap/sdk-core'
import { useUSDPrice } from 'hooks/useUSDPrice'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useCallback, useMemo, useState } from 'react'
import { ThemedText } from 'theme/components'
import { NumberType, useFormatter } from 'utils/formatNumbers'

interface TradePriceProps {
  price: Price<Currency, Currency>
  priceIsChange?: boolean
}

export default function TradePrice({ price, priceIsChange }: TradePriceProps) {
  const { formatNumber, formatPrice } = useFormatter()

  const [showInverted, setShowInverted] = useState<boolean>(false)

  const { baseCurrency, quoteCurrency } = price
  const { data: usdPrice } = useUSDPrice(tryParseCurrencyAmount('1', showInverted ? baseCurrency : quoteCurrency))

  const formattedPrice = useMemo(() => {
    try {
      return formatPrice({ price: showInverted ? price : price.invert(), type: NumberType.TokenTx })
    } catch {
      return '0'
    }
  }, [formatPrice, price, showInverted])

  const label = showInverted ? `${price.quoteCurrency?.symbol}` : `${price.baseCurrency?.symbol} `
  const labelInverted = showInverted ? `${price.baseCurrency?.symbol} ` : `${price.quoteCurrency?.symbol}`
  const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  const text = (
    <>
      {'1 '}
      {labelInverted} = <Text color={priceIsChange ? '#FE9839' : 'inherit'}>{formattedPrice}</Text> {label}
    </>
  )

  return (
    <>
      <Group
        spacing={2}
        onClick={(e) => {
          e.stopPropagation() // dont want this click to affect dropdowns / hovers
          flipPrice()
        }}
      >
        <>
          {'1 '}
          {labelInverted}{' '}
          {usdPrice && (
            <ThemedText.BodySmall color="neutral2">
              (
              {formatNumber({
                input: usdPrice,
                type: NumberType.FiatTokenPrice,
              })}
              )
            </ThemedText.BodySmall>
          )}{' '}
          = <Text color={priceIsChange ? '#FE9839' : 'inherit'}>{formattedPrice}</Text> {label}
        </>
      </Group>
    </>
  )
}
