import { Trans } from 'i18n'
import styled, { useTheme } from 'styled-components'

import { Box, Group, Text, Tooltip } from '@asuikit/core'
import { useMemo } from 'react'
import { RiAlertLine, RiForbid2Line, RiSeedlingLine } from 'react-icons/ri'

const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
`

const ActiveDot = styled.span`
  background-color: ${({ theme }) => theme.success};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const textColorMap = {
  outRange: '#FE9839',
  inRange: '#45AF5C',
  removed: '#959AA4',
}
const bgColorMap = {
  outRange: '#FFF0E1',
  inRange: '#E1FFE4',
  removed: '#F7F7F7',
}
const iconMap = {
  outRange: RiAlertLine,
  inRange: RiSeedlingLine,
  removed: RiForbid2Line,
}

export default function RangeBadge({ removed, inRange }: { removed?: boolean; inRange?: boolean }) {
  const theme = useTheme()

  const content = useMemo(() => {
    let statusType: 'removed' | 'inRange' | 'outRange' = 'removed'
    if (removed) {
      statusType = 'removed'
    } else if (inRange) {
      statusType = 'inRange'
    } else {
      statusType = 'outRange'
    }
    const bg = bgColorMap[statusType]
    const textColor = textColorMap[statusType]
    const icon = iconMap[statusType]
    const textMap = {
      removed: {
        text: <Trans>Closed</Trans>,
        tips: <Trans>Your position has 0 liquidity, and is not earning fees.</Trans>,
      },
      inRange: {
        text: <Trans>In range</Trans>,
        tips: (
          <Trans>The price of this pool is within your selected range. Your position is currently earning fees.</Trans>
        ),
      },
      outRange: {
        text: <Trans>Out of range</Trans>,
        tips: (
          <Trans>
            The price of this pool is outside of your selected range. Your position is not currently earning fees.
          </Trans>
        ),
      },
    }
    return (
      <Tooltip label={textMap[statusType].tips} withinPortal={true}>
        <Group
          spacing={2}
          py={4}
          px={6}
          sx={{
            background: bg,
            borderRadius: 4,
          }}
        >
          <Box component={icon} size={16} color={textColor}></Box>
          <Text fw={400} fz={12} lh="16px" color={textColor}>
            {textMap[statusType].text}
          </Text>
        </Group>
      </Tooltip>
    )
  }, [removed, inRange])

  return content
}
