import { Button, Divider, Group, Image, Text, TextInput } from '@asuikit/core'
import { Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import numbro from 'numbro'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ProjectModel } from 'service/launch.api.d'
import { parseUnits } from 'viem'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import { nativeOnChain } from '../../constants/tokens'
import { useCurrencyBalances } from '../../lib/hooks/useCurrencyBalance'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import isZero from '../../utils/isZero'
import notification from '../../utils/notification'
import { LaunchDetailContext } from './detail'
import { LaunchChainId } from './launchConst'
import { useLaunchDeposit } from './useLaunchContract'

export default function LaunchDeposit({
  item,
  saleType,
  contractAddress,
  paymentTokenContractAddress,
  paymentTokenDecimal,
  max,
  onRefresh,
}: {
  item: ProjectModel
  saleType: 0 | 1
  contractAddress: string
  paymentTokenContractAddress: string
  paymentTokenDecimal: number
  max?: number
  onRefresh?: () => void
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')

  const handleDismiss = useCallback(() => {
    setShowConfirmationModal(false)
    // if there was a tx hash, we want to clear the input
    setAttemptingTxn(false)
    setTxHash('')
  }, [])

  const paymentCurrency = useMemo(() => {
    if (isZero(paymentTokenContractAddress)) {
      return nativeOnChain(LaunchChainId)
    }
    if (!paymentTokenContractAddress || !paymentTokenDecimal) return

    return new Token(LaunchChainId, paymentTokenContractAddress, paymentTokenDecimal, item.currency, item.currency)
  }, [item.currency, paymentTokenContractAddress, paymentTokenDecimal])

  const res = useCurrencyBalances(account, [paymentCurrency])
  const currencyAmount = useMemo(() => {
    if (!res?.[0]) return 0
    return Number(
      numbro(res?.[0]?.toExact()).format({
        thousandSeparated: false,
        mantissa: 8,
        trimMantissa: true,
      })
    )
  }, [res])
  const maxNumber = useMemo(() => {
    if (max) {
      return Math.min(currencyAmount, max)
    }
    return currencyAmount
  }, [currencyAmount, max])

  const launchDetailContext = useContext(LaunchDetailContext)
  const [amount, setAmount] = useState<any>()
  const addTransaction = useTransactionAdder()
  const launchDeposit = useLaunchDeposit(
    contractAddress,
    saleType,
    paymentTokenContractAddress,
    paymentTokenDecimal,
    item.project_id
  )
  const { run, loading: depositLoading } = useRequest(
    async () => {
      if (!account || !amount) return
      try {
        setAttemptingTxn(true)
        setShowConfirmationModal(true)
        const num = parseUnits(String(amount), paymentTokenDecimal)
        const response = await launchDeposit(num)
        if (!response) return
        const transactionInfo: TransactionInfo = {
          type: TransactionType.LAUNCH_DEPOSIT,
          data: {
            detail: item,
            amount,
          },
        }
        addTransaction(response, transactionInfo)
        setAttemptingTxn(false)
        setTxHash(response.hash)
        notification.success(`Deposit success!`)
        launchDetailContext.refresh()
        if (onRefresh) {
          await sleep(1000)
          onRefresh()
        }
        setAmount(undefined)
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
        handleDismiss()
      }
    },
    {
      manual: true,
    }
  )

  const endTime = item.project_end_time
  const isDepositEnd = useMemo(() => {
    return dayjs().isAfter(endTime)
  }, [endTime])
  if (isDepositEnd) return null
  return (
    <>
      <Group spacing={12}>
        <Group
          w={330}
          px={16}
          sx={({ colors }) => ({
            borderRadius: 12,
            background: '#F7F7F7',
            height: 56,
          })}
        >
          <Group sx={{ width: '100%' }}>
            <Group spacing={6}>
              <Image width={24} height={24} radius={24} src={item.payment_token_logo} />
              <Text fw={500} fz={16} lh="20px" color="#0B132A">
                {item.currency}
              </Text>
            </Group>
            <Group spacing={12} sx={{ flex: 1 }} noWrap>
              <TextInput
                value={amount}
                onChange={(e) => {
                  const decimalPlaces = paymentTokenDecimal
                  let newValue = e.target.value
                  // 构建正则表达式来匹配允许的格式: 只能输入数字和小数点
                  const regex = new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`)
                  // 检查输入值是否符合正则表达式，且仅包含数字和小数点
                  if (regex.test(newValue) || newValue === '') {
                    if (!!maxNumber && maxNumber < Number(newValue)) {
                      newValue = String(maxNumber)
                    }
                    setAmount(newValue)
                  } else {
                    setAmount(amount)
                  }
                }}
                placeholder="Amount"
                styles={({ colors }) => ({
                  root: {
                    flex: '1 0',
                  },
                  input: {
                    padding: 0,
                    border: 0,
                    background: 'none',
                    height: '100%',
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '20px',
                    textAlign: 'right',
                  },
                })}
              />
              {!!max && (
                <>
                  <Divider orientation="vertical" h={16} sx={{ alignSelf: 'center' }} />
                  <Text
                    fw={400}
                    fz={16}
                    lh="20px"
                    color="#3C38F5"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setAmount(String(maxNumber))
                    }}
                  >
                    MAX
                  </Text>
                </>
              )}
            </Group>
          </Group>
        </Group>
        <Button
          size="xl"
          loading={depositLoading}
          onClick={run}
          sx={{
            flex: '1 0',
            background: '#45AF5C',
            '&:hover': {
              background: 'rgb(70,191,96)',
            },
          }}
        >
          Deposit
        </Button>
      </Group>

      <TransactionConfirmationModal
        isOpen={showConfirmationModal}
        onDismiss={handleDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash ? txHash : ''}
        reviewContent={() => <></>}
        title="Deposit"
        pendingText=""
      />
    </>
  )
}
