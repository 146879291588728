import { ActionIcon, Box, Center, createStyles, Divider, Group, Image, Skeleton, Stack, Text } from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useDisclosure } from '@asuikit/hooks'
import dayjs from 'dayjs'
import {
  RiDiscordLine,
  RiEarthLine,
  RiGithubFill,
  RiGlobalLine,
  RiMediumFill,
  RiTelegramLine,
  RiTwitterXLine,
  RiYoutubeLine,
} from 'react-icons/ri'
import { ProjectModel } from 'service/launch.api.d'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { STATIC_BASEURL } from '../../constants/detrade'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { mantissaNumber } from '../../utils/detrade/number'
import LaunchTimeCountdown from './LaunchTimeCountdown'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    time: {
      borderRadius: 6,
      background: '#E1FFE4',
      padding: 8,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#45AF5C',
      textAlign: 'center',
    },
    label: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#4F5665',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#45AF5C',
    },
    block: {
      borderRadius: 6,
      background: '#F7F7F7',
      padding: 12,
    },
    timeTitle: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#4F5665',
    },
    timeInfo: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#0B132A',
    },

    timeArea: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
    },
  }
})

const iconColor = '#0A0A0A'
const iconSize = 20
const iconMap = {
  twitter: <RiTwitterXLine size={14} color={iconColor} />,
  discord: <RiDiscordLine size={iconSize} color={iconColor} />,
  website: <RiEarthLine size={iconSize} color={iconColor} />,
  explorer: <RiGlobalLine size={iconSize} color={iconColor} />,
  telegram: <RiTelegramLine size={iconSize} color={iconColor} />,
  medium: <RiMediumFill size={iconSize} color={iconColor} />,
  github: <RiGithubFill size={iconSize} color={iconColor} />,
  coingecko: <Image width={20} height={20} src={`${STATIC_BASEURL}/images/detrade/launch/coingecko.svg`} />,
  youtube: <RiYoutubeLine size={iconSize} color={iconColor} />,
}

export default function LaunchIntroduction({ item, loading = true }: { item: ProjectModel; loading: boolean }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)
  const { launchId } = useParams<{
    launchId?: string
  }>()

  const { classes, cx } = useStyles()

  const startTime = item.project_start_time
  const endTime = item.project_end_time
  const isBefore = useMemo(() => {
    return dayjs().isBefore(startTime)
  }, [startTime])
  const distributionTime = item.project_distribution_time

  const isOver = useMemo(() => {
    return dayjs().isAfter(distributionTime)
  }, [distributionTime])
  const footerBtnGroup = useMemo(() => {
    return Object.keys(iconMap)
      .filter((key) => !!item[key])
      .map((key: any, index) => {
        return (
          <ActionIcon
            key={index}
            size={28}
            radius={4}
            variant="filled"
            sx={{
              background: '#00000014',
              ':hover': {
                background: '#00000014',
              },
            }}
            onClick={() => {
              if (!item[key]) return
              window.open(item[key])
            }}
          >
            {/*@ts-ignore*/}
            {iconMap[key]}
          </ActionIcon>
        )
      })
  }, [item])

  return (
    <Box
      mt={64}
      w={424}
      py={24}
      px={20}
      sx={({ colors }) => ({
        border: '1px solid #BABDC466',
        borderRadius: 16,
      })}
    >
      <Stack spacing={20}>
        {loading ? (
          <Skeleton height={27} />
        ) : (
          <>
            {isOver ? (
              <Box
                className={classes.time}
                sx={({ colors }) => ({
                  background: '#F7F7F7',
                  color: '#4F5665',
                })}
              >
                <Text fw={500} fz={14} lh="20px">
                  Ended
                </Text>
              </Box>
            ) : (
              <Box
                className={classes.time}
                sx={({ colors }) => ({
                  background: isBefore ? '#FFF0E1' : '#E1FFE4',
                  color: isBefore ? '#FE9839' : '#45AF5C',
                })}
              >
                <LaunchTimeCountdown endTime={endTime} startTime={startTime} />
              </Box>
            )}
          </>
        )}

        <Stack spacing={12}>
          <Center>
            {loading ? (
              <Skeleton height={50} circle />
            ) : (
              <Image width={50} height={50} radius={50} src={item.project_logo} />
            )}
          </Center>
          {loading ? (
            <Skeleton height={24} w="50%" mx="auto" />
          ) : (
            <Text fw={500} fz={20} lh="24px" color="#0B132A" ta="center">
              {item.project_name}
            </Text>
          )}

          {/*<Text fw={400} fz={14} lh="20px" color="#4F5665" lineClamp={3}>*/}
          {/*  ALIENX is the Al-Based blockchain infrastructure built for thhe large-scale adoption of NFT, and Gaming*/}
          {/*  Gaming Gaming Gaming Gaming Gaming Gaming Gaming Gaming GamingGaming Gaming Gaming Gaming Gaming Gaming*/}
          {/*  Gaming Gaming GamingGaming Gaming Gaming Gaming Gaming Gaming Gaming Gaming Gaming*/}
          {/*</Text>*/}
        </Stack>

        <Box className={classes.block}>
          <Stack spacing={6}>
            <Group spacing={10} position="apart">
              <div className={classes.label}>Total Allocation:</div>
              <div className={classes.value}>
                {loading ? (
                  <Skeleton height={20} width={80} />
                ) : (
                  <>
                    {mantissaNumber(item.total_allocation || 0, 8)} {item.currency}
                  </>
                )}
              </div>
            </Group>
            {item.is_private_sale && (
              <Group spacing={10} position="apart">
                <div className={classes.label}>Private Sale Price:</div>
                <div className={classes.value}>
                  {mantissaNumber(item.private_sale_price || 0, 8)} {item.currency}
                </div>
              </Group>
            )}

            <Group spacing={10} position="apart">
              <div className={classes.label}>Public Sale Price:</div>
              <div className={classes.value}>
                {loading ? (
                  <Skeleton height={20} width={80} />
                ) : (
                  <>
                    {mantissaNumber(item.public_sale_price || 0, 8)} {item.currency}
                  </>
                )}
              </div>
            </Group>
            <Group spacing={10} position="apart">
              <div className={classes.label}>Pool Size:</div>
              <div className={classes.value}>
                {loading ? (
                  <Skeleton height={20} width={80} />
                ) : (
                  <>
                    {mantissaNumber(item.pool_size || 0, 8)} {item.project_slug}
                  </>
                )}
              </div>
            </Group>
          </Stack>
        </Box>

        <Divider color="#BABDC480" />

        <Stack spacing={8}>
          <Text className={classes.timeTitle}>Sales Time</Text>

          {loading ? (
            <Skeleton height={60} />
          ) : (
            <Box className={classes.block}>
              <Group spacing={12} align="top">
                <Stack spacing={0}>
                  <div className={classes.timeInfo}>{dayjs(startTime).format('MMM DD, YYYY')}</div>
                  <div className={classes.timeArea}>{dayjs(startTime).format('HH:mm [UTC]Z')}</div>
                </Stack>
                <Stack spacing={0}>
                  <div className={classes.timeInfo}>-</div>
                </Stack>
                <Stack spacing={0}>
                  <div className={classes.timeInfo}>{dayjs(endTime).format('MMM DD, YYYY')}</div>
                  <div className={classes.timeArea}>{dayjs(endTime).format('HH:mm [UTC]Z')}</div>
                </Stack>
              </Group>
            </Box>
          )}
        </Stack>
        <Stack spacing={8}>
          <Text className={classes.timeTitle}>Distribution Time</Text>

          {loading ? (
            <Skeleton height={60} />
          ) : (
            <Box className={classes.block}>
              <div className={classes.timeInfo}>{dayjs(distributionTime).format('MMM DD, YYYY')}</div>
              <div className={classes.timeArea}>{dayjs(distributionTime).format('HH:mm [UTC]Z')}</div>
            </Box>
          )}
        </Stack>

        <Divider color="#BABDC480" />

        <Group spacing={8} position="center">
          {loading ? (
            <>
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
              <Skeleton height={24} width={24} />
            </>
          ) : (
            <>{footerBtnGroup}</>
          )}
        </Group>
      </Stack>
    </Box>
  )
}
