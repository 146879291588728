import i18n from 'i18next'
import notification from 'utils/notification'
import { didUserReject } from './swapErrorToUserReadableMessage'

export function getErrorMessage(e: any, defaultMsg = 'unknown error!(web)') {
  // console.log(1111, JSON.parse(JSON.stringify(e)), e?.error)
  let msg = e.msg || e?.cause?.reason || e.shortMessage || e.reason || e.message
  const errorCode = e.code || e.name
  if (errorCode && i18n.exists(`${errorCode}`, { ns: 'alienx_error_code' })) {
    msg = i18n.t(`${errorCode}`, { ns: 'alienx_error_code' })
  }
  return msg || defaultMsg
}

export function handleErrorMessage(e: any, defaultMsg = undefined) {
  if (didUserReject(e)) {
    return
  }
  if (e.message?.match?.(/underlying network changed/)) {
    return
  }
  if (e.response?.status === 401) {
    return
  }
  const msg = getErrorMessage(e, defaultMsg)
  notification.error(msg)
}
