import { CreatorTokenListModel, TotalCreatedModel } from './createToken.api.d'
import API from './index'

//   Total Number Of Created Token
//   GET /create_token/total_created
//   接口ID：195462074
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-195462074
export async function getTotalNumber(): Promise<TotalCreatedModel> {
  return API.get('/create_token/total_created', {})
}

//   Creator Token List
//   GET /create_token/creator_token_list
//   接口ID：195462073
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-195462073
export async function getCreatorTokenList(params: {
  /**
   * creator address
   */
  address: string
  /**
   * page
   */
  page?: number
  /**
   * page size
   */
  pageSize?: number
}): Promise<CreatorTokenListModel> {
  return API.get('/create_token/creator_token_list', { params })
}
