import { sendAnalyticsEvent as sendAnalyticsTraceEvent } from '@uniswap/analytics'
import { atomWithStorage } from 'jotai/utils'

export {
  OriginApplication,
  getDeviceId,
  initializeAnalytics,
  useTrace,
  user,
  type ITraceContext,
} from '@uniswap/analytics'

const allowAnalyticsAtomKey = 'allow_analytics'
export const allowAnalyticsAtom = atomWithStorage<boolean>(allowAnalyticsAtomKey, false)

export const Trace = (props: any) => {
  return props.children
}

Trace.displayName = 'Trace'

export const TraceEvent = (props: any) => {
  return props.children
}

TraceEvent.displayName = 'TraceEvent'

export const sendAnalyticsEvent: typeof sendAnalyticsTraceEvent = (event, properties) => {
  let allowAnalytics = true

  try {
    const value = localStorage.getItem(allowAnalyticsAtomKey)

    if (typeof value === 'string') {
      allowAnalytics = JSON.parse(value)
    }
    // eslint-disable-next-line no-empty
  } catch {}

  if (allowAnalytics) {
    sendAnalyticsTraceEvent(event, properties)
  }
}

// This is only used for initial page load so we can get the user's country
export const sendInitializationEvent: typeof sendAnalyticsTraceEvent = (event, properties) => {
  sendAnalyticsTraceEvent(event, properties)
}
