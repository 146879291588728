import { Box, Button, Group, Modal, ScrollArea, Stack, Text, TextInput, Textarea, createStyles } from '@asuikit/core'
import { isNotEmpty, useForm } from '@asuikit/form'
import { useRequest } from 'ahooks'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormSubmit } from '../../service/pools.api'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import notification from '../../utils/notification'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},

    footer: {
      borderTop: '1px solid #E6E6E6',
      padding: '18px 24px',
    },
  }
})
const formConfig = [
  {
    label: 'Project name',
    key: 'project_name',
  },
  {
    label: 'Token contract address',
    key: 'contract_address',
  },
  {
    label: 'TG for chatting with the team',
    key: 'tg',
  },
  {
    label: 'Twitter link',
    key: 'twitter',
  },
  {
    label: 'Token logo (Link to SVG)',
    key: 'logo',
  },
  {
    label: 'Code/github (optional)',
    key: 'github',
    optional: true,
  },
  {
    label: 'Total reward amount',
    key: 'reward_amount',
  },
  {
    label: 'Reward time of dur',
    key: 'reward_time_duraion',
  },
  {
    label:
      'Please describe the reason for why you need to set the reward for your token, the reward plan, and how you protect your reward from abuse',
    key: 'reason',
    placeholder: 'Write here',
    isTextarea: true,
  },
  {
    label: 'This is a newly launched project on AlienX chain or another existing chain native project',
    key: 'chain_info',
    placeholder: 'Write here',
    isTextarea: true,
  },
]

const validate = formConfig.reduce((cur: any, item) => {
  if (!item.optional) {
    const str = item.placeholder ? `Enter your content` : `Enter ${item.label.toLowerCase()}`
    cur[item.key] = isNotEmpty(str)
  }

  return cur
}, {})

function ModalSubmitReward({ opened, onClose }: any) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const form = useForm({
    initialValues: {
      project_name: '',
      contract_address: '',
      tg: '',
      twitter: '',
      logo: '',
      github: '',
      reward_amount: '',
      reward_time_duraion: '',
      reason: '',
      chain_info: '',
    },
    validate,
  })

  const { run, loading } = useRequest(
    async (values) => {
      try {
        await getFormSubmit(values)
        notification.success('Success.')
        form.reset()
        onClose()
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )
  const canNotSubmit = useMemo(() => {
    return formConfig.filter((item) => !item.optional).some((item) => !form.getInputProps(item.key).value)
  }, [form])

  return (
    <div className={classes.root}>
      <Modal
        opened={opened}
        onClose={onClose}
        title="Set new reward pool for your token"
        size={600}
        styles={({ colors }) => ({
          header: {
            borderBottom: '1px solid #E6E6E6',
            padding: '20px 24px',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0A0A0A',
            background: '#FFFFFF',
          },
          content: {
            background: '#FFFFFF',
          },
          body: {
            padding: 0,
          },
        })}
      >
        <form onSubmit={form.onSubmit((values) => run(values))}>
          <ScrollArea h={544} p={24}>
            <Stack spacing={16}>
              {formConfig.map((item, i) => {
                const comp: any = item.isTextarea ? Textarea : TextInput
                return (
                  <Box
                    component={comp}
                    key={i}
                    label={
                      <Group noWrap align="top" spacing={2}>
                        <Text>{i + 1}.</Text>
                        <Text sx={{ flex: 1 }}>{item.label}</Text>
                      </Group>
                    }
                    placeholder={item.placeholder || item.label}
                    minRows={4}
                    maxRows={4}
                    {...form.getInputProps(item.key)}
                    styles={({ colors }) => ({
                      label: {
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#0A0A0A',
                        marginBottom: 6,
                      },
                      input: {
                        border: '1px solid #CCCCCC',
                        background: '#fff',
                        height: item.isTextarea ? 'auto' : 48,
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: '20px',
                        borderRadius: 8,
                      },
                    })}
                  ></Box>
                )
              })}
            </Stack>
          </ScrollArea>
          <div className={classes.footer}>
            <Button fullWidth size="lg" disabled={canNotSubmit} loading={loading} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ModalSubmitReward
