import { ActionIcon, Box, Button, CopyButton, createStyles, Group, Modal, Stack, Text, Tooltip } from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import { ChainId, Percent, Token, V3_CORE_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { PiCopyLight } from 'react-icons/pi'
import { RiAlertLine } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { shortenAddress } from 'utilities/src/addresses/index'
import notification from 'utils/notification'
import RangeBadge from '../../components/Badge/RangeBadge'
import { DoubleCurrencyLogo } from '../../components/DoubleLogo'
import { PositionListItemProps, usePositionListItem } from '../../components/PositionListItem'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useV3NFTPositionManagerContract } from '../../hooks/useContract'
import { PoolCache } from '../../hooks/usePools'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { useFormatter } from '../../utils/formatNumbers'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { PositionsContext } from '../Pool'
import { LinkedCurrency } from '../Pool/PositionPage'
import { MyLPContext } from './index'
import { useStakePool, useUnStakePool } from './stakeContract.api'
import { stakePoolChainId } from './stakePoolConst'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },

    label: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#959AA4',
    },
    value: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
    },
  }
})

type StakeLiquidityModalType = {
  opened: boolean
  close: () => void
  open: () => void
  pool?: PositionListItemProps
  setPool: (pool: PositionListItemProps) => void
}

export const StakeLiquidityModalContext = createContext<StakeLiquidityModalType>({
  opened: false,
  close: () => undefined,
  open: () => undefined,
  pool: undefined,
  setPool: (pool) => undefined,
})

export function StakeLiquidityModalProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false)
  const [pool, setPool] = useState<PositionListItemProps | undefined>(undefined)
  return (
    <StakeLiquidityModalContext.Provider value={{ opened, open, close, pool, setPool }}>
      {children}
      {pool && <StakeLiquidityModal opened={opened} positionDetail={pool} onClose={close} />}
    </StakeLiquidityModalContext.Provider>
  )
}

export function StakeLiquidityInfo({
  currencyBase,
  currencyQuote,
  feeAmount,
  tokenId,
  poolAddress,
  removed,
  outOfRange,
}: any) {
  const { classes, cx } = useStyles()
  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()

  return (
    <Group
      spacing={8}
      pos="relative"
      position="apart"
      sx={{
        background: '#BABDC41A',
        borderRadius: 6,
        padding: '10px 8px',
      }}
    >
      <Group spacing={4}>
        <DoubleCurrencyLogo currencies={[currencyBase, currencyQuote]} size={32} />

        <Stack spacing={4} sx={{ flexGrow: 1 }}>
          <Group spacing={8}>
            <Text fw={500} fz={16} lh="20px" color="#0B132A">
              {currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
            </Text>

            <Box className={classes.tag}>
              {formatDelta(parseFloat(new Percent(feeAmount, 1_000_000).toSignificant()))}
            </Box>
          </Group>

          <Group spacing={4}>
            {/*<Link to={`/pools/${tokenId}`} target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'none' }}>*/}
            <Text fw={400} fz={12} lh="16px" color="#4F5665">
              #{tokenId.toString()}
            </Text>
            {/*</Link>*/}
            <Text fw={400} fz={12} lh="16px" color="#959AA4">
              {shortenAddress(poolAddress)}
            </Text>

            <CopyButton value={poolAddress || ''} timeout={1000}>
              {({ copy, copied }) => (
                <Tooltip label="Copied" withinPortal opened={copied}>
                  <ActionIcon
                    size={24}
                    onClick={() => {
                      copy()
                    }}
                  >
                    <PiCopyLight size={12} color="#141414" />
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Stack>
      </Group>

      <RangeBadge removed={removed} inRange={!outOfRange} />
    </Group>
  )
}

function StakeLiquidityModal({
  opened = false,
  positionDetail,
  onClose,
}: {
  opened?: boolean
  positionDetail: PositionListItemProps
  onClose: () => void
}) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const { chainId: connectedChainId } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const {
    tokenId,
    priceLower,
    priceUpper,
    currencyBase,
    currencyQuote,
    feeAmount,
    removed,
    outOfRange,
    tickAtLimit,
    inverted,
    position,
  } = usePositionListItem(positionDetail)

  const { classes, cx } = useStyles()
  const positionManager = useV3NFTPositionManagerContract()

  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()
  const { account } = useWeb3React()
  const stakePool = useStakePool()
  const unStake = useUnStakePool()
  const addTransaction = useTransactionAdder()
  const myLPContext = useContext(MyLPContext)
  const positionsContextContext = useContext(PositionsContext)
  const { pathname } = useLocation()
  const { run, loading } = useRequest(
    async () => {
      if (!account) return
      try {
        const response = await stakePool(tokenId)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.STAKE_LP,
          data: positionDetail,
        }
        addTransaction(response, transactionInfo)
        // await unStake(11)
        // const res = await stakePool(account, tokenId)
        notification.success(`stake success!`)
        positionsContextContext.refresh()
        if (pathname === '/pool-list') {
          await sleep(10000)
          myLPContext.refresh()
        }
        onClose()
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )

  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    if (opened) {
      setShowWarning(outOfRange)
    }
  }, [opened, outOfRange])

  const poolAddress = useMemo(() => {
    if (currencyBase && currencyQuote && feeAmount) {
      const tokenA = currencyBase.wrapped
      const tokenB = currencyQuote.wrapped
      if (tokenA.equals(tokenB)) return undefined
      const params: [Token, Token, number] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB, feeAmount]
        : [tokenB, tokenA, feeAmount]
      const address = PoolCache.getPoolAddress(V3_CORE_FACTORY_ADDRESSES[stakePoolChainId], ...params)

      return address
    }
    return undefined
  }, [currencyBase, currencyQuote, feeAmount])

  return (
    <>
      <Modal
        size={480}
        title="Stake liquidity"
        opened={opened}
        onClose={onClose}
        centered
        styles={({ colors, fn }) => ({
          content: {
            background: colors.bg[1],
          },
          header: {
            background: colors.bg[1],
            height: 64,
            padding: '20px 24px',
            // borderBottom: '1px solid' + colors.line[2],
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0B132A',
          },
          body: {
            padding: '8px 24px 24px',
          },
        })}
      >
        {showWarning ? (
          <>
            <Stack spacing={8} align="center">
              <RiAlertLine size={80} color="#FE9839" />
              <Text fw={400} fz={16} lh="20px" color="#959AA4" ta="center">
                The price of this pool is outside of your selected range. Your position is not currently earning fees or
                rewards. Do you still want to stake LP?
              </Text>
            </Stack>
            <Button fullWidth size="xl" onClick={() => setShowWarning(false)} mt={40}>
              Stake
            </Button>
          </>
        ) : (
          <Stack spacing={24}>
            <StakeLiquidityInfo
              currencyBase={currencyBase}
              currencyQuote={currencyQuote}
              feeAmount={feeAmount}
              tokenId={tokenId}
              poolAddress={poolAddress}
              removed={removed}
              outOfRange={outOfRange}
            />
            <Box
              p={16}
              sx={({ colors }) => ({
                borderRadius: 6,
                background: colors.bg[2],
              })}
            >
              <Stack spacing={14}>
                <Group>
                  <LinkedCurrency chainId={chainId ?? ChainId.MAINNET} currency={currencyQuote} />

                  <Box sx={{ flex: 1, textAlign: 'right' }}>
                    {formatCurrencyAmount({ amount: inverted ? position?.amount0 : position?.amount1 })}
                  </Box>
                </Group>

                <Group spacing={8}>
                  <LinkedCurrency chainId={chainId ?? ChainId.MAINNET} currency={currencyBase} />

                  <Box sx={{ flex: 1, textAlign: 'right' }}>
                    {formatCurrencyAmount({ amount: inverted ? position?.amount1 : position?.amount0 })}
                  </Box>
                </Group>
              </Stack>
            </Box>

            <Button fullWidth size="xl" onClick={run} loading={loading}>
              Approve and stake
            </Button>
          </Stack>
        )}
      </Modal>
    </>
  )
}
