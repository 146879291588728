import {
  ActionIcon,
  Box,
  Center,
  Drawer,
  Grid,
  Group,
  Pagination,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  createStyles,
  useMantineTheme,
} from '@asuikit/core'
import { PaginationProps } from '@asuikit/core/lib/Pagination/Pagination'
import { usePagination } from 'hooks/detrade/usePaginaion'
import { useLargeThan } from 'hooks/detrade/useWidthQuery'
import { get } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { RiArrowDownSFill, RiArrowDownSLine, RiArrowUpSFill, RiQuestionLine } from 'react-icons/ri'
import NoData from '../NoData'
import IconCheck from './check-line.svg'
import useTableRequest from './useTableRequest'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      color: colors.opacity[9],
      fontSize: 14,
      lineHeight: '24px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '&.border': {
        borderWidth: 1,
        borderStyle: 'solid',
        '.td': {
          borderWidth: 0,
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderRightWidth: 1,
          '&:last-child': {
            borderRightWidth: 0,
          },
        },
        '.th': {
          borderWidth: 0,
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderRightWidth: 1,
          '&:last-child': {
            borderRightWidth: 0,
          },
        },
      },
      '.hide-x-space': {
        '.td': {
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    },
    header: {
      '&:hover': {
        background: 'none',
      },
    },
    body: {
      overflow: 'auto',
      flex: 1,
    },
    td: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    filterList: {
      borderRadius: 12,
      background: colors.grey[1],
      padding: '0 12px',
    },
    filterItem: {
      display: 'block',
      borderBottom: '1px solid',
      borderColor: colors.line[3],
      paddingTop: 16,
      paddingBottom: 16,
    },
  }
})

interface ITableRes {
  list: any[]
  total: number
  page: number
}

const defaultTableRes: ITableRes = {
  list: [],
  total: 0,
  page: 1,
}

const ASDataTable = forwardRef<
  any,
  {
    columns: any[]
    data?: any
    fixedTableData?: any
    styles?: any
    verticalSpacing?: number
    horizontalSpacing?: number
    loading?: boolean
    fixedHeader?: boolean
    hasXSpace?: boolean
    pagination?: boolean | PaginationProps
    border?: boolean
    borderColor?: string
    smallScreenColumnNum?: number
    onChange?: (page: number) => void
    request?: (
      params: any,
      pageConfig: {
        page: number
        pageSize: number
      },
      sortParams: any
    ) => Promise<ITableRes>
    onRowClick?: (row: any) => void
    headerSticky?: boolean
  }
>(
  (
    {
      columns,
      data,
      fixedTableData = [],
      styles,
      verticalSpacing = 12,
      horizontalSpacing = 8,
      loading = false,
      hasXSpace = false,
      pagination = false,
      border = false,
      borderColor = '',
      smallScreenColumnNum = 3,
      onChange,
      request,
      onRowClick,
      headerSticky = false,
    },
    ref
  ) => {
    const { classes, cx } = useStyles()
    const spacing = 5
    const xsScreen = useLargeThan('xs')
    const mdScreen = useLargeThan('md')

    const { init, tableRes, tablePaginationConfig, onPageChange, tableResLoading, sortConfig, onSortChange } =
      useTableRequest(request, defaultTableRes, 5)
    useEffect(() => {
      if (request) {
        init()
      }
    }, [])

    useImperativeHandle(ref, () => ({
      init,
      tableRes,
    }))

    const spacingStyle = useMemo(() => {
      return {
        paddingTop: verticalSpacing,
        paddingBottom: verticalSpacing,
        paddingLeft: horizontalSpacing / 2,
        paddingRight: horizontalSpacing / 2,
      }
    }, [verticalSpacing, horizontalSpacing])

    const theme = useMantineTheme()
    const styleObject = typeof styles === 'function' ? styles(theme) : styles

    // const smallScreenColumnNum = 3;

    const [selectedFilterIndex, setSelectedFilterIndex] = useState<any>(smallScreenColumnNum - 1)

    const adaptiveColumns = useMemo(() => {
      return columns
        .map((item, index) => {
          return {
            index,
            ...item,
          }
        })
        .filter((item, index) => {
          if (!mdScreen) {
            return index < smallScreenColumnNum - 1 || item.index === selectedFilterIndex
          }
          return true
        })
    }, [columns, mdScreen, selectedFilterIndex])

    const filterList = useMemo(() => {
      const list = columns
        .map((item, index) => {
          return {
            index,
            ...item,
          }
        })
        .filter((item, index) => {
          return index >= smallScreenColumnNum - 1
        })
      return list
    }, [columns, smallScreenColumnNum])

    const [opened, setOpened] = useState(false)

    function drawerClose() {
      setOpened(false)
    }

    function selectFilter(item: any) {
      setSelectedFilterIndex(item.index)
      drawerClose()
    }

    const { currentPageList, currentPage, setCurrentPage, pageTotalNum } = usePagination(data ?? [], 10, 1)
    const paginationConfig = useMemo(() => {
      if (request) {
        return {
          ...tablePaginationConfig,
          onChange(page: any) {
            onPageChange(page)
          },
        }
      }
      if (pagination && typeof pagination !== 'boolean') {
        return {
          ...pagination,
          onChange: (page: any) => {
            onChange && onChange(page)
          },
        }
      }
      return {
        value: currentPage,
        total: pageTotalNum,
        onChange: (page: any) => {
          setCurrentPage(page)
        },
      }
    }, [currentPage, onChange, onPageChange, pageTotalNum, pagination, request, setCurrentPage, tablePaginationConfig])
    const showData = useMemo(() => {
      if (request) {
        return tableRes.list
      }
      if (!pagination) {
        return data
      }
      return currentPageList
    }, [currentPageList, data, pagination, request, tableRes.list])

    const showLoading = useMemo(() => {
      return tableResLoading || loading
    }, [loading, tableResLoading])

    function renderRow(row: any, rowIndex: any) {
      return adaptiveColumns?.map?.((item, index) => {
        return (
          <Box
            key={index}
            className={cx(classes.td, 'td')}
            sx={{
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              textAlign: item.align || 'left',
              flex: item.width ? `0 0 ${item.width}px` : 1,
              flexGrow: item.width ? 0 : 1 + '!important',
              borderColor,
              ...spacingStyle,
              ...styleObject?.td,
              cursor: onRowClick ? 'pointer' : 'inherit',
            }}
            onClick={() => {
              if (onRowClick) {
                onRowClick(row)
              }
            }}
          >
            <Group h="100%" w="100%" noWrap position={item.align || 'left'}>
              {item.render ? item.render(get(row, item.accessorKey), row, rowIndex) : get(row, item.accessorKey) || '-'}
            </Group>
          </Box>
        )
      })
    }

    return (
      <div
        className={cx(classes.root, {
          border,
        })}
        style={{
          borderColor,
        }}
      >
        <Group
          spacing={0}
          noWrap
          className={cx('as-data-table-header', {
            'hide-x-space': !hasXSpace,
          })}
          sx={{
            position: headerSticky ? 'sticky' : 'inherit',
            top: 0,
            zIndex: 9,
            ...styleObject?.header,
          }}
        >
          {adaptiveColumns?.map?.((item, index) => {
            return (
              <Box
                key={index}
                className={cx('th')}
                sx={{
                  textAlign: item.align || 'left',
                  flex: item.width ? `0 0 ${item.width}px` : 1,
                  flexGrow: item.width ? 0 : 1 + '!important',
                  borderColor,
                  ...spacingStyle,
                  ...styleObject?.th,
                }}
              >
                <Group spacing={4} position={item.align || 'left'} align="center" noWrap>
                  {item.title}
                  {item.sortKey && (
                    <ActionIcon size={20} onClick={() => onSortChange(item.sortKey)}>
                      <Stack spacing={-4}>
                        <RiArrowUpSFill
                          size={16}
                          color={
                            item.sortKey === sortConfig.sortBy && sortConfig.sortDirection === 'asc'
                              ? '#FF793C'
                              : '#4D4D4D'
                          }
                        />
                        <Box mt={-10}>
                          <RiArrowDownSFill
                            size={16}
                            color={
                              item.sortKey === sortConfig.sortBy && sortConfig.sortDirection === 'desc'
                                ? '#FF793C'
                                : '#4D4D4D'
                            }
                          />
                        </Box>
                      </Stack>
                    </ActionIcon>
                  )}

                  {mdScreen && item.tooltip && (
                    <Tooltip label={item.tooltip} w={400} multiline ta="left">
                      <Group>
                        <RiQuestionLine size={16} />
                      </Group>
                    </Tooltip>
                  )}
                  {!mdScreen && item.index >= smallScreenColumnNum - 1 && (
                    <ActionIcon onClick={() => setOpened(true)}>
                      <RiArrowDownSLine />
                    </ActionIcon>
                  )}
                </Group>
              </Box>
            )
          })}
        </Group>
        <div className={classes.body}>
          {showLoading ? (
            <>
              {new Array(10).fill('').map((_, index) => {
                return (
                  <Group
                    spacing={0}
                    key={index}
                    noWrap
                    sx={{
                      ...styleObject?.tr,
                    }}
                  >
                    {adaptiveColumns?.map?.((item, index) => {
                      return (
                        <Box
                          key={index}
                          className={cx(classes.td, 'td')}
                          sx={{
                            textAlign: item.align || 'left',
                            display: 'flex',
                            alignItems: 'center',
                            flex: item.width ? `0 0 ${item.width}px` : 1,
                            flexGrow: item.width ? 0 : 1 + '!important',
                            borderColor,
                            ...spacingStyle,
                            ...styleObject?.td,
                          }}
                        >
                          <Box w="100%">{item?.skeleton || <Skeleton height={20} />}</Box>
                        </Box>
                      )
                    })}
                  </Group>
                )
              })}
            </>
          ) : (
            <>
              {fixedTableData.length > 0 &&
                fixedTableData?.map?.((row: any, rowIndex: any) => {
                  return (
                    <Group
                      spacing={0}
                      key={rowIndex}
                      noWrap
                      sx={{
                        ...styleObject?.tr,
                      }}
                    >
                      {renderRow(row, rowIndex)}
                    </Group>
                  )
                })}
              {showData?.map?.((row: any, rowIndex: any) => {
                return (
                  <Group
                    spacing={0}
                    key={rowIndex}
                    noWrap
                    sx={{
                      ...styleObject?.tr,
                    }}
                  >
                    {renderRow(row, rowIndex)}
                  </Group>
                )
              })}
              {(pagination || request) && (
                <Center py={32}>
                  <Pagination
                    radius="lg"
                    boundaries={2}
                    siblings={2}
                    value={paginationConfig.value}
                    onChange={paginationConfig.onChange}
                    total={paginationConfig.total}
                  />
                </Center>
              )}
            </>
          )}
          {!showLoading && !showData?.length && !fixedTableData?.length && <NoData pt={30} />}
        </div>

        <Drawer
          position="bottom"
          size={509}
          opened={opened && !mdScreen}
          onClose={drawerClose}
          title="筛选"
          styles={({ colors }) => {
            return {
              root: {},
              header: {
                background: colors.bg[3],
                padding: '20px 16px',
              },
              title: {
                fontSize: 18,
                fontWeight: 600,
                color: colors.text[1],
              },
              content: {
                background: colors.bg[3],
                borderRadius: '20px 20px 0 0!important',
                overflow: 'hidden',
              },
              close: {
                border: '1px solid',
                borderColor: colors.line[3],
                borderRadius: 6,
                width: 24,
                height: 24,
                color: '#fff',
              },
              body: {
                padding: '0 16px 32px',
              },
            }
          }}
        >
          <div className={classes.filterList}>
            {filterList.map((item, index) => {
              return (
                <a className={classes.filterItem} key={index} onClick={() => selectFilter(item)}>
                  <Grid>
                    <Grid.Col span="auto">
                      <Stack spacing={4}>
                        <Text color="text.1" fz={16} lh="20px">
                          {item.title}
                        </Text>
                        <Text color="text.3" fz={12} lh="16px">
                          {item.tooltip}
                        </Text>
                      </Stack>
                    </Grid.Col>
                    <Grid.Col span="content">
                      <Group w={48} position="center">
                        {selectedFilterIndex === item.index && <IconCheck />}
                      </Group>
                    </Grid.Col>
                  </Grid>
                </a>
              )
            })}
          </div>
        </Drawer>
      </div>
    )
  }
)
ASDataTable.displayName = 'ASDataTable'
export default ASDataTable
