import { Flex, Group, Input, SegmentedControl, Stack, Text, Tooltip } from '@asuikit/core'
import { Percent } from '@uniswap/sdk-core'
import Row from 'components/Row'
import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import styled from 'styled-components'
import { useFormatter } from 'utils/formatNumbers'
import { AlertWarn, getInputStyles } from '../../detrade/pages/SwapForm/SlippageSetting'

enum SlippageError {
  InvalidInput = 'InvalidInput',
}

const Option = styled(Row)<{ isActive: boolean }>`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  background: ${({ isActive, theme }) => (isActive ? theme.surface3 : 'transparent')};
  pointer-events: ${({ isActive }) => isActive && 'none'};
`

const Switch = styled(Row)`
  width: auto;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
`

const NUMBER_WITH_MAX_TWO_DECIMAL_PLACES = /^(?:\d*\.\d{0,2}|\d+)$/
const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 10_000)
const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100)

function useFormatPercentInput() {
  const { formatPercent } = useFormatter()

  return (slippage: Percent) => formatPercent(slippage).slice(0, -1) // remove % sign
}

export default function MaxSlippageSettings({ autoSlippage }: { autoSlippage: Percent }) {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const { formatPercent } = useFormatter()
  const formatPercentInput = useFormatPercentInput()

  // In order to trigger `custom` mode, we need to set `userSlippageTolerance` to a value that is not `auto`.
  // To do so, we use `autoSlippage` value. However, since users are likely to change that value,
  // we render it as a placeholder instead of a value.
  const defaultSlippageInputValue =
    userSlippageTolerance !== SlippageTolerance.Auto && !userSlippageTolerance.equalTo(autoSlippage)
      ? formatPercentInput(userSlippageTolerance)
      : ''

  // If user has previously entered a custom slippage, we want to show that value in the input field
  // instead of a placeholder.
  const [slippageInput, setSlippageInput] = useState(defaultSlippageInputValue)
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)

  // If user has previously entered a custom slippage, we want to show the settings expanded by default.
  const [isOpen, setIsOpen] = useState(defaultSlippageInputValue.length > 0)

  const parseSlippageInput = (value: string) => {
    // Do not allow non-numerical characters in the input field or more than two decimals
    if (value.length > 0 && !NUMBER_WITH_MAX_TWO_DECIMAL_PLACES.test(value)) {
      return
    }

    setSlippageInput(value)
    setSlippageError(false)

    // If the input is empty, set the slippage to the default
    if (value.length === 0) {
      setUserSlippageTolerance(SlippageTolerance.Auto)
      return
    }

    if (value === '.') {
      return
    }

    // Parse user input and set the slippage if valid, error otherwise
    try {
      const parsed = Math.floor(Number.parseFloat(value) * 100)
      if (parsed > 5000) {
        setSlippageError(SlippageError.InvalidInput)
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    } catch (e) {
      setSlippageError(SlippageError.InvalidInput)
    }
  }

  const tooLow =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)
  const tooHigh =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)

  const selectedTab = useMemo(() => {
    return userSlippageTolerance === SlippageTolerance.Auto ? SlippageTolerance.Auto : 'Custom'
  }, [userSlippageTolerance])

  function handleSegmentedControl(value: SlippageTolerance) {
    if (value === SlippageTolerance.Auto) {
      setSlippageInput('')
      setUserSlippageTolerance(SlippageTolerance.Auto)
    } else {
      setUserSlippageTolerance(autoSlippage)
    }
  }
  return (
    <>
      <Stack spacing={12}>
        <Group spacing={2}>
          <Text fw={500} fz={16} lh="20px" color="#0B132A">
            Slippage
          </Text>
          <Tooltip
            width={240}
            withArrow
            multiline
            position="top-start"
            label={
              <Text fw={400} fz={14} lh="20px" color="grey.0">
                Your transaction will be revert if the price changes by more than this percentage
              </Text>
            }
          >
            <Group>
              <RiErrorWarningFill size={16} color="#BABDC4" />
            </Group>
          </Tooltip>
        </Group>

        <Flex gap={6}>
          <SegmentedControl
            value={selectedTab}
            onChange={handleSegmentedControl}
            data={[
              { label: 'Auto', value: SlippageTolerance.Auto },
              { label: 'Custom', value: 'Custom' },
            ]}
            styles={({ colors }) => ({
              root: {
                background: '#fff',
                border: '1px solid' + colors.line[2],
                padding: 6,

                borderRadius: 14,
                height: 40,
              },
              indicator: {
                background: colors.bg[3],
                height: 28,

                borderRadius: 10,
                marginTop: -3,
                marginLeft: -3,
              },
              label: {
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '20px',
                color: '#0B132A',
                padding: '4px 8px',
                height: 28,
              },
            })}
          />
          <Input
            rightSectionWidth={40}
            placeholder={formatPercentInput(autoSlippage)}
            value={slippageInput}
            onChange={(e) => parseSlippageInput(e.target.value)}
            onBlur={() => {
              // When the input field is blurred, reset the input field to the default value
              setSlippageInput(defaultSlippageInputValue)
              setSlippageError(false)
            }}
            rightSection={
              <Text fw={400} fz={16} lh="20px" color="#0B132A">
                %
              </Text>
            }
            styles={getInputStyles}
          />
        </Flex>
        {tooLow || tooHigh ? (
          <AlertWarn
            text={
              tooLow ? (
                <Trans>
                  Slippage below {{ amt: formatPercent(MINIMUM_RECOMMENDED_SLIPPAGE) }} may result in a failed
                  transaction
                </Trans>
              ) : (
                <Trans>Your transaction may be frontrun and result in an unfavorable trade.</Trans>
              )
            }
          />
        ) : null}

        {/*  Slippage below 0.05% may result in afailed transaction */}
      </Stack>
      {/*<Expand*/}
      {/*  testId="max-slippage-settings"*/}
      {/*  padding="6px 0px"*/}
      {/*  isOpen={isOpen}*/}
      {/*  onToggle={() => setIsOpen(!isOpen)}*/}
      {/*  header={*/}
      {/*    <Row width="auto">*/}
      {/*      <ThemedText.BodyPrimary>*/}
      {/*        <Trans>Max. slippage</Trans>*/}
      {/*      </ThemedText.BodyPrimary>*/}
      {/*      <QuestionHelper*/}
      {/*        text={*/}
      {/*          <Trans>Your transaction will revert if the price changes unfavorably by more than this percentage.</Trans>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </Row>*/}
      {/*  }*/}
      {/*  button={*/}
      {/*    <ThemedText.BodyPrimary>*/}
      {/*      {userSlippageTolerance === SlippageTolerance.Auto ? (*/}
      {/*        <Trans>Auto</Trans>*/}
      {/*      ) : (*/}
      {/*        formatPercent(userSlippageTolerance)*/}
      {/*      )}*/}
      {/*    </ThemedText.BodyPrimary>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <RowBetween gap="md">*/}
      {/*    <Switch>*/}
      {/*      <Option*/}
      {/*        onClick={() => {*/}
      {/*          // Reset the input field when switching to auto*/}
      {/*          setSlippageInput('')*/}
      {/*          setUserSlippageTolerance(SlippageTolerance.Auto)*/}
      {/*        }}*/}
      {/*        isActive={userSlippageTolerance === SlippageTolerance.Auto}*/}
      {/*      >*/}
      {/*        <ThemedText.BodyPrimary>*/}
      {/*          <Trans>Auto</Trans>*/}
      {/*        </ThemedText.BodyPrimary>*/}
      {/*      </Option>*/}
      {/*      <Option*/}
      {/*        onClick={() => {*/}
      {/*          // When switching to custom slippage, use `auto` value as a default.*/}
      {/*          setUserSlippageTolerance(autoSlippage)*/}
      {/*        }}*/}
      {/*        isActive={userSlippageTolerance !== SlippageTolerance.Auto}*/}
      {/*      >*/}
      {/*        <ThemedText.BodyPrimary>*/}
      {/*          <Trans>Custom</Trans>*/}
      {/*        </ThemedText.BodyPrimary>*/}
      {/*      </Option>*/}
      {/*    </Switch>*/}
      {/*    <InputContainer gap="md" error={!!slippageError}>*/}
      {/*      <Input*/}
      {/*        data-testid="slippage-input"*/}
      {/*        placeholder={formatPercentInput(autoSlippage)}*/}
      {/*        value={slippageInput}*/}
      {/*        onChange={(e) => parseSlippageInput(e.target.value)}*/}
      {/*        onBlur={() => {*/}
      {/*          // When the input field is blurred, reset the input field to the default value*/}
      {/*          setSlippageInput(defaultSlippageInputValue)*/}
      {/*          setSlippageError(false)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <ThemedText.BodyPrimary>%</ThemedText.BodyPrimary>*/}
      {/*    </InputContainer>*/}
      {/*  </RowBetween>*/}
      {/*  {tooLow || tooHigh ? (*/}
      {/*    <RowBetween gap="md">*/}
      {/*      <CautionTriangle />*/}
      {/*      <ThemedText.BodySmall color="deprecated_accentWarning">*/}
      {/*        {tooLow ? (*/}
      {/*          <Trans>*/}
      {/*            Slippage below {{ amt: formatPercent(MINIMUM_RECOMMENDED_SLIPPAGE) }} may result in a failed transaction*/}
      {/*          </Trans>*/}
      {/*        ) : (*/}
      {/*          <Trans>Your transaction may be frontrun and result in an unfavorable trade.</Trans>*/}
      {/*        )}*/}
      {/*      </ThemedText.BodySmall>*/}
      {/*    </RowBetween>*/}
      {/*  ) : null}*/}
      {/*</Expand>*/}
    </>
  )
}
