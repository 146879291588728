import { Box, Button, Divider, Group, Modal, Slider, Stack, Switch, Text, createStyles } from '@asuikit/core'
import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { LiquidityEventName, LiquiditySource } from '@uniswap/analytics-events'
import { CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { NonfungiblePositionManager } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'
import { sendAnalyticsEvent, useTrace } from '../../../analytics'
import { useIsSupportedChainId } from '../../../constants/chains'
import { WRAPPED_NATIVE_CURRENCY } from '../../../constants/tokens'
import { useLargeThan } from '../../../hooks/detrade/useWidthQuery'
import { useV3NFTPositionManagerContract } from '../../../hooks/useContract'
import useDebouncedChangeHandler from '../../../hooks/useDebouncedChangeHandler'
import { useGetTransactionDeadline } from '../../../hooks/useTransactionDeadline'
import { Trans } from '../../../i18n'
import useNativeCurrency from '../../../lib/hooks/useNativeCurrency'
import { MyLPContext } from '../../../pages/StakePool'
import { useBurnV3ActionHandlers, useBurnV3State, useDerivedV3BurnInfo } from '../../../state/burn/v3/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
import { useUserSlippageToleranceWithDefault } from '../../../state/user/hooks'
import { PositionDetails } from '../../../types/position'
import { sleep } from '../../../utils'
import { calculateGasMargin } from '../../../utils/calculateGasMargin'
import { currencyId } from '../../../utils/currencyId'
import { WrongChainError } from '../../../utils/errors'
import { useFormatter } from '../../../utils/formatNumbers'
import { handleErrorMessage } from '../../../utils/handleErrorMessage'
import RangeBadge from '../../Badge/RangeBadge'
import { DoubleCurrencyLogo } from '../../DoubleLogo'
import CurrencyLogo from '../../Logo/CurrencyLogo'
import { ConfirmationPendingContent } from '../../TransactionConfirmationModal'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},

    box: {
      borderRadius: 8,
      background: '#F7F7F7',
      padding: 16,
    },
  }
})

const DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function RemoveLiquidity({ opened, onClose, tokenId, position, needWaitConfirm }: any) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const { chainId } = useWeb3React()
  const isSupportedChain = useIsSupportedChainId(chainId)
  const location = useLocation()
  const parsedTokenId = useMemo(() => {
    try {
      return BigNumber.from(tokenId)
    } catch {
      return null
    }
  }, [tokenId])

  return (
    <div className={classes.root}>
      <Modal
        size={480}
        title="Remove liquidity"
        opened={opened}
        onClose={onClose}
        centered
        styles={({ colors, fn }) => ({
          content: {
            background: colors.bg[1],
          },
          header: {
            background: colors.bg[1],
            height: 64,
            padding: '20px 24px',
            // borderBottom: '1px solid' + colors.line[2],
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0B132A',
          },
          body: {
            padding: '24px 24px 24px',
          },
        })}
      >
        {!(parsedTokenId === null || parsedTokenId.eq(0)) && (
          <Remove tokenId={parsedTokenId} position={position} onClose={onClose} needWaitConfirm={needWaitConfirm} />
        )}
      </Modal>
    </div>
  )
}

function Remove({
  tokenId,
  position,
  onClose,
  needWaitConfirm,
}: {
  tokenId: BigNumber
  position: PositionDetails
  onClose: () => void
  needWaitConfirm: boolean
}) {
  const { classes, cx } = useStyles()

  // const { position } = useV3PositionFromTokenId(tokenId)
  const { account, chainId, provider } = useWeb3React()
  const trace = useTrace()
  const { formatCurrencyAmount } = useFormatter()

  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const nativeCurrency = useNativeCurrency(chainId)
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

  // burn state
  const { percent } = useBurnV3State()
  const {
    position: positionSDK,
    liquidityPercentage,
    liquidityValue0,
    liquidityValue1,
    feeValue0,
    feeValue1,
    outOfRange,
    error,
  } = useDerivedV3BurnInfo(position, receiveWETH)
  const { onPercentSelect } = useBurnV3ActionHandlers()

  const removed = position?.liquidity?.eq(0)

  // boilerplate for the slider
  const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(percent, onPercentSelect)

  const getDeadline = useGetTransactionDeadline() // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE) // custom from users

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()

  const myLPContext = useContext(MyLPContext)
  const [loading, setLoading] = useState(false)
  const burn = useCallback(async () => {
    setAttemptingTxn(true)
    if (
      !positionManager ||
      !liquidityValue0 ||
      !liquidityValue1 ||
      !account ||
      !chainId ||
      !positionSDK ||
      !liquidityPercentage ||
      !provider
    ) {
      return
    }

    const deadline = await getDeadline()
    if (!deadline) throw new Error('could not get deadline')

    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(positionSDK, {
      tokenId: tokenId.toString(),
      liquidityPercentage,
      slippageTolerance: allowedSlippage,
      deadline: deadline.toString(),
      collectOptions: {
        expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(liquidityValue0.currency, 0),
        expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(liquidityValue1.currency, 0),
        recipient: account,
      },
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    const connectedChainId = await provider.getSigner().getChainId()
    if (chainId !== connectedChainId) throw new WrongChainError()

    setLoading(true)
    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }
        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then(async (response: TransactionResponse) => {
            sendAnalyticsEvent(LiquidityEventName.REMOVE_LIQUIDITY_SUBMITTED, {
              source: LiquiditySource.V3,
              label: [liquidityValue0.currency.symbol, liquidityValue1.currency.symbol].join('/'),
              ...trace,
            })
            setTxnHash(response.hash)
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.REMOVE_LIQUIDITY_V3,
              baseCurrencyId: currencyId(liquidityValue0.currency),
              quoteCurrencyId: currencyId(liquidityValue1.currency),
              expectedAmountBaseRaw: liquidityValue0.quotient.toString(),
              expectedAmountQuoteRaw: liquidityValue1.quotient.toString(),
            })
            if (needWaitConfirm) {
              await sleep(11000)
              myLPContext.refresh()
              setLoading(false)
            }
            onClose()
          })
      })
      .catch((error) => {
        setAttemptingTxn(false)
        setLoading(false)
        console.error(error)
        if (!didUserReject(error)) {
          handleErrorMessage(error)
        }
      })
  }, [
    positionManager,
    liquidityValue0,
    liquidityValue1,
    account,
    chainId,
    positionSDK,
    liquidityPercentage,
    provider,
    getDeadline,
    tokenId,
    allowedSlippage,
    feeValue0,
    feeValue1,
    trace,
    addTransaction,
    needWaitConfirm,
    onClose,
    myLPContext,
  ])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
      onPercentSelectForSlider(0)
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [onPercentSelectForSlider, txnHash])

  const pendingText = (
    <Trans>
      Removing {{ amt: liquidityValue0?.toSignificant(6) }} {{ sym: liquidityValue0?.currency?.symbol }} and{' '}
      {{ amt2: liquidityValue1?.toSignificant(6) }} {{ sym2: liquidityValue1?.currency?.symbol }}
    </Trans>
  )

  const showCollectAsWeth = Boolean(
    liquidityValue0?.currency &&
      liquidityValue1?.currency &&
      (liquidityValue0.currency.isNative ||
        liquidityValue1.currency.isNative ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue0.currency.chainId]?.equals(liquidityValue0.currency.wrapped) ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue1.currency.chainId]?.equals(liquidityValue1.currency.wrapped))
  )

  const list = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
    {
      value: 100,
    },
  ]
  return (
    <>
      <Stack spacing={24}>
        <Group position="apart">
          <Group spacing={8}>
            <DoubleCurrencyLogo currencies={[liquidityValue0?.currency, liquidityValue1?.currency]} size={30} />

            <Text fw={500} fz={20} lh="24px" color="#0B132A">
              {`${liquidityValue0?.currency?.symbol}/${liquidityValue1?.currency?.symbol}`}
            </Text>
          </Group>
          <Group spacing={8}>
            <RangeBadge removed={removed} inRange={!outOfRange} />
          </Group>
        </Group>

        <Box className={classes.box}>
          <Text fw={400} fz={14} lh="20px" color="#959AA4">
            Amount
          </Text>

          <Group mt={12} mb={22} position="apart">
            <Text fw={500} fz={32} lh="36px" color="#0B132A">
              {percentForSlider}%
            </Text>
            <Group spacing={6}>
              {list.map((item, index) => (
                <Box
                  key={index}
                  sx={({ colors }) => ({
                    background: '#fff',
                    borderRadius: 4,
                    border: '1px solid' + colors.line[2],
                    padding: '6px 8px',
                    cursor: 'pointer',
                  })}
                  onClick={() => onPercentSelect(item.value)}
                >
                  <Text fw={400} fz={14} lh="16px" color="#0B132A">
                    {item.value}%
                  </Text>
                </Box>
              ))}
            </Group>
          </Group>
          <Slider
            value={percentForSlider}
            onChange={onPercentSelectForSlider}
            marks={[{ value: 25 }, { value: 50 }, { value: 75 }]}
          />
        </Box>

        <Stack
          spacing={14}
          className={classes.box}
          sx={({ colors }) => ({
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '20px',
            color: '#0B132A',
          })}
        >
          <Group spacing={6}>
            <CurrencyLogo size={24} currency={liquidityValue0?.currency} />

            <Box sx={{ flexGrow: 1 }}>Pooled {liquidityValue0?.currency?.symbol}</Box>
            <Box>{liquidityValue0 && formatCurrencyAmount({ amount: liquidityValue0 })}</Box>
          </Group>
          <Group spacing={6}>
            <CurrencyLogo size={24} currency={liquidityValue1?.currency} />

            <Box sx={{ flexGrow: 1 }}>Pooled {liquidityValue1?.currency?.symbol}</Box>
            <Box>{liquidityValue1 && formatCurrencyAmount({ amount: liquidityValue1 })}</Box>
          </Group>

          {(feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) && (
            <>
              <Divider color="line.2" />

              <Group spacing={6}>
                <CurrencyLogo size={24} currency={feeValue0?.currency} />

                <Box sx={{ flexGrow: 1 }}>{feeValue0?.currency?.symbol} Fees Earned:</Box>
                <Box>{feeValue0 && formatCurrencyAmount({ amount: feeValue0 })}</Box>
              </Group>
              <Group spacing={6}>
                <CurrencyLogo size={24} currency={feeValue1?.currency} />

                <Box sx={{ flexGrow: 1 }}>{feeValue1?.currency?.symbol} Fees Earned:</Box>
                <Box>{feeValue1 && formatCurrencyAmount({ amount: feeValue1 })}</Box>
              </Group>
            </>
          )}
        </Stack>
      </Stack>

      {showCollectAsWeth && (
        <Group spacing={16} position="apart" mt={18}>
          <Text fw={500} fz={16} lh="20px" color="#0B132A">
            Collect as {nativeWrappedSymbol}
          </Text>
          <Switch checked={receiveWETH} onChange={() => setReceiveWETH((receiveWETH) => !receiveWETH)} />
        </Group>
      )}

      <Button
        mt={24}
        fullWidth
        size="xl"
        loading={loading}
        disabled={removed || percent === 0 || !liquidityValue0}
        onClick={() => {
          setShowConfirm(true)
          burn()
        }}
      >
        {removed ? <Trans>Closed</Trans> : error ?? <Trans>Remove</Trans>}
      </Button>

      <Modal
        size={480}
        title=""
        opened={showConfirm && attemptingTxn}
        onClose={handleDismissConfirmation}
        centered
        styles={({ colors, fn }) => ({
          content: {
            background: colors.bg[1],
          },
          header: {
            background: colors.bg[1],
            height: 64,
            padding: '20px 24px',
            // borderBottom: '1px solid' + colors.line[2],
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            color: '#0B132A',
          },
          body: {
            padding: '8px 24px 24px',
          },
        })}
      >
        <ConfirmationPendingContent onDismiss={handleDismissConfirmation} pendingText={pendingText} />
      </Modal>
    </>
  )
}
