import API from './index'
import { LPDBannerModel, LPDFinishedModel, PrivateSaleModel, PrivateSaleSignModel, ProjectModel } from './launch.api.d'

//   Launchpad Banner List
//   GET /lpd/banner
//   接口ID：193077846
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077846
export async function getLaunchpadBannerList(): Promise<LPDBannerModel[]> {
  return API.get('/lpd/banner', {})
}

//  Launchpad Finished List
//   GET /lpd/finished
//   接口ID：193077847
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077847
export async function getLaunchpadFinishedList(): Promise<LPDFinishedModel[]> {
  return API.get('/lpd/finished', {})
}

//   Search Project
//   GET /lpd/search
//   接口ID：193077848
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077848
export async function getSearchProject(q: string): Promise<LPDFinishedModel[]> {
  return API.get('/lpd/search', {
    params: {
      q,
    },
  })
}

//   Project Info
//   GET /lpd/project_info
//   接口ID：193077849
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077849
export async function getProjectInfo(project_id: string): Promise<ProjectModel> {
  return API.get('/lpd/project_info', {
    params: {
      project_id,
    },
  })
}

//   Project Private Sale Info
//   GET /lpd/private_sale_info
//   接口ID：193077850
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077850
export async function getProjectPrivateSaleInfo(params: {
  project_id: string
  address: string
}): Promise<PrivateSaleModel> {
  return API.get('/lpd/private_sale_info', {
    params,
  })
}

//   Private Sale Sign
//   POST /lpd/private_sale/sign
//   接口ID：193077851
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-193077851
export async function postPrivateSaleSign(params: {
  project_id: string
  address: string
  timestamp: number
  buy_amount: number | string
  signature: string
}): Promise<PrivateSaleSignModel> {
  return API.post('/lpd/private_sale/sign', {
    ...params,
  })
}
