import { ChainId } from '@uniswap/sdk-core'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { merge } from 'lodash'
import qs from 'qs'
import { CHAIN_INFO, isSupportedChainId } from '../constants/chains'

type WeirdAxiosInstance = Omit<AxiosInstance, 'post' | 'get'> & {
  get<T = any, R = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>
  post<T = any, R = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>
}

// @ts-ignore
const baseURL = {
  dev: '/api',
  staging: 'https://api-test.detrade.one',
  prod: 'https://api.detrade.one',
  // @ts-ignore
}[process.env.REACT_APP_ENV || 'prod'] // @ts-ignore
window.APP_ENV = process.env.REACT_APP_ENV // @ts-ignore
const API: WeirdAxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  },
})

function getChainData(chainId: number) {
  return isSupportedChainId(chainId) ? CHAIN_INFO[chainId] : CHAIN_INFO[ChainId.ALIENXCHAIN]
}

API.interceptors.request.use((config) => {
  config.params = config.params || {}

  config.headers = config.headers || {}

  if (config.headers?.['Content-Type'] !== 'multipart/form-data') {
    config.params = merge({}, config.params, {})
    config.data = merge({}, config?.data ?? {}, {})
  }
  return config
})

API.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data?.msg === 'ok') {
      return response.data.data
    } else {
      return Promise.reject({
        code: response.data.status,
        message: response.data.msg,
        ...response.data,
      })
    }
  },
  (err) => {
    throw err
  }
)

export default API
