import numbro from 'numbro'

export function averageNumber(value: any, mantissa = 6, optionalMantissa = true, averageNum = 99999) {
  return String(
    mantissaNumber(value, value > averageNum ? 2 : mantissa, optionalMantissa, value > averageNum)
  ).toUpperCase()
}

export function mantissaNumber(value: any, mantissa = 6, optionalMantissa = true, average = false) {
  if (!value) return '0'
  return numbro(value).format({
    thousandSeparated: true,
    average,
    totalLength: average ? 4 : 0,
    optionalMantissa,
    mantissa,
    trimMantissa: true,
  })
}

export const formatNumberWithThousandsSeparator = (str: string) => {
  // Split the input string into integer and decimal parts
  const [integerPart, decimalPart] = str.split('.')

  // Add thousand separators to the integer part
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Combine the integer part and decimal part (if any)
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart
}

export const formatScientificNotation = (numberString: string) => {
  // Convert the string to a BigInt
  const number = BigInt(numberString)

  // Convert BigInt to a number to use scientific notation (not directly supported)
  const num = Number(number)

  // Format the number in scientific notation
  const scientificNotation = num.toExponential(2)

  // Extract the coefficient and exponent parts
  const [coefficient, exponent] = scientificNotation.split('e+')

  // Construct the formatted scientific notation string
  return `${mantissaNumber(coefficient, 2)} x 10^${exponent}`
}

export const formatShowNumber = (numberString: string) => {
  const num = String(numberString || 0)
  if (9999999999 > Number(num)) {
    return averageNumber(num)
  }
  return formatScientificNotation(num)
}
