import { ActionIcon, Box, CopyButton, createStyles, Divider, Group, Image, Stack, Text, Tooltip } from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useDisclosure } from '@asuikit/hooks'
import { PiCopyLight } from 'react-icons/pi'
import ReactMarkdown from 'react-markdown'
import { ProjectModel } from 'service/launch.api.d'
import { shortenAddress } from 'utilities/src/addresses'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { mantissaNumber } from '../../utils/detrade/number'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    label: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#4F5665',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#0B132A',
    },
  }
})

function MarkdownImage({ ...rest }) {
  return <Image {...rest} width={550} height="auto" />
}
export default function LaunchInfo({ item, loading = true }: { item: ProjectModel; loading: boolean }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)
  const { launchId } = useParams<{
    launchId?: string
  }>()

  const { classes, cx } = useStyles()
  const address = useMemo(() => {
    return item.project_contract_address
  }, [item.project_contract_address])

  const list = useMemo(() => {
    return [
      {
        title: 'Allocation',
        desc: (
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            Deposit-Based Allocation: Your token allocation is based on the amount you deposit. A higher deposit results
            in a larger allocation.
            <Text fw={600} fz={14} lh="20px">
              Scenario 1: Public Sale Total Deposits ≤ Public Sale Allocation:
            </Text>
            <ul>
              <li>
                If the public sale total deposits from all users do not exceed the public sale allocation, your entire
                deposit will be used to purchase tokens.
              </li>
              <li>Your public sale token allocation = your deposit.</li>
            </ul>
            <Text fw={600} fz={14} lh="20px">
              Scenario 2: Public Sale Total Deposits {'>'} Public Sale Allocation:
            </Text>
            <ul>
              <li>
                {`If the public sale total deposits from all users exceed the public sale allocation, each user's deposit will be partially used, and the remainder will be refunded.`}
              </li>
              <li>Your public sale token allocation = (your deposit / total deposits) * public sale allocation.</li>
              <li>Refund = your deposit - (your public sale token allocation * public sale price).</li>
            </ul>
          </Text>
        ),
      },
      {
        title: 'What is ALIENX ?',
        desc:
          'Plena Finance was the first mobile application to use the now-trending Account Abstraction technology. They started using it 2 years ago when no one was using it, and no one believed people would switch from Metamask or Trust Wallet. Today, everyone in Web3 including Vitalik (Co - Founder of Ethereum) believes that only Account Abstraction can bring mass adoption to blockchain applications and onboard a billion users to web3. \n' +
          'Using Account Abstraction, they are able to provide their users with a centralized application like UX. What this means is that their users don’t have to learn anything about the blockchain technology before using any dApp, which was required before and on top of this, they can perform any action on blockchain, however complex, in just one single tap and pay for that in the token of their choice, this eliminates the need to hold multiple blockchain native tokens which are only used to pay for the transaction fees.',
        media: 'https://detrade.one/images/detrade/stake-pool/bg.png',
      },
    ]
  }, [])

  const content = useMemo(() => {
    const ary = list.map((item, index) => {
      return (
        <Stack spacing={12} key={index}>
          <Text fw={600} fz={16} lh="24px" color="#0B132A">
            {index + 1}. {item.title}
          </Text>
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            {item.desc}
          </Text>
          {item.media && <Image width={550} height="auto" src={item.media} />}
        </Stack>
      )
    })
    const res = ary.reduce((acc: any, item, index) => {
      acc.push(item)
      if (index !== ary.length - 1) {
        acc.push(<Divider color="#BABDC480" />)
      }
      return acc
    }, [])
    return res
  }, [list])

  return (
    <>
      <Box
        w="100%"
        p={20}
        sx={({ colors }) => ({
          border: '1px solid #BABDC466',
          borderRadius: 16,
          overflow: 'auto',
        })}
      >
        <Group
          spacing={24}
          py={16}
          px={20}
          w="100%"
          sx={({ colors }) => ({
            borderRadius: 6,
            background: '#F7F7F7',
          })}
          align="top"
        >
          <Group spacing={8} noWrap>
            <Image width={28} height={28} radius={28} src={item.project_logo} />
            <Text fw={600} fz={18} lh="24px" color="#0B132A">
              {item.project_slug}
            </Text>
          </Group>
          <Divider orientation="vertical" color="#BABDC466" h={34} sx={{ alignSelf: 'center' }} />
          <Stack spacing={2}>
            <Text fw={400} fz={12} lh="16px" color="#959AA4">
              Mainnet Token Contract:
            </Text>
            <Group spacing={4} noWrap>
              <Text fw={500} fz={12} lh="16px" color="#0B132A">
                {shortenAddress(address)}
              </Text>
              <CopyButton value={address || ''} timeout={1000}>
                {({ copy, copied }) => (
                  <Tooltip label="Copied" withinPortal opened={copied}>
                    <ActionIcon
                      size={24}
                      onClick={() => {
                        copy()
                      }}
                    >
                      <PiCopyLight size={12} color="#141414" />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </Stack>

          <Stack spacing={2}>
            <Text fw={400} fz={12} lh="16px" color="#959AA4">
              Token Unlocks:
            </Text>
            <Text fw={500} fz={12} lh="24px" color="#45AF5C">
              {item.token_unlocks}
            </Text>
          </Stack>
        </Group>
        <Stack spacing={10} mt={16}>
          <Group position="apart">
            <div className={classes.label}>Total Allocation:</div>
            <div className={classes.value}>
              {mantissaNumber(item.total_allocation || 0, 8)} {item.currency}
            </div>
          </Group>
          <Group position="apart">
            <div className={classes.label}>Token Supply:</div>
            <div className={classes.value}>
              {mantissaNumber(item.token_supply || 0, 8)} {item.project_slug}
            </div>
          </Group>
          <Group position="apart">
            <div className={classes.label}>Initial FDV:</div>
            <div className={classes.value}>${mantissaNumber(item.initial_fdv || 0, 2)}</div>
          </Group>
          <Group position="apart">
            <div className={classes.label}>Initial Market Cap:</div>
            <div className={classes.value}>${mantissaNumber(item.initial_market_cap || 0, 2)}</div>
          </Group>
          <Group position="apart">
            <div className={classes.label}>Initial Circulating Supply:</div>
            <div className={classes.value}>
              {mantissaNumber(item.initial_circulating_supply || 0, 8)} {item.project_slug}
            </div>
          </Group>
        </Stack>
      </Box>

      <Box mt={40}>
        <Text fw={600} fz={24} lh="32px" color="#0B132A">
          🔥 Project
        </Text>
      </Box>

      <Stack
        mt={28}
        spacing={16}
        sx={({ colors }) => ({
          'h1, h2, h3, h4, h5': {
            margin: 0,
          },
          // img: {
          //   maxWidth: 550,
          //   width: '100%',
          // },
        })}
      >
        <ReactMarkdown
          source={item.doc_content || ''}
          renderers={{
            image: MarkdownImage,
          }}
        ></ReactMarkdown>
      </Stack>
    </>
  )
}
