import { DEFAULT_THEME, MantineProvider, MantineProviderProps, MantineThemeOverride } from '@asuikit/core'
import React, { useMemo } from 'react'
// import { dark as darkV1 } from '@/theme';
// import { dark, light } from '@/themeV2';

const StyleProvider: React.FC<
  MantineProviderProps & {
    v2?: boolean
    colorScheme?: string
    forceColorScheme?: 'dark' | 'light'
  }
> = ({ v2 = true, colorScheme = 'light', forceColorScheme, ...props }) => {
  const theme = useMemo(() => {
    // let finalColorScheme = setting.isDark ? 'dark' : 'light';
    // if (!isBrowser) {
    //   finalColorScheme = colorScheme === 'dark' ? 'dark' : 'light';
    // } else if (forceColorScheme) {
    //   finalColorScheme = forceColorScheme === 'dark' ? 'dark' : 'light';
    // }

    const green = DEFAULT_THEME.lightColors?.green.slice(0)
    if (green) {
      green[9] = '#D5F462'
    }
    const blue = DEFAULT_THEME.lightColors?.blue.slice(0)
    if (blue) {
      blue[9] = '#3C38F5'
    }
    const orange = DEFAULT_THEME.lightColors?.blue.slice(0)
    if (orange) {
      orange[9] = '#FE9839'
    }

    return {
      fontFamily: `Poppins, PingFang SC, 'Microsoft Yahei',simsun,sans-serif`,
      colorScheme: 'light',
      primaryColor: 'blue',
      lightColors: {
        green: [
          '#ecfff0',
          '#e9fff0',
          '#e6fff0',
          '#e3fff0',
          '#e0fff0',
          '#ddfec7',
          '#dbfbae',
          '#d9f994',
          '#d7f77b',
          '#d5f462',
        ],
        blue,
      },
    } as MantineThemeOverride
  }, [])

  return (
    <MantineProvider
      // theme={store.colorScheme === 'light' ? light : dark}
      {...props}
      theme={theme}
    />
  )
}

export default StyleProvider
