import { AppShell, Center, Container, Text } from '@asuikit/core'
import { ChainId, Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useScreenSize } from 'hooks/useScreenSize'
import { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isPreviewTrade } from 'state/routing/utils'
import { SwapAndLimitContextProvider, SwapContextProvider } from 'state/swap/SwapContext'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { CurrencyState, SwapAndLimitContext } from 'state/swap/types'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { SwapForm } from './SwapForm'

export function getIsReviewableQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  if (swapInputError) return false
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true

  return Boolean(trade && tradeState === TradeState.VALID)
}

export default function SwapPage({ className }: { className?: string }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const { chainId: connectedChainId } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  return (
    <AppShell
      padding={0}
      // footer={<Footer fixed={false} />}
      styles={(theme) => ({
        main: {
          background: 'linear-gradient(180deg, #FFFFFF 7.67%, #F7F7F7 100%)',
        },
      })}
    >
      <Container mt={0} px={smScreen ? 60 : 16} size={1440}>
        <Center>
          <Swap
            className={className}
            chainId={chainId}
            disableTokenInputs={supportedChainId === undefined}
            initialInputCurrency={initialInputCurrency}
            initialOutputCurrency={initialOutputCurrency}
            syncTabToUrl={true}
          />
        </Center>
        <Center mt={16}>
          <Text fw={400} fz={14} lh="20px" color="text.3">
            Bridge token to{' '}
            <Text
              span
              color="blue.9"
              underline
              onClick={() => {
                window.open('https://alienxchain.io/')
              }}
              sx={{ cursor: 'pointer' }}
            >
              AlienXChain
            </Text>
          </Text>
        </Center>
      </Container>
    </AppShell>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl,
}: {
  className?: string
  chainId?: ChainId
  onCurrencyChange?: (selected: CurrencyState) => void
  disableTokenInputs?: boolean
  initialInputCurrency?: Currency
  initialOutputCurrency?: Currency
  compact?: boolean
  syncTabToUrl: boolean
}) {
  const isDark = useIsDarkMode()
  const screenSize = useScreenSize()

  return (
    <SwapAndLimitContextProvider
      chainId={chainId}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
    >
      {/* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */}
      <SwapAndLimitContext.Consumer>
        {({ currentTab }) => (
          <SwapContextProvider>
            <SwapForm onCurrencyChange={onCurrencyChange} disableTokenInputs={disableTokenInputs} />
          </SwapContextProvider>
        )}
      </SwapAndLimitContext.Consumer>
    </SwapAndLimitContextProvider>
  )
}
