import { Button } from '@asuikit/core'
import { ButtonProps } from '@asuikit/core/lib/Button/Button'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { CHAIN_INFO, useIsSupportedChainId } from '../../constants/chains'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { Trans } from '../../i18n'
import { useAppSelector } from '../../state/hooks'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import { useToggleAccountDrawer } from '../AccountDrawer/MiniPortfolio/hooks'

const ButtonCheckChain: React.FC<ButtonProps & { chainId: ChainId; custom?: boolean }> = (props) => {
  const { chainId } = props
  const connectionReady = useConnectionReady()

  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const targetChain = switchingChain ? switchingChain : undefined
  const switchingChainIsSupported = useIsSupportedChainId(targetChain)

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()
  const isSupportedChain = useIsSupportedChainId(chainId)

  // return <Button {...props} />
  return (
    <>
      {switchingChain ? (
        <Button fullWidth={props.fullWidth} size={props.size} disabled={true}>
          <Trans>Connecting to {{ label: switchingChainIsSupported ? CHAIN_INFO[targetChain]?.label : '' }}</Trans>
        </Button>
      ) : connectionReady && !account ? (
        <Button fullWidth={props.fullWidth} size={props.size} onClick={toggleWalletDrawer}>
          <Trans>Connect wallet</Trans>
        </Button>
      ) : chainId && chainId !== connectedChainId ? (
        <Button
          fullWidth={props.fullWidth}
          size={props.size}
          onClick={async () => {
            try {
              await switchChain(connector, chainId)
            } catch (error) {
              if (didUserReject(error)) {
                // Ignore error, which keeps the user on the previous chain.
              } else {
                // TODO(WEB-3306): This UX could be improved to show an error state.
                throw error
              }
            }
          }}
        >
          Connect to {isSupportedChain ? CHAIN_INFO[chainId].label : ''}
        </Button>
      ) : (
        <>{props.custom ? <>{props.children}</> : <Button {...props} />}</>
      )}
    </>
  )
}

export default ButtonCheckChain
