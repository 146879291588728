import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { getContract } from 'utilities/src/contracts/getContract'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import AlienXTokenFactoryAbi from './AlienXTokenFactoryAbi.json'

import { useRequest } from 'ahooks'
import { parseUnits } from 'viem'
import { createTokenChainId, createTokenContractAddress } from './createTokenConst'

export function useCreateToken() {
  const { account, provider } = useWeb3React()
  const functionName = 'createToken'

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async ({
      name,
      symbol,
      maxSupply,
      tradeBurnRatio,
      tradeFeeRatio,
    }: {
      name: string
      symbol: string
      maxSupply: number | bigint | string
      tradeBurnRatio: number | bigint
      tradeFeeRatio: number | bigint
    }) => {
      if (!(await checkTargetChain(createTokenChainId))) return

      if (!account || !provider) return

      const createTokenContract = getContract(createTokenContractAddress, AlienXTokenFactoryAbi, provider, account)

      const txRes = await createTokenContract?.[functionName].apply(null, [
        name,
        symbol,
        maxSupply,
        // tradeBurnRatio,
        // tradeFeeRatio,
        // account,
        {
          value: parseUnits('0.002', 18),
        },
      ])
      return txRes
    },
    [account, checkTargetChain, provider]
  )
}

export function useTransferOwnership(contractAddress: string) {
  const { account, provider } = useWeb3React()
  const functionName = 'renounceOwnership'

  const checkTargetChain = useCheckTargetChain()
  return useCallback(async () => {
    if (!(await checkTargetChain(createTokenChainId))) return

    if (!account || !provider) return

    const createTokenContract = getContract(
      contractAddress,
      [
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      provider,
      account
    )

    const txRes = await createTokenContract?.[functionName].apply(null, [])
    return txRes
  }, [account, checkTargetChain, contractAddress, provider])
}

export function useContractOwner(contractAddress: string = '') {
  const { account, provider } = useWeb3React()
  const functionName = 'owner'

  const res = useRequest(
    async () => {
      if (!contractAddress || !provider || !account) return undefined
      const contract = getContract(
        contractAddress,
        [
          {
            inputs: [],
            name: 'owner',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        provider,
        account
      )
      const res = await contract?.[functionName].apply(null, [])
      return res
    },
    {
      pollingInterval: 10000,
      ready: !!contractAddress,
    }
  )
  return res
}

export function useDisplayERC20Token(chainId: number | string) {
  const checkTargetChain = useCheckTargetChain()

  return useCallback(
    async (params: { address: string; symbol?: string; decimals?: number; image?: string }) => {
      if (!(await checkTargetChain(Number(chainId)))) return

      if (!window.ethereum || !params.address || !params.symbol || !params.decimals) return
      await window.ethereum?.request?.({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: params,
        },
      })
    },
    [chainId, checkTargetChain]
  )
}
