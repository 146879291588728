import { InterfaceSectionName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, useTrace } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import { Field } from 'components/swap/constants'
import { useConnectionReady } from 'connection/eagerlyConnect'
import { CHAIN_INFO, useIsSupportedChainId } from 'constants/chains'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import { useMaxAmountIn } from 'hooks/useMaxAmountIn'
import useParsedQueryString from 'hooks/useParsedQueryString'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { SwapResult, useSwapCallback } from 'hooks/useSwapCallback'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useUSDPrice } from 'hooks/useUSDPrice'
import useWrapCallback, { WrapErrorText, WrapType } from 'hooks/useWrapCallback'
import { Trans } from 'i18n'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isClassicTrade } from 'state/routing/utils'
import {
  queryParametersToCurrencyState,
  useSwapActionHandlers,
  useSwapAndLimitContext,
  useSwapContext,
} from 'state/swap/hooks'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { maybeLogFirstSwapAction } from 'tracing/swapFlowLoggers'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { NumberType, useFormatter } from 'utils/formatNumbers'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { largerPercentValue } from 'utils/percent'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/prices'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import { Box, Button, Group, Stack, Text, createStyles } from '@asuikit/core'
import { useRequest } from 'ahooks'
import Error from 'components/Icons/Error'
import Row from 'components/Row'
import { useCurrencyInfo } from 'hooks/Tokens'
import { RiArrowDownLine } from 'react-icons/ri'
import { CurrencyState } from 'state/swap/types'
import { SafetyLevel } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { CurrencyInfo } from 'uniswap/src/features/dataApi/types'
import { getIsReviewableQuote } from '.'
import SlippageSetting from '../../components/detrade/pages/SwapForm/SlippageSetting'
import ReviewSwap from '../../components/detrade/pages/SwapForm/modals/ReviewSwap'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { SlippageTolerance } from '../../state/user/types'
import { OutputTaxTooltipBody } from './TaxTooltipBody'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      width: '100%',
      maxWidth: 646,
      minHeight: 640,
      background: colors.bg[1],
      border: '1px solid ' + colors.line[1],
      padding: '26px 40px',
      borderRadius: 24,
    },
    switch: {
      border: '5px solid #FFFFFF',
      background: '#F7F7F7',
      borderRadius: 14,
      position: 'absolute',
      width: 53,
      height: 53,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }
})

const SWAP_FORM_CURRENCY_SEARCH_FILTERS = {
  showCommonBases: true,
}

interface SwapFormProps {
  disableTokenInputs?: boolean
  onCurrencyChange?: (selected: CurrencyState) => void
}

export function SwapForm({ disableTokenInputs = false, onCurrencyChange }: SwapFormProps) {
  const { classes, cx } = useStyles()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const trace = useTrace()

  const { chainId, prefilledState, currencyState } = useSwapAndLimitContext()
  const isSupportedChain = useIsSupportedChainId(chainId)
  const { swapState, setSwapState, derivedSwapInfo } = useSwapContext()
  const { typedValue, independentField } = swapState

  // token warning stuff
  const parsedQs = useParsedQueryString()
  const prefilledCurrencies = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const prefilledInputCurrencyInfo = useCurrencyInfo(prefilledCurrencies?.inputCurrencyId, chainId)
  const prefilledOutputCurrencyInfo = useCurrencyInfo(prefilledCurrencies?.outputCurrencyId, chainId)

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const urlTokensNotInDefault = useMemo(
    () =>
      prefilledInputCurrencyInfo && prefilledOutputCurrencyInfo
        ? [prefilledInputCurrencyInfo, prefilledOutputCurrencyInfo]
            .filter((token: CurrencyInfo) => {
              return token.currency.isToken && token.safetyLevel !== SafetyLevel.Verified
            })
            .map((token: CurrencyInfo) => token.currency as Token)
        : [],
    [prefilledInputCurrencyInfo, prefilledOutputCurrencyInfo]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const {
    trade: { state: tradeState, trade, swapQuoteLatency },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    outputFeeFiatValue,
    inputTax,
    outputTax,
  } = derivedSwapInfo
  const [inputTokenHasTax, outputTokenHasTax] = useMemo(
    () => [!inputTax.equalTo(0), !outputTax.equalTo(0)],
    [inputTax, outputTax]
  )

  useEffect(() => {
    // Force exact input if the user switches to an output token with tax
    if (outputTokenHasTax && independentField === Field.OUTPUT) {
      setSwapState((state) => ({
        ...state,
        independentField: Field.INPUT,
        typedValue: '',
      }))
    }
  }, [independentField, outputTokenHasTax, setSwapState, trade?.outputAmount])

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])
  const getSingleUnitAmount = (currency?: Currency) => {
    if (!currency) return
    return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(10 ** currency.decimals))
  }

  const fiatValueInput = useUSDPrice(
    parsedAmounts[Field.INPUT] ?? getSingleUnitAmount(currencies[Field.INPUT]),
    currencies[Field.INPUT]
  )
  const fiatValueOutput = useUSDPrice(
    parsedAmounts[Field.OUTPUT] ?? getSingleUnitAmount(currencies[Field.OUTPUT]),
    currencies[Field.OUTPUT]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      tradeState === TradeState.NO_ROUTE_FOUND,
      tradeState === TradeState.LOADING,
      tradeState === TradeState.LOADING && Boolean(trade),
    ],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const preTaxFiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const [stablecoinPriceImpact, preTaxStablecoinPriceImpact] = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade) || showWrap
        ? [undefined, undefined]
        : [
            computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
            computeFiatValuePriceImpact(fiatValueTradeInput.data, preTaxFiatValueTradeOutput.data),
          ],
    [fiatValueTradeInput, fiatValueTradeOutput, preTaxFiatValueTradeOutput, routeIsSyncing, trade, showWrap]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, swapResult }, setSwapFormState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: InterfaceTrade
    swapError?: Error
    swapResult?: SwapResult
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    swapResult: undefined,
  })

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState?.inputCurrency &&
      !prefilledState.inputCurrency?.equals(previousPrefilledState.inputCurrency)
    const prefilledOutputChanged =
      previousPrefilledState?.outputCurrency &&
      !prefilledState?.outputCurrency?.equals(previousPrefilledState.outputCurrency)

    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      // reset local state
      setSwapFormState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        swapResult: undefined,
      })
    }
  }, [
    connectedChainId,
    prefilledState.inputCurrency,
    prefilledState?.outputCurrency,
    previousConnectedChainId,
    previousPrefilledState,
  ])

  const { formatCurrencyAmount } = useFormatter()
  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount({
            amount: parsedAmounts[dependentField],
            type: NumberType.SwapTradeAmount,
            placeholder: '',
          }),
    }),
    [dependentField, formatCurrencyAmount, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage)
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined,
    trade?.fillType
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data, feeUsd: outputFeeFiatValue }
  }, [fiatValueTradeInput.data, fiatValueTradeOutput.data, outputFeeFiatValue])

  // the callback to execute the swap
  const swapCallback = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleContinueToReview = useCallback(() => {
    setSwapFormState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      swapResult: undefined,
    })
  }, [trade])

  const clearSwapState = useCallback(() => {
    setSwapFormState((currentState) => ({
      ...currentState,
      swapError: undefined,
      swapResult: undefined,
    }))
  }, [])

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (preTaxStablecoinPriceImpact && !confirmPriceImpactWithoutFee(preTaxStablecoinPriceImpact)) {
      return
    }
    swapCallback()
      .then((result) => {
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: undefined,
          swapResult: result,
        }))
      })
      .catch((error) => {
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: error,
          swapResult: undefined,
        }))
      })
  }, [swapCallback, preTaxStablecoinPriceImpact])

  const { runAsync: handleOnWrap, loading: loadingOnWrap } = useRequest(
    async () => {
      if (!onWrap) return
      try {
        const txHash = await onWrap()
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash,
        }))
        onUserInput(Field.INPUT, '')
      } catch (error) {
        if (!didUserReject(error)) {
          sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
            wrapType,
            input: currencies[Field.INPUT],
            output: currencies[Field.OUTPUT],
          })
        }
        console.error('Could not wrap/unwrap', error)
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
      }
    },
    {
      manual: true,
    }
  )

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    if (!isClassicTrade(trade)) {
      return { priceImpactSeverity: 0, largerPriceImpact: undefined }
    }

    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, preTaxStablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [preTaxStablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapFormState((currentState) => ({ ...currentState, showConfirm: false }))
    // If there was a swap, we want to clear the input
    if (swapResult) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, swapResult])

  const handleAcceptChanges = useCallback(() => {
    setSwapFormState((currentState) => ({ ...currentState, tradeToConfirm: trade }))
  }, [trade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        inputCurrency,
        outputCurrency: currencyState.outputCurrency,
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, currencyState, trace]
  )
  const inputCurrencyNumericalInputRef = useRef<HTMLInputElement>(null)

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    maybeLogFirstSwapAction(trace)
  }, [maxInputAmount, onUserInput, trace])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        inputCurrency: currencyState.inputCurrency,
        outputCurrency,
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, currencyState, trace]
  )

  const showPriceImpactWarning = isClassicTrade(trade) && largerPriceImpact && priceImpactSeverity > 3

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatSwapQuoteReceivedEventProperties(trade, allowedSlippage, swapQuoteLatency, outputFeeFiatValue),
      ...trace,
    })
  }, [prevTrade, trade, trace, allowedSlippage, swapQuoteLatency, outputFeeFiatValue])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const inputCurrency = currencies[Field.INPUT] ?? undefined
  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const targetChain = switchingChain ? switchingChain : undefined
  const switchingChainIsSupported = useIsSupportedChainId(targetChain)

  const [showBannedExtension, setShowBannedExtension] = useState<boolean>(false)
  useEffect(() => {
    const isBannedExtension = async () => {
      let blocked = false
      const webAccessibleResources = [
        'chrome-extension://gacgndbocaddlemdiaadajmlggabdeod/ccip.08a1ffae.js', // pocket universe extension
      ]
      for (let i = 0; i < webAccessibleResources.length; i++) {
        if (blocked) {
          break
        }
        try {
          await fetch(webAccessibleResources[i])
          // if anything comes back, then the extension is active
          blocked = true
        } catch (error) {
          console.error(error)
        }
      }
      setShowBannedExtension(blocked)
    }
    if (window.chrome) isBannedExtension().catch(console.error)
  }, [])

  const {
    derivedSwapInfo: { trade: autoSlippageTrade, autoSlippage },
  } = useSwapContext()

  const { formatPercent } = useFormatter()
  const [userSlippageTolerance] = useUserSlippageTolerance()

  const hasSlippageWarning = useMemo(() => {
    return userSlippageTolerance !== SlippageTolerance.Auto && Number(userSlippageTolerance.toFixed(3)) > 5
  }, [userSlippageTolerance])

  return (
    <>
      {/*<TokenSafetyModal*/}
      {/*  isOpen={urlTokensNotInDefault.length > 0 && !dismissTokenWarning}*/}
      {/*  tokenAddress={urlTokensNotInDefault[0]?.address}*/}
      {/*  secondTokenAddress={urlTokensNotInDefault[1]?.address}*/}
      {/*  onContinue={handleConfirmTokenWarning}*/}
      {/*  onCancel={handleDismissTokenWarning}*/}
      {/*  showCancel={true}*/}
      {/*/>*/}
      {trade && (
        <ReviewSwap
          trade={trade}
          priceImpact={largerPriceImpact}
          inputCurrency={inputCurrency}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          onCurrencySelection={onCurrencySelection}
          swapResult={swapResult}
          allowedSlippage={allowedSlippage}
          clearSwapState={clearSwapState}
          onConfirm={handleSwap}
          allowance={allowance}
          swapError={swapError}
          fiatValueInput={fiatValueTradeInput}
          fiatValueOutput={fiatValueTradeOutput}
          opened={showConfirm}
          onClose={handleConfirmDismiss}
        />
      )}
      {showPriceImpactModal && showPriceImpactWarning && (
        <PriceImpactModal
          priceImpact={largerPriceImpact}
          onDismiss={() => setShowPriceImpactModal(false)}
          onContinue={() => {
            setShowPriceImpactModal(false)
            handleContinueToReview()
          }}
        />
      )}

      <div className={classes.root}>
        <Group position="apart">
          <Text fw={500} fz={20} lh="24px" color="#0B132A">
            Swap
          </Text>
          <SlippageSetting autoSlippage={autoSlippage} chainId={chainId} trade={autoSlippageTrade?.trade} />
        </Group>

        <Stack spacing={6} pt={18} pb={20}>
          <Stack spacing={6} sx={{ position: 'relative' }}>
            <SwapCurrencyInputPanel
              label={<Trans>You pay</Trans>}
              disabled={disableTokenInputs}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={showMaxButton}
              currency={currencies[Field.INPUT] ?? null}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              fiatValue={showFiatValueInput ? fiatValueInput : undefined}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              currencySearchFilters={SWAP_FORM_CURRENCY_SEARCH_FILTERS}
              id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
              loading={independentField === Field.OUTPUT && routeIsSyncing}
              ref={inputCurrencyNumericalInputRef}
            />

            <Group className={classes.switch} position="center">
              <Box
                onClick={() => {
                  if (disableTokenInputs) return
                  onSwitchTokens({
                    newOutputHasTax: inputTokenHasTax,
                    previouslyEstimatedOutput: formattedAmounts[dependentField],
                  })
                  maybeLogFirstSwapAction(trace)
                }}
                sx={{ cursor: 'pointer' }}
              >
                <RiArrowDownLine size={24} color="#09121F" />
              </Box>
            </Group>

            <SwapCurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              disabled={disableTokenInputs}
              onUserInput={handleTypeOutput}
              label={<Trans>You receive</Trans>}
              showMaxButton={false}
              hideBalance={false}
              fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
              priceImpact={stablecoinPriceImpact}
              currency={currencies[Field.OUTPUT] ?? null}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              currencySearchFilters={SWAP_FORM_CURRENCY_SEARCH_FILTERS}
              id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
              loading={independentField === Field.INPUT && routeIsSyncing}
              numericalInputSettings={{
                // We disable numerical input here if the selected token has tax, since we cannot guarantee exact_outputs for FOT tokens
                disabled: inputTokenHasTax || outputTokenHasTax,
                // Focus the input currency panel if the user tries to type into the disabled output currency panel
                onDisabledClick: () => inputCurrencyNumericalInputRef.current?.focus(),
                disabledTooltipBody: (
                  <OutputTaxTooltipBody
                    currencySymbol={currencies[inputTokenHasTax ? Field.INPUT : Field.OUTPUT]?.symbol}
                  />
                ),
              }}
            />
          </Stack>
          {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
        </Stack>

        <div>
          {swapIsUnsupported ? (
            <Button fullWidth size="xl" disabled={true}>
              <Trans>Unsupported asset</Trans>
            </Button>
          ) : switchingChain ? (
            <Button fullWidth size="xl" disabled={true}>
              <Trans>Connecting to {{ label: switchingChainIsSupported ? CHAIN_INFO[targetChain]?.label : '' }}</Trans>
            </Button>
          ) : connectionReady && !account ? (
            <Button fullWidth size="xl" onClick={toggleWalletDrawer}>
              <Trans>Connect wallet</Trans>
            </Button>
          ) : chainId && chainId !== connectedChainId ? (
            <Button
              fullWidth
              size="xl"
              onClick={async () => {
                try {
                  await switchChain(connector, chainId)
                } catch (error) {
                  if (didUserReject(error)) {
                    // Ignore error, which keeps the user on the previous chain.
                  } else {
                    // TODO(WEB-3306): This UX could be improved to show an error state.
                    throw error
                  }
                }
              }}
            >
              Connect to {isSupportedChain ? CHAIN_INFO[chainId].label : ''}
            </Button>
          ) : showWrap ? (
            <Button
              fullWidth
              size="xl"
              loading={loadingOnWrap}
              disabled={Boolean(wrapInputError)}
              onClick={handleOnWrap}
            >
              {wrapInputError ? (
                <WrapErrorText wrapInputError={wrapInputError} />
              ) : wrapType === WrapType.WRAP ? (
                <Trans>Wrap</Trans>
              ) : wrapType === WrapType.UNWRAP ? (
                <Trans>Unwrap</Trans>
              ) : null}
            </Button>
          ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
            <Button fullWidth size="xl" disabled={true}>
              <Trans>Insufficient liquidity for this trade.</Trans>
            </Button>
          ) : (
            <Button
              onClick={() => {
                showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
              }}
              fullWidth
              size="xl"
              disabled={showBannedExtension || !getIsReviewableQuote(trade, tradeState, swapInputError)}
              color={swapInputError || priceImpactSeverity > 2 || hasSlippageWarning ? 'orange.9' : 'blue.9'}
              loading={trade && (routeIsSyncing || routeIsLoading)}
              loaderProps={{ color: 'blue.9' }}
            >
              <Text>
                {swapInputError ? (
                  swapInputError
                ) : routeIsSyncing || routeIsLoading ? (
                  <Trans>Swap</Trans>
                ) : priceImpactSeverity > 2 || hasSlippageWarning ? (
                  <Trans>Swap anyway</Trans>
                ) : (
                  <Trans>Swap</Trans>
                )}
              </Text>
            </Button>
          )}

          {showDetailsDropdown && (
            <Box mt={28}>
              <SwapDetailsDropdown
                trade={trade}
                syncing={routeIsSyncing}
                loading={routeIsLoading}
                allowedSlippage={allowedSlippage}
                priceImpact={largerPriceImpact}
              />
            </Box>
          )}
          {showBannedExtension && <SwapNotice />}
        </div>
      </div>
    </>
  )
}

function SwapNotice() {
  const theme = useTheme()
  return (
    <Row
      align="flex-start"
      gap="md"
      backgroundColor={theme.surface2}
      marginTop="12px"
      borderRadius="12px"
      padding="16px"
    >
      <Row width="auto" borderRadius="16px" backgroundColor={theme.critical2} padding="8px">
        <Error />
      </Row>

      <Column flex="10" gap="sm">
        <ThemedText.SubHeader>Blocked Extension</ThemedText.SubHeader>
        <ThemedText.BodySecondary lineHeight="22px">
          <Trans>
            The Pocket Universe extension violates our Terms&nbsp;of&nbsp;Service by modifying our product in a way that
            is misleading and could harm users. Please disable the extension and reload&nbsp;the&nbsp;page.
          </Trans>
        </ThemedText.BodySecondary>
      </Column>
    </Row>
  )
}
