import { Box, Button, createStyles, Divider, Group, Stack, Text } from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useDisclosure } from '@asuikit/hooks'
import dayjs from 'dayjs'
import { ProjectModel } from 'service/launch.api.d'
import { formatUnits } from 'viem'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import ButtonCheckChain from '../../components/detrade/ButtonCheckChain'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { mantissaNumber } from '../../utils/detrade/number'
import LaunchClaimToken from './LaunchClaimToken'
import { LaunchChainId } from './launchConst'
import LaunchDeposit from './LaunchDeposit'
import LaunchRefund from './LaunchRefund'
import LaunchRules from './LaunchRules'
import { useGetLaunchDepositAmount, useLaunchTotalDeposit } from './useLaunchContract'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    label: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#0B132A',
    },
  }
})
export default function LaunchPublicSale({ item, loading = true }: { item: ProjectModel; loading: boolean }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, provider, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)
  const { launchId } = useParams<{
    launchId?: string
  }>()

  const { classes, cx } = useStyles()
  const address = useMemo(() => {
    return '0x59bE2846C5bB241e6e8479AcE1480A41822937b8'
  }, [])

  const list = useMemo(() => {
    return [
      {
        title: 'Allocation',
        desc: (
          <Text fw={400} fz={14} lh="20px" color="#4F5665">
            Deposit-Based Allocation: Your token allocation is based on the amount you deposit. A higher deposit results
            in a larger allocation.
            <Text fw={600} fz={14} lh="20px" mt={10}>
              Scenario 1: Public Sale Total Deposits ≤ Public Sale Allocation:
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                If the public sale total deposits from all users do not exceed the public sale allocation, your entire
                deposit will be used to purchase tokens.
              </li>
              <li>Your public sale token allocation = your deposit.</li>
            </ul>
            <Text fw={600} fz={14} lh="20px" mt={10}>
              Scenario 2: Public Sale Total Deposits {'>'} Public Sale Allocation:
            </Text>
            <ul
              style={{
                margin: 5,
                paddingLeft: 10,
              }}
            >
              <li>
                {`If the public sale total deposits from all users exceed the public sale allocation, each user's deposit will be partially used, and the remainder will be refunded.`}
              </li>
              <li>Your public sale token allocation = (your deposit / total deposits) * public sale allocation.</li>
              <li>Refund = your deposit - (your public sale token allocation * public sale price).</li>
            </ul>
          </Text>
        ),
        media: '',
      },
      {
        title: 'Price',
        desc: 'Your token purchase = Your public sale token allocation / public sale price.',
        media: '',
      },
      {
        title: 'Distribution',
        desc: 'You will receive your purchased tokens and any applicable refund once the purchase event concludes.',
        media: '',
      },
    ]
  }, [])

  const startTime = item.project_start_time
  const endTime = item.project_end_time
  const isActiveEnd = useMemo(() => {
    return dayjs().isAfter(endTime)
  }, [endTime])
  const canDeposit = useMemo(() => {
    return !isActiveEnd
  }, [isActiveEnd])

  const contractAddress = item.lpd_contract_address
  const paymentTokenContractAddress = item.payment_token_address
  // const paymentTokenContractAddress = '0x52D512B297F3CF6158B22ec0AD4ca6a47eE67bEd'
  // const paymentTokenDecimal = 6
  const paymentTokenDecimal = item.payment_token_decimals
  const {
    data,
    loading: depositAmountLoading,
    refresh: refreshLaunchDepositAmount,
  } = useGetLaunchDepositAmount(contractAddress, 1)
  const depositAmount = useMemo(() => {
    return formatUnits(data || 0, paymentTokenDecimal)
  }, [data, paymentTokenDecimal])
  const { data: dataTotalDeposit, loading: loadingTotalDeposit } = useLaunchTotalDeposit(contractAddress, 1)
  const totalDepositAmount = useMemo(() => {
    return formatUnits(dataTotalDeposit || 0, paymentTokenDecimal)
  }, [dataTotalDeposit, paymentTokenDecimal])

  const subscribed = useMemo(() => {
    if (!totalDepositAmount || !item.public_sale_allocation) return 0
    if (item.public_sale_allocation >= totalDepositAmount) return 0
    const res = Number(totalDepositAmount) / Number(item.public_sale_allocation)
    return res
  }, [item.public_sale_allocation, totalDepositAmount])

  const estimated = useMemo(() => {
    if (!depositAmount || !totalDepositAmount || !item.public_sale_allocation || !item.public_sale_price) return 0

    return (
      (Number(depositAmount) / Math.max(Number(totalDepositAmount), Number(item.public_sale_allocation))) *
      (Number(item.public_sale_allocation) / Number(item.public_sale_price))
    )
  }, [depositAmount, item.public_sale_allocation, item.public_sale_price, totalDepositAmount])

  return (
    <>
      <Box
        w="100%"
        p={20}
        sx={({ colors }) => ({
          border: '1px solid #BABDC466',
          borderRadius: 16,
        })}
      >
        <Group
          spacing={6}
          p={12}
          sx={({ colors }) => ({
            borderRadius: 6,
            background: '#F7F7F7',
          })}
        >
          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <div className={classes.value}>
              {mantissaNumber(item.public_sale_allocation || 0, 8)} {item.currency}
            </div>
            <div className={classes.label}>Public Sale Allocation</div>
          </Stack>
          <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <Group spacing={6} position="center">
              <div className={classes.value}>
                {mantissaNumber(totalDepositAmount || 0, 8)} {item.currency}
              </div>
              {subscribed > 0 && (
                <Box
                  sx={({ colors }) => ({
                    padding: '2px 6px',
                    background: '#FFF0E1',
                    borderRadius: 16,
                  })}
                >
                  <Text fw={500} fz={12} lh="14px" color="#FE9839">
                    {mantissaNumber(subscribed, 2)}x subscribed
                  </Text>
                </Box>
              )}
            </Group>
            <div className={classes.label}>Public Sale Total Deposit</div>
          </Stack>
          <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

          <Stack align="center" spacing={4} sx={{ flex: 1 }}>
            <Text className={classes.value} sx={{ color: '#45AF5C' }}>
              {mantissaNumber(item.public_sale_price || 0, 8)} {item.currency}
            </Text>
            <div className={classes.label}>Public Sale Price</div>
          </Stack>
        </Group>
        <Stack spacing={32} mt={20}>
          <Text fw={600} fz={18} lh="24px" color="#0B132A">
            My Public Sale Info
          </Text>

          <Stack spacing={20} w="100%">
            <Group spacing={10}>
              <Stack align="center" spacing={7} sx={{ flex: '1 0' }}>
                <div className={classes.label}>My Deposit</div>
                <Text fw={700} fz={24} lh="28px" color="#45AF5C">
                  {mantissaNumber(depositAmount || 0, 8)} {item.currency}
                </Text>
              </Stack>

              <Divider orientation="vertical" color="#BABDC466" h={40} sx={{ alignSelf: 'center' }} />

              <Stack align="center" spacing={7} sx={{ flex: '1 0' }}>
                <div className={classes.label}>My Estimated {item.project_slug} Allocation</div>

                <Text fw={700} fz={24} lh="28px" color="#45AF5C">
                  {mantissaNumber(estimated || 0, 8)} {item.project_slug} (
                  {mantissaNumber(
                    ((estimated || 0) / item.public_sale_allocation) * Number(item.public_sale_price) * 100,
                    2
                  )}
                  %)
                </Text>
              </Stack>
            </Group>

            <LaunchRefund
              item={item}
              saleType={1}
              contractAddress={contractAddress}
              paymentTokenDecimal={paymentTokenDecimal}
            />
          </Stack>

          {canDeposit ? (
            <ButtonCheckChain size="xl" fullWidth chainId={LaunchChainId} disabled={!canDeposit} custom>
              <LaunchDeposit
                item={item}
                saleType={1}
                contractAddress={contractAddress}
                paymentTokenContractAddress={paymentTokenContractAddress}
                paymentTokenDecimal={paymentTokenDecimal}
                onRefresh={() => {
                  refreshLaunchDepositAmount()
                }}
              />
            </ButtonCheckChain>
          ) : (
            <>
              {isActiveEnd ? (
                <></>
              ) : (
                <Button size="xl" fullWidth disabled={!canDeposit}>
                  Coming soon
                </Button>
              )}
            </>
          )}

          <LaunchClaimToken
            item={item}
            saleType={1}
            contractAddress={contractAddress}
            paymentTokenDecimal={paymentTokenDecimal}
          />
        </Stack>
      </Box>

      <Box mt={40}>
        <Text fw={600} fz={24} lh="32px" color="#0B132A">
          📚 Private sale rule
        </Text>
      </Box>

      <Stack mt={28} spacing={16}>
        <LaunchRules list={list} />
      </Stack>
    </>
  )
}
