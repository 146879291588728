import { Button } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { ProjectModel } from 'service/launch.api.d'
import { formatUnits } from 'viem'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { useGetLaunchClaimTokenData, useLaunchClaimToken, useLaunchTokenDecimals } from './useLaunchContract'

export default function LaunchClaimToken({
  item,
  saleType,
  contractAddress,
  paymentTokenDecimal,
}: {
  item: ProjectModel
  saleType: 0 | 1
  contractAddress: string
  paymentTokenDecimal: number
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const { data: decimals } = useLaunchTokenDecimals(item.project_contract_address)
  const {
    data: launchClaimAmountData = {},
    loading: launchRefundAmountLoading,
    refresh,
  } = useGetLaunchClaimTokenData(contractAddress, saleType)
  const { expectedToken = 0, claimedToken = 0 } = launchClaimAmountData

  const addTransaction = useTransactionAdder()
  const claimToken = useLaunchClaimToken(contractAddress, saleType)
  const { run, loading } = useRequest(
    async () => {
      try {
        const response = await claimToken()
        if (!response) return
        const transactionInfo: TransactionInfo = {
          type: TransactionType.LAUNCH_CLAIM_TOKEN,
          data: {
            detail: item,
            amount: decimals ? formatUnits(expectedToken, decimals) : '--',
          },
        }
        addTransaction(response, transactionInfo)
        await sleep(1000)
        await refresh()
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )
  const distributionTime = item.project_distribution_time
  const isShowRefundAndClaim = useMemo(() => {
    return dayjs().isAfter(distributionTime)
  }, [distributionTime])
  if (expectedToken <= 0 || !isShowRefundAndClaim) return null
  return (
    <>
      <Button
        size="xl"
        fullWidth
        sx={{
          background: '#45AF5C',
          '&:hover': {
            background: 'rgb(70,191,96)',
          },
        }}
        disabled={claimedToken >= expectedToken}
        loading={loading}
        onClick={run}
      >
        Claim {item.project_slug}
      </Button>
    </>
  )
}
