import { t } from 'i18n'
import { useAtom } from 'jotai'
import { lazy, ReactNode, useMemo } from 'react'
import { matchPath, Navigate, useLocation } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
import { SpinnerSVG } from 'theme/components'
import { isBrowserRouterEnabled } from 'utils/env'

// High-traffic pages (index and /swap) should not be lazy-loaded.
import CreateToken from './CreateToken'
import Landing from './Landing'
import Launch from './Launch'
import LaunchDetail from './Launch/detail'
import StakePool from './StakePool'
import Swap from './Swap'

const AddLiquidityWithTokenRedirects = lazy(() => import('pages/AddLiquidity/redirects'))

const NotFound = lazy(() => import('pages/NotFound'))
const PositionPage = lazy(() => import('pages/Pool/PositionPage'))

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
)

interface RouterConfig {
  browserRouterEnabled?: boolean
  hash?: string
  shouldDisableNFTRoutes?: boolean
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled()
  const { hash } = useLocation()
  const [shouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom)
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      shouldDisableNFTRoutes: Boolean(shouldDisableNFTRoutes),
    }),
    [browserRouterEnabled, hash, shouldDisableNFTRoutes]
  )
}

export interface RouteDefinition {
  path: string
  nestedPaths: string[]
  getTitle: (path?: string) => string
  enabled: (args: RouterConfig) => boolean
  getElement: (args: RouterConfig) => ReactNode
}

// Assigns the defaults to the route definition.
function createRouteDefinition(route: Partial<RouteDefinition>): RouteDefinition {
  return {
    getElement: () => null,
    getTitle: () => 'Detrade Interface',
    enabled: () => true,
    path: '/',
    nestedPaths: [],
    // overwrite the defaults
    ...route,
  }
}

const SwapTitle = t`Buy, sell & trade Ethereum and other top tokens on Detrade`

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: '/',
    getTitle: () => t`Detrade | Trade crypto & NFTs safely on the top DeFi exchange`,
    getElement: (args) => {
      return args.browserRouterEnabled && args.hash ? <Navigate to={args.hash.replace('#', '')} replace /> : <Landing />
    },
  }),
  // createRouteDefinition({
  //   path: '/explore',
  //   getTitle: getExploreTitle,
  //   nestedPaths: [':tab', ':chainName', ':tab/:chainName'],
  //   getElement: () => <RedirectExplore />,
  // }),
  // createRouteDefinition({
  //   path: '/explore/tokens/:chainName/:tokenAddress',
  //   getTitle: () => t`Buy & sell on Detrade`,
  //   getElement: () => <TokenDetails />,
  // }),
  // createRouteDefinition({
  //   path: '/tokens',
  //   getTitle: getDefaultTokensTitle,
  //   getElement: () => <Navigate to="/explore/tokens" replace />,
  // }),
  // createRouteDefinition({
  //   path: '/tokens/:chainName',
  //   getTitle: getDefaultTokensTitle,
  //   getElement: () => <RedirectExplore />,
  // }),
  // createRouteDefinition({
  //   path: '/tokens/:chainName/:tokenAddress',
  //   getTitle: getDefaultTokensTitle,
  //   getElement: () => <RedirectExplore />,
  // }),
  // createRouteDefinition({
  //   path: '/explore/pools/:chainName/:poolAddress',
  //   getTitle: () => t`Explore pools on Detrade`,
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <PoolDetails />
  //     </Suspense>
  //   ),
  // }),
  // createRouteDefinition({
  //   path: '/vote/*',
  //   getTitle: () => t`Vote on governance proposals on Detrade`,
  //   getElement: () => (
  //     <Suspense fallback={<LazyLoadSpinner />}>
  //       <Vote />
  //     </Suspense>
  //   ),
  // }),
  // createRouteDefinition({
  //   path: '/create-proposal',
  //   getTitle: () => t`Create a new governance proposal on Detrade`,
  //   getElement: () => <Navigate to="/vote/create-proposal" replace />,
  // }),
  // createRouteDefinition({
  //   path: '/send',
  //   getElement: () => <Swap />,
  //   getTitle: () => t`Send tokens on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/limits',
  //   getElement: () => <Navigate to="/limit" replace />,
  // }),
  // createRouteDefinition({
  //   path: '/limit',
  //   getElement: () => <Swap />,
  //   getTitle: () => SwapTitle,
  // }),
  // createRouteDefinition({
  //   path: '/pool/v2/find',
  //   getElement: () => <PoolFinder />,
  //   getTitle: () => t`Explore top liquidity pools (v2) on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/pool/v2',
  //   getElement: () => <PoolV2 />,
  //   getTitle: () => t`Provide liquidity to pools (v2) on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/pool',
  //   getElement: () => <Pool />,
  //   getTitle: () => t`Manage & provide pool liquidity on Detrade`,
  // }),
  createRouteDefinition({
    path: '/pool/:tokenId',
    getElement: () => <PositionPage />,
    getTitle: () => t`Manage pool liquidity on Detrade`,
  }),
  // createRouteDefinition({
  //   path: '/pools/v2/find',
  //   getElement: () => <PoolFinder />,
  //   getTitle: () => t`Explore top liquidity pools (v2) on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/pools/v2',
  //   getElement: () => <PoolV2 />,
  //   getTitle: () => t`Manage & provide v2 pool liquidity on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/pools',
  //   getElement: () => <Pool />,
  //   getTitle: () => t`Manage & provide pool liquidity on Detrade`,
  // }),
  createRouteDefinition({
    path: '/pools/:tokenId',
    getElement: () => <PositionPage />,
    getTitle: () => t`Manage pool liquidity on Detrade`,
  }),
  // createRouteDefinition({
  //   path: '/add/v2',
  //   nestedPaths: [':currencyIdA', ':currencyIdA/:currencyIdB'],
  //   getElement: () => <AddLiquidityV2WithTokenRedirects />,
  //   getTitle: () => t`Provide liquidity to pools (v2) on Detrade`,
  // }),
  createRouteDefinition({
    path: '/add',
    nestedPaths: [
      ':currencyIdA',
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:feeAmount',
      ':currencyIdA/:currencyIdB/:feeAmount/:tokenId',
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
    getTitle: () => t`Provide liquidity to pools on Detrade`,
  }),
  // createRouteDefinition({
  //   path: '/remove/v2/:currencyIdA/:currencyIdB',
  //   getElement: () => <RemoveLiquidity />,
  //   getTitle: () => t`Manage v2 pool liquidity on Detrade`,
  // }),
  // createRouteDefinition({
  //   path: '/remove/:tokenId',
  //   getElement: () => <RemoveLiquidityV3 />,
  //   getTitle: () => t`Manage pool liquidity on Detrade`,
  // }),

  createRouteDefinition({
    path: '/swap',
    getElement: () => <Swap />,
    getTitle: () => SwapTitle,
  }),
  createRouteDefinition({
    path: '/pool-list',
    getElement: () => <StakePool />,
    getTitle: () => SwapTitle,
  }),
  createRouteDefinition({
    path: '/launch/:launchId',
    getElement: () => <LaunchDetail />,
    getTitle: () => SwapTitle,
  }),
  createRouteDefinition({
    path: '/launch',
    getElement: () => <Launch />,
    getTitle: () => SwapTitle,
  }),
  createRouteDefinition({
    path: '/create-token',
    getElement: () => <CreateToken />,
    getTitle: () => SwapTitle,
  }),
  createRouteDefinition({ path: '*', getElement: () => <Navigate to="/not-found" replace /> }),
  createRouteDefinition({ path: '/not-found', getElement: () => <NotFound /> }),
]

export const findRouteByPath = (pathname: string) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname)
    if (match) {
      return route
    }
    const subPaths = route.nestedPaths.map((nestedPath) => `${route.path}/${nestedPath}`)
    for (const subPath of subPaths) {
      const match = matchPath(subPath, pathname)
      if (match) {
        return route
      }
    }
  }
  return undefined
}
