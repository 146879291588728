import React, { CSSProperties, memo } from 'react'
import { STATIC_BASEURL } from '../../constants/detrade'

interface IProps {
  color?: string
  className?: string
  img?: string
  style?: CSSProperties
}

const IconNoData: React.FC<IProps> = ({ color = '#ffffff', className, img, style }) => {
  return <img src={img || `${STATIC_BASEURL}/images/detrade/no-data.svg`} alt="" className={className} style={style} />
}

export default memo(IconNoData)
