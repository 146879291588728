import API from './index'
import {
  ConfigData,
  Data,
  LPRewardSignModel,
  LPStakeRewardModel,
  MyPoolsListModelData,
  PoolListModelData,
  PoolsRewardModel,
  PopTokenModel,
  TokenModel,
} from './pools.api.d'

//   Popular Tokens
//   GET /popular_tokens
//   接口ID：184705751
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705751
export async function getPopularTokens(params: { chain: string }): Promise<PopTokenModel[]> {
  return API.get('/popular_tokens', {
    params,
  })
}

//   Token Search
//   GET /token/search
//   接口ID：184705752
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705752
export async function getTokenSearch(params: {
  /**
   * chain name
   */
  chain: string
  /**
   * Search keywords
   */
  q: string
  [property: string]: any
}): Promise<TokenModel[]> {
  return API.get('/token/search', {
    params,
  })
}

//   Detrade Header
//   GET /pools/header
//   接口ID：184705753
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705753
export async function getPoolsHeader(params: {
  /**
   * chain name
   */
  chain: string
  [property: string]: any
}): Promise<Data> {
  return API.get('/pools/header', {
    params,
  })
}

//   Pools List
//   GET /pools/list
//   接口ID：184705754
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705754
export async function getPoolsList(params: {
  chain: string
  page?: number
  pageSize?: number
  sortBy?: string
  sortDirection?: string
}): Promise<PoolListModelData> {
  return API.get('/pools/list', {
    params,
  })
}

//   Query Pool Stake Reward Info
//   GET /pools/stake/reward/info
//   接口ID：184705755
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705755
export async function getStakeRewardInfo(params: { chain: string; pool_address: string }): Promise<PoolsRewardModel[]> {
  return API.get('/pools/stake/reward/info', {
    params,
  })
}

//   My Pools List
//   GET /my_pools/list
//   接口ID：184705756
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705756
export async function getMyPoolsList(params: {
  address: string

  chain: string

  page?: number

  pageSize?: number
}): Promise<MyPoolsListModelData> {
  return API.get('/my_pools/list', {
    params,
  })
}

//   Query Lp Reward
//   GET /my_pools/lp/reward
//   接口ID：184705757
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705757
export async function getMyPoolsLpReward(params: {
  chain: string | number
  token_address: string
  token_id: number
}): Promise<LPStakeRewardModel[]> {
  return API.get('/my_pools/lp/reward', {
    params,
  })
}

//   Claim Reward Sign
//   POST /my_pools/lp/claim/sign
//   接口ID：184705758
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-184705758
export async function postMyPoolsClaimSign(params: {
  /**
   * chain name
   */
  chain: string
  /**
   * Reward Address，timestamp
   */
  reward_address: string
  /**
   * Reward Token Address，reward_token_address
   */
  reward_token_address: string
  /**
   * Signature，sign result
   */
  signature: string
  /**
   * Timestamp，timestamp
   */
  timestamp: number
  /**
   * Token Address，V3 NFT token address
   */
  token_address: string
  /**
   * Token Id，V3 NFT token id
   */
  token_id: number
}): Promise<LPRewardSignModel> {
  return API.post('/my_pools/lp/claim/sign', params)
}

//   Config
//   GET /conif
//   接口ID：186680487
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-186680487
export async function getPoolConfig(): Promise<ConfigData> {
  return API.get('/conif', {})
}

//   Select Detrade Share
//   GET /detrade_share
//   接口ID：189996285
//   接口地址：https://app.apifox.com/link/project/4669540/apis/api-189996285
export async function getDetradeShare(params: { address: string }): Promise<any> {
  return API.get('/detrade_share', {
    params,
  })
}
export async function getFormSubmit(params: {
  project_name: 'string'
  contract_address: 'string'
  tg: 'string'
  twitter: 'string'
  logo: 'string'
  github: 'string'
  reward_amount: 'string'
  reward_time_duraion: 'string'
  reason: 'string'
  chain_info: 'string'
}): Promise<any> {
  return API.post('/form_submit', params)
}
